import {
  Box,
  ButtonBase,
  CardHeader,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material"
import { Link } from "react-router-dom"

import * as shared from "probuild-shared"

import UserAvatar from "components/views/users/UserAvatar"
import PostListItemVisibilityBadgeView from "components/views/projects/feed/PostListItemVisibilityBadgeView"
import OverflowMenu from "components/views/generic/OverflowMenu"
import paths from "model/utils/paths"
import OpenInFullIcon from "@mui/icons-material/OpenInFull"
import { styled } from "@mui/system"

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  marginTop: theme.spacing(0.25),
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  width: "100%",
  marginTop: theme.spacing(0.25),
}))

const PostListItemCardHeader = ({
  teamKey,
  projectKey,
  viewData,
  onPostMenuActionSelected,
  isOpenPostButtonVisible,
}: {
  teamKey: string
  projectKey: string
  viewData: shared.com.probuildsoftware.probuild.library.projects.data.view.PostListItemViewData
  onPostMenuActionSelected: (postKey: string, actionKey: string) => void
  isOpenPostButtonVisible?: boolean
}) => {
  if (!viewData.header) return null
  return (
    <CardHeader
      avatar={
        viewData.header.createdBy.avatar && (
          <UserAvatar size={45} viewData={viewData.header.createdBy.avatar} />
        )
      }
      title={
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <ButtonBase
            component={Link}
            to={paths.userEdit(teamKey, viewData.header.createdBy.userKey)}
          >
            {viewData.header.createdBy.name}
          </ButtonBase>
        </Box>
      }
      subheader={
        <StyledBox>
          <PostListItemVisibilityBadgeView
            visibility={viewData.header.visibility}
          />
          <StyledTypography variant="caption">
            {viewData.header.createdAtFormatted}
          </StyledTypography>
        </StyledBox>
      }
      action={
        <>
          {isOpenPostButtonVisible && (
            <Tooltip placement="top" title={viewData.openPostButtonText}>
              <IconButton
                component={Link}
                to={paths.postPreview(teamKey, projectKey, viewData.postKey)}
              >
                <OpenInFullIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          <OverflowMenu
            viewData={viewData.menu}
            onActionSelected={(actionKey: string) => {
              onPostMenuActionSelected(viewData.postKey, actionKey)
            }}
          />
        </>
      }
    />
  )
}

export default PostListItemCardHeader
