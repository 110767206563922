import { styled } from "@mui/material/styles"
import { Typography } from "@mui/material"
import ErrorIcon from "@mui/icons-material/Error"

const RootDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: theme.spacing(2),
  margin: theme.spacing(4),
}))

const ErrorView = ({ description }: { description: string | null }) => {
  return (
    <RootDiv key={0}>
      <ErrorIcon fontSize="large" color="error" />
      <Typography>{description}</Typography>
    </RootDiv>
  )
}

export default ErrorView
