import CryptoService from "./CryptoService"

import * as shared from "probuild-shared"

class TeamDecryptionService {
  private secretFetchPromises: { [teamKey: string]: Promise<string> } = {}
  private aesSecretStorageApi: shared.com.probuildsoftware.probuild.library.common.model.crypto.AesSecretStorageApi
  private cryptoService: CryptoService

  constructor(
    aesSecretStorageApi: shared.com.probuildsoftware.probuild.library.common.model.crypto.AesSecretStorageApi,
    cryptoService: CryptoService
  ) {
    this.aesSecretStorageApi = aesSecretStorageApi
    this.cryptoService = cryptoService
  }

  public async decrypt(
    teamKey: string,
    cipherText: string | null
  ): Promise<string | null> {
    const teamSecret = await this.getTeamSecret(teamKey)
    if (teamSecret == null) {
      return Promise.resolve(null)
    }
    if (cipherText == null) {
      return Promise.resolve(null)
    }
    const decryptedValue = this.cryptoService.decryptWithAESSecret(
      teamSecret,
      cipherText
    )
    return Promise.resolve(decryptedValue)
  }

  public async decryptArrayBuffer(
    teamKey: string,
    arrayBuffer: ArrayBuffer,
    base64EncodedIv: string
  ): Promise<Uint8Array | null> {
    const teamSecret = await this.getTeamSecret(teamKey)
    if (teamSecret == null) {
      return Promise.resolve(null)
    }
    const decryptedUintArray =
      await this.cryptoService.decryptArrayBufferWithAESSecret(
        teamSecret,
        arrayBuffer,
        base64EncodedIv
      )
    return Promise.resolve(decryptedUintArray)
  }

  private async getTeamSecret(teamKey: string): Promise<string> {
    if (this.secretFetchPromises[teamKey] == null) {
      this.secretFetchPromises[teamKey] = new Promise((resolve, reject) => {
        this.aesSecretStorageApi.load(teamKey, (teamSecret: string) => {
          resolve(teamSecret)
        })
      })
    }
    return this.secretFetchPromises[teamKey]
  }
}

export default TeamDecryptionService
