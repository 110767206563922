import { useCallback, useState } from "react"
import { styled } from "@mui/material/styles"
import { Button, ButtonBase, Typography } from "@mui/material"
import GavelIcon from "@mui/icons-material/Gavel"

import * as shared from "probuild-shared"

import ContractListDialog from "components/dialogs/ContractListDialog"
import ContractEditDialog from "components/dialogs/ContractEditDialog"

const ContractContentDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
})

const ContractTitle = styled(Typography)({
  fontWeight: "bold",
})

const ContractButtonContentDiv = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
})

const ContractButton = styled(ButtonBase)(({ theme }) => ({
  width: "100%",
  marginBottom: theme.spacing(1),
  paddingTop: 0,
  paddingBottom: 0,
}))

const ContractParagraph = styled(Typography)({
  overflow: "hidden",
  textAlign: "left",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  "-webkit-line-clamp": "2",
  "-webkit-box-orient": "vertical",
  whiteSpace: "pre-line",
})

const DocumentEditingContract = ({
  teamKey,
  documentAnswerKey,
  element,
  input,
}: {
  teamKey: string
  documentAnswerKey: string
  element: shared.com.probuildsoftware.probuild.library.documents.data.view.elements.ElementViewData
  input: shared.com.probuildsoftware.probuild.library.documents.DocumentInput
}) => {
  const {
    contractKey,
    contractName,
    contractText,
    changeButtonText,
    removeButtonText,
  } = element.contract || {}

  const [isSelectingContract, setIsSelectingContract] = useState<boolean>(false)
  const [isEditingContract, setIsEditingContract] = useState<boolean>(false)
  const [isCreatingContract, setIsCreatingContract] = useState<boolean>(false)
  const onCurrentContractClicked = () => setIsEditingContract(true)
  const onChangeContractClicked = () => {
    setIsSelectingContract(true)
  }
  const onRemoveContractClicked = () =>
    input.changeContract(documentAnswerKey, null)
  const onContractSaved = useCallback(
    (contractKey: string | null) => {
      setIsEditingContract(false)
      setIsCreatingContract(false)
      if (contractKey) {
        input.changeContract(documentAnswerKey, contractKey)
      }
    },
    [documentAnswerKey, input]
  )
  const onContractSelected = (contractKey: string | null) => {
    setIsSelectingContract(false)
    if (contractKey) {
      input.changeContract(documentAnswerKey, contractKey)
    }
  }
  const onCreateContractClicked = () => {
    setIsCreatingContract(true)
    setIsSelectingContract(false)
  }
  return (
    <div>
      {contractKey && (
        <ContractButton onClick={onCurrentContractClicked}>
          <ContractButtonContentDiv>
            <ContractContentDiv>
              {contractName && <ContractTitle>{contractName}</ContractTitle>}
              {contractText && (
                <ContractParagraph>{contractText}</ContractParagraph>
              )}
            </ContractContentDiv>
            <GavelIcon color="primary" />
          </ContractButtonContentDiv>
        </ContractButton>
      )}
      {changeButtonText && (
        <Button
          variant="contained"
          color="primary"
          onClick={onChangeContractClicked}
        >
          {changeButtonText}
        </Button>
      )}
      {removeButtonText && (
        <Button
          sx={{ ml: 1 }}
          onClick={onRemoveContractClicked}
          color="primary"
        >
          {removeButtonText}
        </Button>
      )}
      {isSelectingContract && (
        <ContractListDialog
          teamKey={teamKey}
          isOpen={isSelectingContract}
          onContractSelected={onContractSelected}
          onCreateClicked={onCreateContractClicked}
        />
      )}
      {(isEditingContract || isCreatingContract) && (
        <ContractEditDialog
          teamKey={teamKey}
          contractKey={
            !isCreatingContract && contractKey ? contractKey : undefined
          }
          isOpen={isEditingContract || isCreatingContract}
          onContractSaved={onContractSaved}
        />
      )}
    </div>
  )
}

export default DocumentEditingContract
