import { MouseEvent, useState } from "react"

import { Link } from "@mui/material"
import CardHeader from "@mui/material/CardHeader"
import Avatar from "@mui/material/Avatar"
import IconButton from "@mui/material/IconButton"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import Popover from "@mui/material/Popover"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import FeedListItemInfo from "model/feed/item/FeedListItemInfo"
import MenuLink from "model/menu/MenuLink"
import paths from "model/utils/paths"

const FeedItemHeaderView = ({
  info,
  links,
  showTeamName,
}: {
  info: FeedListItemInfo
  links?: Array<MenuLink>
  showTeamName: boolean
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const handleClick = (event: MouseEvent<any>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const id = open ? "menu-popover" : undefined
  const menuListItems = links?.map((link: MenuLink) => {
    if (link.url) {
      return (
        <ListItem button component="a" href={link.url} key={link.url}>
          <ListItemIcon>{link.icon}</ListItemIcon>
          <ListItemText primary={link.name} />
        </ListItem>
      )
    } else {
      return null
    }
  })
  return (
    <>
      <CardHeader
        avatar={
          <Avatar sx={{ backgroundColor: "error.main" }}>
            {info.initials}
          </Avatar>
        }
        title={
          <>
            <Link
              color="inherit"
              underline="hover"
              href={paths.userEdit(info.teamKey, info.userKey)}
            >
              {info.userName}
            </Link>
            {showTeamName && (
              <>
                {" • "}
                <Link
                  color="inherit"
                  underline="hover"
                  href={paths.team(info.teamKey)}
                  sx={{
                    fontWeight: 500,
                  }}
                >
                  {info.teamName}
                </Link>
              </>
            )}
          </>
        }
        subheader={info.createdAt}
        action={
          links && (
            <IconButton onClick={handleClick} size="large">
              <MoreVertIcon />
            </IconButton>
          )
        }
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <List component="nav">{menuListItems}</List>
      </Popover>
    </>
  )
}

export default FeedItemHeaderView
