import { styled } from "@mui/material/styles"
import { ListItemIcon, ListItemButton, Box } from "@mui/material"
import { SvgIconComponent } from "@mui/icons-material"
import { Link, useLocation } from "react-router-dom"

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  width: "100%",
  color: theme.palette.primary.contrastText,
  [`&.Mui-selected`]: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
  },
  [`&.Mui-selected:hover`]: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
  },
})) as typeof ListItemButton

const NavigationDrawerItem = ({
  title,
  icon,
  href,
}: {
  title: string
  icon: SvgIconComponent
  href: string
}) => {
  const location = useLocation()
  const path = location.pathname
  const Icon = icon
  const selected = path.startsWith(href)
  return (
    <StyledListItemButton
      component={Link}
      selected={path.startsWith(href)}
      key={title}
      to={href}
    >
      <ListItemIcon>
        <Icon sx={{ color: "primary.contrastText" }} />
      </ListItemIcon>
      <Box
        fontSize={16}
        fontWeight={selected ? "fontWeightBold" : "fontWeightRegular"}
      >
        {title}
      </Box>
    </StyledListItemButton>
  )
}

export default NavigationDrawerItem
