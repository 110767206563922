import { useMemo } from "react"

import * as shared from "probuild-shared"

import useDependencyProvider from "model/utils/useDependencyProvider"
import useAppUser from "model/utils/useAppUser"
import usePresenter from "model/presenter/usePresenter"

const useItem = ({
  teamKey,
  itemKey,
  observer,
}: {
  teamKey: string
  itemKey: string | undefined
  observer: shared.com.probuildsoftware.probuild.library.items.ItemEventObserver
}): [
  shared.com.probuildsoftware.probuild.library.items.data.view.ItemViewData | null,
  shared.com.probuildsoftware.probuild.library.items.ItemInput | null
] => {
  const dependencyProvider = useDependencyProvider()
  const appUser = useAppUser({ teamKey })
  const presenter = useMemo(() => {
    if (dependencyProvider && appUser) {
      console.log(
        `Creating item presenter teamKey = ${appUser.teamKey} userKey = ${appUser.userKey} itemKey = ${itemKey}`
      )
      return new shared.com.probuildsoftware.probuild.library.items.ItemPresenter(
        dependencyProvider,
        null,
        appUser,
        {
          itemKey,
        }
      )
    }
  }, [itemKey, appUser, dependencyProvider])
  return usePresenter(presenter, observer)
}

export default useItem
