import { ReactNode } from "react"
import { styled } from "@mui/material/styles"
import { Card, Typography } from "@mui/material"

const StyledCard = styled(Card)(({ theme }) => ({
  variant: "outlined",
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(2),
  marginBottom: theme.spacing(3),
  gap: theme.spacing(2),
}))

const LabeledCard = ({
  text,
  children,
}: {
  text: string | null | undefined
  children: ReactNode
}) => {
  return (
    <div>
      <Typography sx={{ m: 2 }}>{text}</Typography>
      <StyledCard>{children}</StyledCard>
    </div>
  )
}

export default LabeledCard
