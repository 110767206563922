import { useMemo, useState } from "react"

import * as shared from "probuild-shared"

import useDependencyProvider from "model/utils/useDependencyProvider"
import usePresenter from "model/presenter/usePresenter"

function useAuthSecureCode(): [
  boolean,
  boolean,
  shared.com.probuildsoftware.probuild.library.auth.data.view.AuthSecureCodeViewData | null,
  shared.com.probuildsoftware.probuild.library.auth.AuthSecureCodeInput | null
] {
  const [navigateBackToPhoneNumberEntry, setNavigateBackToPhoneNumberEntry] =
    useState<boolean>(false)
  const [navigateToAccountSetup, setNavigateToAccountSetup] =
    useState<boolean>(false)
  const dependencyProvider = useDependencyProvider()
  const presenter = useMemo(() => {
    if (!dependencyProvider) {
      return
    }
    return new shared.com.probuildsoftware.probuild.library.auth.AuthSecureCodePresenter(
      dependencyProvider
    )
  }, [dependencyProvider])
  const eventsObserver = useMemo(() => {
    return {
      onCompletion() {
        setNavigateToAccountSetup(true)
      },
      onNavigateBackToPhoneNumberEntry() {
        setNavigateBackToPhoneNumberEntry(true)
      },
    }
  }, [])
  const [viewData, input] = usePresenter(presenter, eventsObserver)
  return [
    navigateBackToPhoneNumberEntry,
    navigateToAccountSetup,
    viewData,
    input,
  ]
}

export default useAuthSecureCode
