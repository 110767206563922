import { useMemo } from "react"

import * as shared from "probuild-shared"

import useDependencyProvider from "model/utils/useDependencyProvider"
import useAppUser from "model/utils/useAppUser"
import usePresenter from "model/presenter/usePresenter"

const useSubscription = ({
  teamKey,
  observer,
}: {
  teamKey: string
  observer: shared.com.probuildsoftware.probuild.library.subscriptions.SubscriptionEventObserver
}): [
  shared.com.probuildsoftware.probuild.library.subscriptions.data.view.SubscriptionViewData | null,
  shared.com.probuildsoftware.probuild.library.subscriptions.SubscriptionInput | null
] => {
  const appUser = useAppUser({ teamKey })
  const dependencyProvider = useDependencyProvider()
  const presenter = useMemo(() => {
    if (dependencyProvider && appUser) {
      console.log(
        `Creating subscription presenter teamKey = ${appUser.teamKey} userKey = ${appUser.userKey}`
      )
      return new shared.com.probuildsoftware.probuild.library.subscriptions.SubscriptionPresenter(
        dependencyProvider,
        appUser
      )
    }
  }, [appUser, dependencyProvider])
  return usePresenter(presenter, observer)
}

export default useSubscription
