import { useState } from "react"
import { Fade } from "@mui/material"
import { Skeleton } from "@mui/material"

const LoadingImage = ({
  className,
  src,
  alt,
}: {
  className?: string
  src: string
  alt: string
}) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false)
  return (
    <div style={{ position: "relative" }}>
      <Fade in={isImageLoaded} timeout={1000}>
        <img
          className={className}
          src={src}
          alt={alt}
          onLoad={() => setIsImageLoaded(true)}
        />
      </Fade>
      {!isImageLoaded && (
        <Skeleton
          className={className}
          variant="rectangular"
          animation="wave"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            borderColor: "rgba(0,0,0,0)",
          }}
        />
      )}
    </div>
  )
}

export default LoadingImage
