import React, { useState } from "react"
import { styled } from "@mui/material/styles"
import { Button, Toolbar } from "@mui/material"
import { Navigate } from "react-router"

import useAccountsList from "model/app/useAccountsList"
import AccountView from "components/views/app/AccountView"
import AccountPopover from "components/views/app/AccountPopover"
import paths from "model/utils/paths"

const AccountInfoButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  padding: 0,
  width: "100%",
  [`&:hover`]: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
  },
}))

const AccountButton = () => {
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(
    null
  )
  const [viewData, input] = useAccountsList()
  const currentAccount = viewData && viewData.currentAccount
  const buttonClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget)
  }
  const closePopover = () => {
    setAnchorElement(null)
  }
  const signOut = () => {
    input?.signOut()
  }
  const selectTeam = (teamKey: string) => {
    input?.changeCurrentAccount(teamKey)
    setAnchorElement(null)
  }
  if (viewData?.isSignedOut === true) {
    return <Navigate to={paths.authPhone()} replace={true} />
  }
  if (viewData?.navigateToTeamKey) {
    return (
      <Navigate to={paths.team(viewData?.navigateToTeamKey)} replace={true} />
    )
  }
  return (
    <Toolbar disableGutters={true}>
      <AccountPopover
        anchorElement={anchorElement}
        accountsViewData={viewData}
        selectTeam={selectTeam}
        signOut={signOut}
        closePopover={closePopover}
      />
      <AccountInfoButton onClick={buttonClicked}>
        <AccountView account={currentAccount || null} light={true} />
      </AccountInfoButton>
    </Toolbar>
  )
}

export default AccountButton
