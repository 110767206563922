import { useState } from "react"

import * as shared from "probuild-shared"

import ConfirmationDialog from "components/dialogs/ConfirmationDialog"

function useConfirmDiscardDialog(
  shouldConfirmDiscard: boolean | undefined,
  discardDialog:
    | shared.com.probuildsoftware.probuild.library.common.data.view.DialogViewData
    | undefined,
  onDiscardConfirmed: () => void
): [() => JSX.Element | null, () => void] {
  const [isConfirmDiscardDialogOpen, setIsConfirmDiscardDialogOpen] =
    useState<boolean>(false)
  const onDiscardInitiated = () => {
    if (shouldConfirmDiscard) {
      setIsConfirmDiscardDialogOpen(true)
    } else {
      onDiscardConfirmed()
    }
  }
  return [
    () => {
      return (
        <ConfirmationDialog
          open={isConfirmDiscardDialogOpen}
          dialogViewData={discardDialog}
          onConfirm={() => onDiscardConfirmed()}
          onCancel={() => setIsConfirmDiscardDialogOpen(false)}
        />
      )
    },
    onDiscardInitiated,
  ]
}

export default useConfirmDiscardDialog
