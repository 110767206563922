import {
  Camera,
  Delete,
  Edit,
  FilePresent,
  NoteAdd,
  Notes,
  PhotoAlbum,
  Share,
  UploadFile,
  Send,
} from "@mui/icons-material"

import * as shared from "probuild-shared"

import unreachableCode from "model/utils/unreachableCode"

const CommonIcon = ({ name }: { name: string | null | undefined }) => {
  if (!name) return <></>
  const CommonIcons =
    shared.com.probuildsoftware.probuild.library.common.data.view.CommonIcons
  const commonIconName = CommonIcons.Companion.fromName(name)?.name
  if (!commonIconName) return <></>
  switch (commonIconName) {
    case CommonIcons.CAMERA.name:
      return <Camera />
    case CommonIcons.CREATE_ESTIMATE.name:
      return <NoteAdd />
    case CommonIcons.CREATE_INVOICE.name:
      return <NoteAdd />
    case CommonIcons.DELETE.name:
      return <Delete />
    case CommonIcons.EDIT.name:
      return <Edit />
    case CommonIcons.SELECT_DOCUMENT.name:
      return <FilePresent />
    case CommonIcons.SELECT_FILES.name:
      return <UploadFile />
    case CommonIcons.SELECT_PHOTOS.name:
      return <PhotoAlbum />
    case CommonIcons.SHARE.name:
      return <Share />
    case CommonIcons.WRITE_TEXT.name:
      return <Notes />
    case CommonIcons.EMAIL.name:
      return <Send />
    default:
      return unreachableCode(commonIconName)
  }
}

export default CommonIcon
