import { useMemo } from "react"

import * as shared from "probuild-shared"

import useAppUser from "model/utils/useAppUser"
import useDependencyProvider from "model/utils/useDependencyProvider"
import usePresenter from "model/presenter/usePresenter"

function usePaymentEdit({
  teamKey,
  documentKey,
  paymentKey,
  observer,
}: {
  teamKey: string
  documentKey: string | undefined
  paymentKey: string | null
  observer: shared.com.probuildsoftware.probuild.library.documents.PaymentEditEventObserver
}): [
  shared.com.probuildsoftware.probuild.library.documents.data.view.PaymentEditViewData | null,
  shared.com.probuildsoftware.probuild.library.documents.PaymentEditInput | null
] {
  const dependencyProvider = useDependencyProvider()
  const appUser = useAppUser({ teamKey })
  const presenter = useMemo(() => {
    if (dependencyProvider && appUser && documentKey) {
      return new shared.com.probuildsoftware.probuild.library.documents.PaymentEditPresenter(
        dependencyProvider,
        null,
        appUser,
        {
          documentKey,
          paymentKey,
        }
      )
    }
  }, [appUser, dependencyProvider, documentKey, paymentKey])
  return usePresenter(presenter, observer)
}

export default usePaymentEdit
