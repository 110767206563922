import * as shared from "probuild-shared"

function colorToCss(
  color:
    | shared.com.probuildsoftware.probuild.library.common.data.view.ColorViewData
    | null
    | undefined
) {
  return (
    (color && `rgb(${color.red}, ${color.green}, ${color.blue})`) || undefined
  )
}

export default colorToCss
