import { createContext } from "react"

import UserState from "model/user/UserState"

const UserContext = createContext<UserState>({
  signedInUser: null,
  checked: false,
})

export default UserContext
