import React, { useState } from "react"
import { styled } from "@mui/material/styles"
import { TableRow } from "@mui/material"
import { Navigate } from "react-router"

const StyledTableRow = styled(TableRow)({
  textDecoration: "none",
  cursor: "pointer",
})

const LinkTableRow = ({
  children,
  to,
}: {
  children: React.ReactNode
  to: string
}) => {
  const [navigateToDestination, setNavigateToDestination] = useState(false)

  return (
    <>
      {navigateToDestination && <Navigate to={to} />}
      <StyledTableRow
        hover={true}
        onClick={(event) => {
          const openInNewTab = event.ctrlKey || event.metaKey || event.shiftKey
          if (openInNewTab) {
            window.open(to, "_blank", "noopener,noreferrer")
          } else {
            setNavigateToDestination(true)
          }
        }}
      >
        {children}
      </StyledTableRow>
    </>
  )
}

export default LinkTableRow
