import { useMemo } from "react"
import { Box } from "@mui/material"

import NavigationBarDialog from "components/dialogs/NavigationBarDialog"
import useItem from "model/items/edit/useItem"
import useSnackbar from "model/snackbar/useSnackbar"
import SaveButton from "components/views/generic/SaveButton"
import ItemEditView from "components/views/items/edit/ItemEditView"
import useNavigateSafe from "model/navigation/useNavigateSafe"

const ItemEditDialog = ({
  teamKey,
  itemKey,
  isOpen,
  onItemSaved,
}: {
  teamKey: string
  itemKey: string | undefined
  isOpen: boolean
  onItemSaved: (itemKey: string | null) => void
}) => {
  const showSnackbar = useSnackbar()
  const [, navigateSafeState] = useNavigateSafe()
  const observer = useMemo(() => {
    return {
      onSaveSuccess(message: string, itemKey: string) {
        showSnackbar(message, "success")
        onItemSaved(itemKey)
      },
      onSaveError(message: string) {
        showSnackbar(message, "error")
      },
      onDeleteSuccess(message: string) {
        showSnackbar(message, "success")
      },
      onDeleteError(message: string) {
        showSnackbar(message, "error")
      },
    }
  }, [showSnackbar, onItemSaved])
  const onSaveClicked = () => input?.save()
  const onDialogClosed = () => onItemSaved(null)
  const [viewData, input] = useItem({ teamKey, itemKey, observer })
  return (
    <NavigationBarDialog
      isOpen={isOpen}
      onClose={onDialogClosed}
      title={viewData?.title}
      barItems={
        <SaveButton
          text={viewData?.actions.saveButtonText}
          isEnabled={viewData?.status.isModified}
          onClick={onSaveClicked}
        />
      }
    >
      <Box px={3}>
        <ItemEditView
          viewData={viewData}
          input={input}
          navigateSafeState={navigateSafeState}
        />
      </Box>
    </NavigationBarDialog>
  )
}

export default ItemEditDialog
