import { Box, IconButton, styled, Tooltip } from "@mui/material"
import { useRef } from "react"
import PhotoAlbumIcon from "@mui/icons-material/PhotoAlbum"
import SendIcon from "@mui/icons-material/Send"

import * as shared from "probuild-shared"

import ImageInput from "./ImageInput"
import ViewDataTextField from "./ViewDataTextField"

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  alignItems: "center",
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  alignSelf: "flex-end",
  color: theme.palette.primary.main,
}))

const PostCommentInputField = ({
  viewData,
  input,
}: {
  viewData: shared.com.probuildsoftware.probuild.library.projects.data.view.PostPreviewViewData
  input: shared.com.probuildsoftware.probuild.library.projects.PostPreviewInput | null
}) => {
  const photoInputRef = useRef<HTMLInputElement>(null)
  const onAddPhoto = () => {
    photoInputRef.current?.click()
  }
  const onImageSelected = async (
    file: shared.com.probuildsoftware.probuild.library.common.data.files.InputFile
  ) => {
    input?.postPhotoComment(file)
  }
  const onCommentChanged = (text: string) => {
    input?.changeCurrentComment(text)
  }
  const onCommentClicked = () => {
    input?.postComment()
  }
  return (
    <StyledBox>
      <Tooltip placement="top" arrow title={viewData.addPhotosHelperText}>
        <StyledIconButton>
          <PhotoAlbumIcon onClick={onAddPhoto} />
        </StyledIconButton>
      </Tooltip>
      <ViewDataTextField
        placeholderOnly
        viewData={viewData.addComment}
        onChange={onCommentChanged}
        sx={{ width: "100%" }}
      />
      <StyledIconButton onClick={onCommentClicked}>
        <SendIcon />
      </StyledIconButton>
      <ImageInput
        inputRef={photoInputRef}
        hidden={true}
        multiple={true}
        onFileChanged={onImageSelected}
      />
    </StyledBox>
  )
}

export default PostCommentInputField
