import shared from "probuild-shared"

class LiveEnvironmentApi
  implements
    shared.com.probuildsoftware.probuild.library.common.model.environment.api
      .EnvironmentApi
{
  localBackendHost: string | null = null
  projectId: string = (() => {
    const projectId = process.env.REACT_APP_FIREBASE_CONFIG_PROJECT_ID
    if (projectId) {
      return projectId
    }
    throw new Error("projectId was found to be undefined")
  })()
}

export default LiveEnvironmentApi
