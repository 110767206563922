import * as shared from "probuild-shared"

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"

const DropdownListItemSelectionMenu = ({
  label,
  value,
  renderValue,
  onChange,
  onOpen,
  options,
  disable,
}: {
  label?: string | undefined
  value: string | undefined
  renderValue: string | undefined | null
  onChange: (value: string) => void
  onOpen?: () => void
  options:
    | readonly shared.com.probuildsoftware.probuild.library.common.data.view.ListSelectionItemViewData[]
    | undefined
  disable?: boolean
}) => {
  return (
    <FormControl>
      <InputLabel variant="standard" shrink={true} sx={{ color: "inherit" }}>
        {label}
      </InputLabel>
      <Select<string>
        label={label}
        value={value || renderValue || undefined}
        renderValue={() => renderValue}
        variant="standard"
        onChange={(event) => {
          onChange(event.target.value)
        }}
        onOpen={onOpen}
        disabled={disable}
      >
        {options?.map((option) => {
          return (
            <MenuItem key={option.itemKey} value={option.itemKey}>
              {option.itemName}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default DropdownListItemSelectionMenu
