import { styled } from "@mui/material/styles"
import { Button, Paper, Typography } from "@mui/material"
import SendIcon from "@mui/icons-material/Send"

import * as shared from "probuild-shared"

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  position: "sticky",
  bottom: "0px",
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(3),
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  alignItems: "center",
}))

const DocumentPreviewToolbar = ({
  viewData,
  onSendClicked,
}: {
  viewData: shared.com.probuildsoftware.probuild.library.documents.data.view.DocumentViewData | null
  onSendClicked: () => void
}) => {
  if (!viewData) return null
  return (
    <StyledPaper variant="outlined">
      <Typography>
        {viewData.lastWorkflow?.formattedEmailWorkflowStatus}
      </Typography>
      {viewData.emailButtonText && (
        <Button
          onClick={onSendClicked}
          variant="contained"
          color="primary"
          startIcon={<SendIcon />}
        >
          {viewData.emailButtonText}
        </Button>
      )}
    </StyledPaper>
  )
}

export default DocumentPreviewToolbar
