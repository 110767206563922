import InfiniteScroll from "react-infinite-scroller"
import LoadingView from "components/views/LoadingView"
import FeedFilter from "components/views/feed/FeedFilter"
import FeedItemView from "components/views/feed/FeedItemView"
import SupportAgentPageLayout from "components/views/layouts/SupportAgentPageLayout"
import useFeedListView from "model/feed/useFeedListView"

const SupportAgentFeedPage = () => {
  const feedListView = useFeedListView({ teamKey: null })
  return (
    <SupportAgentPageLayout>
      <div>
        <FeedFilter
          filter={feedListView.typeFilter}
          filterChanged={feedListView.changeTypeFilter}
        />
        <InfiniteScroll
          pageStart={0}
          loadMore={feedListView.loadMoreAtBottom}
          hasMore={true}
          loader={<LoadingView />}
        >
          {feedListView.feedList?.items.map((feedItem) => {
            return (
              <FeedItemView
                key={feedItem.key}
                item={feedItem}
                showTeamName={true}
              />
            )
          })}
        </InfiniteScroll>
      </div>
    </SupportAgentPageLayout>
  )
}

export default SupportAgentFeedPage
