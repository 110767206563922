import { useMemo } from "react"

import * as shared from "probuild-shared"

import usePresenter from "model/presenter/usePresenter"
import useAppUser from "model/utils/useAppUser"
import useDependencyProvider from "model/utils/useDependencyProvider"

function useTabs({
  teamKey,
}: {
  teamKey: string
}): shared.com.probuildsoftware.probuild.library.app.data.view.TabsViewData | null {
  const appUser = useAppUser({ teamKey })
  const dependencyProvider = useDependencyProvider()
  const presenter = useMemo(() => {
    if (dependencyProvider && appUser) {
      console.log(
        `Creating tabs presenter teamKey = ${appUser.teamKey} userKey = ${appUser.userKey}`
      )
      return new shared.com.probuildsoftware.probuild.library.app.TabsPresenter(
        dependencyProvider,
        appUser
      )
    }
  }, [appUser, dependencyProvider])
  const [viewData] = usePresenter(presenter)
  return viewData
}

export default useTabs
