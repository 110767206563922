import * as shared from "probuild-shared"

import PostAttachmentDocumentView from "components/views/projects/feed/attachments/PostAttachmentDocumentView"
import PostAttachmentFileView from "components/views/projects/feed/attachments/PostAttachmentFileView"
import PostAttachmentPhotoGalleryView from "./PostAttachmentPhotoGalleryView"
import { Box, styled } from "@mui/material"

const AttachmentsBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}))

const PostAttachmentView = ({
  teamKey,
  projectKey,
  postKey,
  viewData,
  onDelete,
  onPhotoClicked,
  onPhotoViewed,
  isAllPhotoAttachmentsVisible,
}: {
  teamKey: string
  viewData: shared.com.probuildsoftware.probuild.library.projects.data.view.PostAttachmentViewData
  onDelete: ((attachmentKey: string) => void) | null
  onPhotoClicked: (attachmentKey: string) => void
  onPhotoViewed: (attachmentKey: string) => void
} & (
  | {
      isAllPhotoAttachmentsVisible: true
      projectKey?: string
      postKey?: string
    }
  | {
      isAllPhotoAttachmentsVisible: false
      projectKey: string
      postKey: string
    }
)) => {
  const documentAttachments = viewData.documents?.asJsReadonlyArrayView() || []
  const fileAttachments = viewData.files?.asJsReadonlyArrayView() || []
  return (
    <>
      {documentAttachments.map((documentAttachment) => {
        return (
          <AttachmentsBox>
            <PostAttachmentDocumentView
              teamKey={teamKey}
              viewData={documentAttachment}
              onDelete={onDelete}
            />
          </AttachmentsBox>
        )
      })}
      {fileAttachments.map((fileAttachment) => {
        return (
          <AttachmentsBox>
            <PostAttachmentFileView
              onDelete={onDelete}
              viewData={fileAttachment}
            />
          </AttachmentsBox>
        )
      })}
      {isAllPhotoAttachmentsVisible ? (
        <PostAttachmentPhotoGalleryView
          teamKey={teamKey}
          viewData={viewData}
          onDelete={onDelete}
          onPhotoClicked={onPhotoClicked}
          onPhotoViewed={onPhotoViewed}
          isAllPhotoAttachmentsVisible={true}
        />
      ) : (
        <PostAttachmentPhotoGalleryView
          teamKey={teamKey}
          projectKey={projectKey}
          postKey={postKey}
          viewData={viewData}
          onDelete={onDelete}
          onPhotoClicked={onPhotoClicked}
          onPhotoViewed={onPhotoViewed}
          isAllPhotoAttachmentsVisible={false}
        />
      )}
    </>
  )
}

export default PostAttachmentView
