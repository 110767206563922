import FeedListItem from "model/feed/item/FeedListItem"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import FeedItemHeaderView from "components/views/feed/FeedItemHeaderView"
import FeedItemContentView from "../FeedItemContentView"

const UnknownItemView = ({
  item,
  showTeamName,
}: {
  item: FeedListItem
  showTeamName: boolean
}) => {
  return (
    <Box mb={1}>
      <Card>
        <FeedItemHeaderView info={item.info} showTeamName={showTeamName} />
        <FeedItemContentView item={item} webPortalPath={item.webPortalPath} />
      </Card>
    </Box>
  )
}

export default UnknownItemView
