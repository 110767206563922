import { Paper, Box } from "@mui/material"
import GavelIcon from "@mui/icons-material/Gavel"

import TeamPageLayout from "components/views/layouts/TeamPageLayout"
import ContractListItemView from "components/views/contracts/list/ContractListItemView"
import SearchBar from "components/views/generic/SearchBar"
import useContractList from "model/contracts/list/useContractList"
import paths from "model/utils/paths"
import EmptyView from "components/views/EmptyView"

const ContractListPage = ({ teamKey }: { teamKey: string }) => {
  const [viewData, input] = useContractList({ teamKey, isSelecting: false })
  const contractListItems = viewData?.items?.asJsReadonlyArrayView() || []
  return (
    <TeamPageLayout
      barItems={
        <SearchBar
          placeholder={viewData?.searchHint}
          onChange={(text) => input?.changeSearchQuery(text)}
        />
      }
      createButtons={[
        {
          to: paths.contractCreate(teamKey),
          text: viewData?.createButtonText,
        },
      ]}
      title={viewData?.title}
      showLoadingSpinner={!viewData}
    >
      {viewData && (
        <Paper>
          {contractListItems.map((listItem) => {
            return (
              <ContractListItemView
                key={listItem.contractKey}
                teamKey={teamKey}
                item={listItem}
              />
            )
          })}
          {viewData.empty && (
            <Box sx={{ p: 2 }}>
              <EmptyView icon={GavelIcon} viewData={viewData.empty} />
            </Box>
          )}
        </Paper>
      )}
    </TeamPageLayout>
  )
}

export default ContractListPage
