import React from "react"
import { Avatar } from "@mui/material"

import * as shared from "probuild-shared"

import extractImagePath from "model/utils/extractImagePath"

const StyledAvatar = ({
  alt,
  variant,
  size,
  file,
  placeholder,
  borderColor,
  backgroundColor,
  contain,
}: {
  alt: string | undefined
  variant: "circular" | "rounded"
  size: number
  file:
    | shared.com.probuildsoftware.probuild.library.common.data.view.FileViewData
    | null
    | undefined
  placeholder: string | null | undefined
  borderColor?: string
  backgroundColor?: string
  contain?: boolean
}) => {
  const borderWidth = Math.ceil(size / 50)
  const style: React.CSSProperties = {
    width: size,
    height: size,
    fontSize: size / 2,
    borderColor: borderColor,
    borderWidth: borderColor && borderWidth,
    borderStyle: borderColor && "solid",
    backgroundColor: backgroundColor,
  }
  return (
    <Avatar
      variant={variant}
      alt={alt}
      style={style}
      sx={
        contain
          ? {
              "& > img": {
                objectFit: "contain",
              },
            }
          : undefined
      }
      src={
        extractImagePath({ file, width: size, height: size, fit: "outside" }) ||
        undefined
      }
    >
      {placeholder}
    </Avatar>
  )
}

export default StyledAvatar
