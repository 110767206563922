import * as shared from "probuild-shared"

import LoadingModal from "components/views/generic/LoadingModal"
import LabeledSwitchView from "components/views/generic/LabeledSwitchView"
import ViewDataTextField from "components/views/generic/ViewDataTextField"
import LabeledCard from "components/views/generic/LabeledCard"
import usePromptSafe from "model/navigation/usePromptSafe"
import { NavigateSafeState } from "model/navigation/useNavigateSafe"

const ItemEditView = ({
  viewData,
  input,
  navigateSafeState,
}: {
  viewData: shared.com.probuildsoftware.probuild.library.items.data.view.ItemViewData | null
  input: shared.com.probuildsoftware.probuild.library.items.ItemInput | null
  navigateSafeState: NavigateSafeState
}) => {
  usePromptSafe({
    when: viewData?.status.isModified === true,
    message: viewData?.actions.discardConfirmationDialog.message || "",
    navigateSafeState: navigateSafeState,
  })
  if (!input || !viewData) return null
  return (
    <>
      <LabeledCard text={null}>
        <ViewDataTextField
          viewData={viewData.name}
          onChange={(text) => input?.changeName(text)}
        />
        <ViewDataTextField
          viewData={viewData.description}
          onChange={(text) => input?.changeDescription(text)}
        />
        <ViewDataTextField
          viewData={viewData.rate}
          onChange={(text) => input?.changeRate(text)}
        />
        <LabeledSwitchView
          labelText={viewData.taxable.label}
          isOn={viewData.taxable.value}
          onChange={(isOn) => {
            input?.changeTaxable(isOn)
          }}
        />
      </LabeledCard>
      <LoadingModal
        open={
          viewData.status.isSaveInProgress || viewData.status.isDeleteInProgress
        }
      />
    </>
  )
}

export default ItemEditView
