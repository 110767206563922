import React from "react"
import { styled } from "@mui/material/styles"
import { Toolbar, Typography } from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import IconButton from "@mui/material/IconButton"
import CollapsibleCreateButtons from "./CollapsibleCreateButtons"

const CenteredContentDiv = styled("div")(({ theme }) => ({
  width: "100%",
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
}))

const NavigationBarFlexContainerDiv = styled("div")({
  display: "flex",
  justifyContent: "space-between",
})

const NavigationBarLeftDiv = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  marginRight: theme.spacing(3),
  flexShrink: 0,
  [theme.breakpoints.down("sm")]: { marginRight: 0 },
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: { display: "none" },
}))

const NavigationBarRightDiv = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: theme.spacing(1),
  flexGrow: 1,
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(3),
  [theme.breakpoints.up("md")]: { display: "none" },
  [theme.breakpoints.down("sm")]: { marginRight: theme.spacing(2) },
}))

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  boxShadow: theme.shadows[1],
  justifyContent: "center",
  flexShrink: 0,
  position: "relative",
  zIndex: 1000,
}))

const NavigationBar = ({
  className,
  title,
  fullWidth,
  barItems,
  createButtons,
  toggleNavigationDrawer,
}: {
  className?: string
  title: string | null | undefined
  fullWidth?: boolean
  barItems?: React.ReactNode
  createButtons?: { to: string; text: string | undefined | null }[]
  toggleNavigationDrawer?: () => void
}) => {
  const buttonsWithText = createButtons?.reduce(
    (result: { to: string; text: string }[], button) => {
      if (button.text) {
        result.push({ to: button.to, text: button.text })
      }
      return result
    },
    []
  )
  return (
    <StyledToolbar className={className} disableGutters={true}>
      <CenteredContentDiv sx={{ maxWidth: fullWidth ? "1400px" : "870px" }}>
        <NavigationBarFlexContainerDiv>
          <NavigationBarLeftDiv>
            {toggleNavigationDrawer && (
              <StyledIconButton
                color="inherit"
                aria-label="open drawer"
                onClick={toggleNavigationDrawer}
              >
                <MenuIcon />
              </StyledIconButton>
            )}
            <StyledTypography variant="h6">{title}</StyledTypography>
          </NavigationBarLeftDiv>
          <NavigationBarRightDiv>
            {barItems}
            {buttonsWithText && (
              <CollapsibleCreateButtons buttons={buttonsWithText} />
            )}
          </NavigationBarRightDiv>
        </NavigationBarFlexContainerDiv>
      </CenteredContentDiv>
    </StyledToolbar>
  )
}

export default NavigationBar
