import { useEffect, useState } from "react"
import { Box, Button, ButtonBase, styled, Typography } from "@mui/material"
import { grey } from "@mui/material/colors"

import * as shared from "probuild-shared"

import UserAvatar from "components/views/users/UserAvatar"
import ImageView from "../generic/ImageView"
import { useInView } from "react-intersection-observer"
import ImageGalleryView from "../generic/ImageGalleryView"
import { Link } from "react-router-dom"
import paths from "model/utils/paths"

const BubbleBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: grey[200],
  borderRadius: theme.spacing(2),
  padding: theme.spacing(1.5),
}))

const MessageBubbleView = ({
  teamKey,
  viewData,
  onViewed,
}: {
  teamKey: string
  viewData: shared.com.probuildsoftware.probuild.library.projects.data.view.PostCommentViewData
  onViewed: (commentKey: string) => void
}) => {
  const [isGalleryVisible, setIsGalleryVisible] = useState(false)
  const { ref, inView } = useInView({})
  useEffect(() => {
    if (inView) {
      onViewed(viewData.commentKey)
    }
  }, [onViewed, viewData.commentKey, inView])
  const originalPhotoWidth = viewData.photoDimensions?.width || 1
  const originalPhotoHeight = viewData.photoDimensions?.height || 1
  const photoAspectRatio = originalPhotoWidth / originalPhotoHeight
  return (
    <Box sx={{ display: "flex", my: 2, mx: 1 }} ref={ref}>
      <Box sx={{ display: "flex", alignItems: "start" }}>
        <ButtonBase
          component={Link}
          to={paths.userEdit(teamKey, viewData.createdBy.userKey)}
        >
          <UserAvatar size={30} viewData={viewData.createdBy.avatar} />
        </ButtonBase>
      </Box>
      <Box sx={{ ml: 1 }}>
        <BubbleBox>
          <Typography fontWeight="bold" variant="body2">
            {viewData.createdBy.name}
          </Typography>
          <Typography sx={{ whiteSpace: "pre-line" }}>
            {viewData.message}
          </Typography>
          {viewData.photo && (
            <Button ref={ref} onClick={() => setIsGalleryVisible(true)}>
              <Box
                sx={{
                  aspectRatio: photoAspectRatio,
                }}
              >
                <ImageView file={viewData.photo} alt={"Photo"} width={400} />
              </Box>
            </Button>
          )}
          {isGalleryVisible && (
            <ImageGalleryView
              files={viewData.photo ? [viewData.photo] : null}
              initialFilePath={viewData.photo?.info.path || null}
              onPhotoViewed={() => {}}
              onClose={() => setIsGalleryVisible(false)}
            />
          )}
        </BubbleBox>
        <Typography sx={{ ml: 1.5 }} variant="caption">
          {viewData.createdAtFormatted}
        </Typography>
      </Box>
    </Box>
  )
}

export default MessageBubbleView
