import { styled, Typography } from "@mui/material"

const StyledTypography = styled(Typography)(({ theme }) => ({
  display: "inline-block",
  border: "solid 1px",
  borderRadius: theme.shape.borderRadius,
  paddingLeft: theme.spacing(0.5),
  paddingRight: theme.spacing(0.5),
}))

const PostListItemVisibilityBadgeView = ({
  visibility,
}: {
  visibility: string
}) => {
  return (
    <StyledTypography variant="caption" color="gray">
      {visibility}
    </StyledTypography>
  )
}

export default PostListItemVisibilityBadgeView
