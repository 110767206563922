import * as shared from "probuild-shared"

import AddressTextField from "components/views/generic/AddressTextField"
import GoogleMap from "components/views/generic/GoogleMap"

const AddressAndMapView = ({
  location,
  onChange,
}: {
  location: shared.com.probuildsoftware.probuild.library.common.data.view.LocationViewData
  onChange: (address: string, lat: number | null, lng: number | null) => void
}) => {
  return (
    <div>
      <AddressTextField
        placeholder={location.address.label}
        defaultValue={location.address.text}
        onChange={onChange}
        sx={{ mb: 2 }}
      />
      <GoogleMap
        address={location.address.text}
        lat={location.lat || null}
        lng={location.lng || null}
      />
    </div>
  )
}

export default AddressAndMapView
