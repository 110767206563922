import * as shared from "probuild-shared"

import reportDataStorage from "model/storage/ReportDataStorage"

import { useState, useEffect } from "react"

function useLocalReportData(): shared.com.probuildsoftware.probuild.library.documents.data.view.DocumentReportViewData | null {
  const [reportData, setReportData] =
    useState<shared.com.probuildsoftware.probuild.library.documents.data.view.DocumentReportViewData | null>(
      null
    )
  useEffect(() => {
    const reportDataTransformer =
      new shared.com.probuildsoftware.probuild.library.documents.ReportDataTransformer()
    const currentJson = reportDataStorage.getJson()
    if (currentJson) {
      console.log(currentJson)
      setReportData(reportDataTransformer.fromJSON(currentJson))
    }
    return reportDataStorage.observe((newJson) => {
      if (newJson) {
        console.log(newJson)
        setReportData(reportDataTransformer.fromJSON(newJson))
      }
    })
  }, [])
  return reportData
}

export default useLocalReportData
