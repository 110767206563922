import React, { ReactNode, useState } from "react"
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material"
import { MoreHoriz } from "@mui/icons-material"
import { red } from "@mui/material/colors"

import * as shared from "probuild-shared"

import CommonIcon from "./CommonIcon"

const OverflowMenu = ({
  children,
  viewData,
  onActionSelected,
}: {
  children?: ReactNode | ReactNode[]
  viewData?: shared.com.probuildsoftware.probuild.library.common.data.view.MenuViewData
  onActionSelected?: (actionKey: string) => void
}) => {
  const [moreAnchorElement, setMoreAnchorElement] =
    useState<null | HTMLElement>(null)
  const handleMoreClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMoreAnchorElement(event.currentTarget)
  }
  const handleMoreClosed = () => {
    setMoreAnchorElement(null)
  }
  const actions = viewData?.actions?.asJsReadonlyArrayView() || []
  if (isMenuEmpty(children) && actions.length === 0) {
    return null
  }
  return (
    <>
      <IconButton aria-label={viewData?.title} onClick={handleMoreClicked}>
        <MoreHoriz />
      </IconButton>
      <Menu
        anchorEl={moreAnchorElement}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(moreAnchorElement)}
        onClick={handleMoreClosed}
        onClose={handleMoreClosed}
      >
        {actions.length > 0
          ? actions.map((action) => (
              <MenuItem
                sx={{
                  minWidth: 150,
                  color: action.isDestructive ? "error.main" : undefined,
                }}
                key={action.actionKey}
                onClick={() => {
                  if (onActionSelected) {
                    onActionSelected(action.actionKey)
                  }
                  handleMoreClosed()
                }}
              >
                {action.actionIcon && (
                  <ListItemIcon
                    sx={{ color: action.isDestructive ? red[500] : undefined }}
                  >
                    <CommonIcon name={action.actionIcon} />
                  </ListItemIcon>
                )}
                <ListItemText>{action.actionLabel}</ListItemText>
              </MenuItem>
            ))
          : children}
      </Menu>
    </>
  )
}

const isMenuEmpty = (children?: ReactNode) => {
  return React.Children.toArray(children).length === 0
}

export default OverflowMenu
