import {
  getRemoteConfig,
  fetchAndActivate,
  getValue,
} from "firebase/remote-config"

import shared from "probuild-shared"
class LiveRemoteConfigApi
  implements
    shared.com.probuildsoftware.probuild.library.common.model.config.api
      .RemoteConfigApi
{
  fetchAndActivate(completion: (success: boolean) => void) {
    fetchAndActivate(getRemoteConfig())
      .then(() => {
        completion(true)
      })
      .catch(() => {
        completion(false)
      })
  }
  getString(key: string): string | null {
    return getValue(getRemoteConfig(), key).asString()
  }
}

export default LiveRemoteConfigApi
