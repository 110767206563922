import { Badge } from "@mui/material"
import TimelapseIcon from "@mui/icons-material/Timelapse"
import { green } from "@mui/material/colors"
import { styled } from "@mui/material/styles"

import * as shared from "probuild-shared"

import colorToCss from "model/utils/colorToCss"
import StyledAvatar from "components/views/generic/StyledAvatar"

interface StyledTimelapseIconProps
  extends React.ComponentProps<typeof TimelapseIcon> {
  size: number
}

const StyledTimelapseIcon = styled(TimelapseIcon)<StyledTimelapseIconProps>(
  ({ theme, size }) => ({
    backgroundColor: theme.palette.background.paper,
    color: green[500],
    borderRadius: size / 2,
    width: size / 2,
    height: size / 2,
  })
)

const UserAvatar = ({
  size,
  viewData,
  hideClockedInBadge,
  borderColor,
}: {
  size: number
  viewData: shared.com.probuildsoftware.probuild.library.common.data.view.AvatarViewData | null
  hideClockedInBadge?: boolean
  borderColor?: string
}) => {
  return (
    <Badge
      overlap="circular"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      badgeContent={
        viewData?.isClockedIn &&
        !hideClockedInBadge && <StyledTimelapseIcon size={size} />
      }
    >
      <StyledAvatar
        variant="circular"
        alt={viewData?.placeholder.oneInitial}
        size={size}
        file={viewData?.file}
        placeholder={viewData?.placeholder.oneInitial}
        backgroundColor={colorToCss(viewData?.placeholder.color)}
        borderColor={borderColor}
      />
    </Badge>
  )
}

export default UserAvatar
