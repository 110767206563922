import { useMemo } from "react"
import { Box, Divider, InputLabel } from "@mui/material"

import TeamPageLayout from "components/views/layouts/TeamPageLayout"
import useProjectEdit from "model/projects/useProjectEdit"
import useSnackbar from "model/snackbar/useSnackbar"
import LabeledCard from "components/views/generic/LabeledCard"
import ViewDataTextField from "components/views/generic/ViewDataTextField"
import AddressAndMapView from "components/views/generic/AddressAndMapView"
import SaveButton from "components/views/generic/SaveButton"
import ClientSummaryView from "components/views/clients/summary/ClientSummaryView"
import DropdownView from "components/views/generic/DropdownView"
import { useParams } from "react-router-dom"
import useNavigateSafe from "model/navigation/useNavigateSafe"
import usePromptSafe from "model/navigation/usePromptSafe"

const ProjectInfoPage = ({ teamKey }: { teamKey: string }) => {
  const { projectKey } = useParams()
  const showSnackbar = useSnackbar()
  const [navigate, navigateSafeState] = useNavigateSafe()
  const observer = useMemo(() => {
    return {
      onShowSuccessSnackbar(message: string) {
        showSnackbar(message, "success")
      },
      onShowErrorSnackbar(message: string) {
        showSnackbar(message, "error")
      },
      onNavigateBack() {
        navigate(-1)
      },
    }
  }, [navigate, showSnackbar])
  const [viewData, input] = useProjectEdit({ teamKey, projectKey, observer })
  usePromptSafe({
    when: viewData?.shouldConfirmDiscard === true,
    message: viewData?.discardConfirmationDialog.message,
    navigateSafeState: navigateSafeState,
  })
  if (!viewData || !input) return <></>
  const onClientChanged = (clientKey: string | null) => {
    if (clientKey) {
      input.changeClient(clientKey)
    } else {
      input.removeClient()
    }
  }
  return (
    <TeamPageLayout
      title={viewData?.title}
      barItems={
        <>
          <SaveButton
            text={viewData.saveButtonText}
            isEnabled={true}
            onClick={() => input?.save()}
          />
        </>
      }
    >
      <LabeledCard text={""}>
        <ViewDataTextField
          viewData={viewData.name}
          onChange={(text) => input?.changeName(text)}
        />
        <ViewDataTextField
          viewData={viewData.description}
          onChange={(text) => input?.changeDescription(text)}
        />
        <DropdownView
          viewData={viewData.status}
          onChange={(statusKey) => {
            input?.changeStatus(statusKey)
          }}
        />
        <Divider />
        <Box>
          <InputLabel variant="standard" shrink={true}>
            {viewData.client.clientLabel}
          </InputLabel>
          <Box>
            <ClientSummaryView
              teamKey={teamKey}
              clientName={viewData.client.clientName}
              clientKey={viewData.client.clientKey}
              detailText={null}
              onClientChanged={onClientChanged}
              changeButtonText={viewData.client.clientChangeButtonText}
              removeButtonText={viewData.client.clientRemoveButtonText}
            />
          </Box>
        </Box>
        <Divider />
        <AddressAndMapView
          location={viewData.location}
          onChange={(address, lat, lng) => {
            input?.changeLocation(address, lat, lng)
          }}
        />
      </LabeledCard>
    </TeamPageLayout>
  )
}

export default ProjectInfoPage
