import {
  User,
  browserLocalPersistence,
  getAuth,
  signInWithCustomToken,
  getIdToken,
} from "firebase/auth"
import app from "../app/firebaseApp"

import SignedInUser from "./SignedInUser"
import SignedInUserChangedObserver from "./SignedInUserChangedObserver"
import ObserverUnsubscribe from "./ObserverUnsubscribe"

class UserService {
  private signedInUser: SignedInUser | null = null
  private signedInUserChecked: boolean = false
  private observers: SignedInUserChangedObserver[] = []
  private cleanUp: (() => void) | null = null

  async signIn(firebaseToken: string): Promise<boolean> {
    try {
      await getAuth(app).setPersistence(browserLocalPersistence)
      const userCreds = await signInWithCustomToken(getAuth(app), firebaseToken)
      this.updateSignedInUser(userCreds.user)
      return true
    } catch {
      return false
    }
  }

  async fetchIdToken(): Promise<string | null> {
    const auth = getAuth(app)
    const { currentUser } = auth
    if (!currentUser) return null
    return await getIdToken(currentUser, false)
  }

  addSignedInUserChangedObserver(
    observer: SignedInUserChangedObserver
  ): ObserverUnsubscribe {
    this.observers.push(observer)
    if (this.signedInUserChecked) {
      observer.onSignedInUserChanged(this.signedInUser)
    }
    this.attachObserversIfNecessary()
    return () => {
      this.observers.filter((currentObserver) => currentObserver !== observer)
      this.removeObserversIfNecessary()
    }
  }

  private attachObserversIfNecessary() {
    if (this.cleanUp != null) return
    this.cleanUp = getAuth(app).onIdTokenChanged(async (user: User | null) => {
      await this.updateSignedInUser(user)
    })
  }

  private removeObserversIfNecessary() {
    const cleanUp = this.cleanUp
    if (this.observers.length !== 0 || cleanUp === null) return
    cleanUp()
    this.cleanUp = null
  }

  private notifyObservers() {
    this.observers.forEach((observer) =>
      observer.onSignedInUserChanged(this.signedInUser)
    )
  }

  private async updateSignedInUser(user: User | null) {
    this.signedInUser = await this.createUpdatedSignedInUser(user)
    this.signedInUserChecked = true
    this.notifyObservers()
  }

  private async createUpdatedSignedInUser(
    firebaseUser: User | null
  ): Promise<SignedInUser | null> {
    if (firebaseUser) {
      console.log(`Updated signed in user for uid: ${firebaseUser.uid}`)
      const uid = firebaseUser.uid
      const idTokenResult = await firebaseUser.getIdTokenResult()
      return new SignedInUser(uid, idTokenResult.token, idTokenResult.claims)
    } else {
      return null
    }
  }
}

export default UserService
