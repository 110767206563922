import { styled } from "@mui/material/styles"
import { CircularProgress } from "@mui/material"

const RootDiv = styled("div")(({ theme }) => ({
  margin: theme.spacing(4),
  display: "flex",
  justifyContent: "center",
}))

const LoadingView = ({ percentage }: { percentage?: number }) => {
  return (
    <RootDiv key={0}>
      <CircularProgress
        value={percentage}
        variant={percentage ? "determinate" : "indeterminate"}
      />
    </RootDiv>
  )
}

export default LoadingView
