import { styled } from "@mui/material/styles"
import { Switch, Typography } from "@mui/material"

const RootDiv = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
})

const LabeledSwitchView = ({
  labelText,
  isOn,
  onChange,
}: {
  labelText: string | null
  isOn: boolean
  onChange: (newValue: boolean) => void
}) => {
  return (
    <RootDiv>
      <Typography>{labelText}</Typography>
      <Switch
        color="primary"
        checked={isOn}
        onChange={() => {
          onChange(!isOn)
        }}
      />
    </RootDiv>
  )
}

export default LabeledSwitchView
