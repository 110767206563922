import * as shared from "probuild-shared"

import DateSelector from "components/views/generic/DateSelector"

const DocumentEditingDate = ({
  documentAnswerKey,
  element,
  input,
}: {
  documentAnswerKey: string
  element: shared.com.probuildsoftware.probuild.library.documents.data.view.elements.ElementViewData
  input: shared.com.probuildsoftware.probuild.library.documents.DocumentInput
}) => {
  return (
    <DateSelector
      isoDate={element.date?.isoFormat}
      onDateChange={(newIsoDate: string) => {
        input.changeElementText(
          documentAnswerKey,
          element.elementKey,
          newIsoDate
        )
      }}
    />
  )
}

export default DocumentEditingDate
