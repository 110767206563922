import React, { useCallback, useState } from "react"
import { styled } from "@mui/material/styles"
import { Box, Button, ButtonBase, Typography } from "@mui/material"
import Contacts from "@mui/icons-material/Contacts"

import ClientListDialog from "components/dialogs/ClientListDialog"
import ClientEditDialog from "components/dialogs/ClientEditDialog"

const CurrentClientButtonContentDiv = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
})

const ClientSummaryView = ({
  teamKey,
  clientName,
  clientKey,
  detailText,
  onClientChanged,
  changeButtonText,
  removeButtonText,
}: {
  teamKey: string
  clientName: string | null | undefined
  clientKey: string | null | undefined
  detailText: string | null | undefined
  onClientChanged: (clientKey: string | null) => void
  changeButtonText: string | null | undefined
  removeButtonText: string | null | undefined
}) => {
  const [isSelectingClient, setIsSelectingClient] = useState<boolean>(false)
  const [isEditingClient, setIsEditingClient] = useState<boolean>(false)
  const [isCreatingClient, setIsCreatingClient] = useState<boolean>(false)
  const onCloseClientDialog = useCallback(() => {
    setIsEditingClient(false)
    setIsCreatingClient(false)
  }, [])
  const onAddClientClicked = () => {
    setIsSelectingClient(true)
  }
  const onClientClicked = (clientKey: string | null) => {
    setIsSelectingClient(false)
    if (clientKey) {
      onClientChanged(clientKey)
    }
  }
  const onCreateClientClicked = () => {
    setIsCreatingClient(true)
    setIsSelectingClient(false)
  }
  const onRemoveClientClicked = () => {
    onClientChanged(null)
  }
  const onSave = useCallback(
    (clientKey: string) => {
      onClientChanged(clientKey)
    },
    [onClientChanged]
  )
  return (
    <>
      {clientName && (
        <ButtonBase
          sx={{ width: "100%", mb: 1, py: 1 }}
          onClick={() => {
            setIsEditingClient(true)
          }}
        >
          <CurrentClientButtonContentDiv>
            {clientName && (
              <Typography display="inline" variant="body1">
                {clientName}
              </Typography>
            )}
            <Contacts color="primary" />
          </CurrentClientButtonContentDiv>
        </ButtonBase>
      )}
      {detailText && (
        <Box display="inline" fontStyle="italic">
          {detailText}
        </Box>
      )}
      {changeButtonText && (
        <Button
          variant="contained"
          color="primary"
          onClick={onAddClientClicked}
        >
          {changeButtonText}
        </Button>
      )}
      {removeButtonText && (
        <Button sx={{ ml: 1 }} color="primary" onClick={onRemoveClientClicked}>
          {removeButtonText}
        </Button>
      )}
      {isSelectingClient && (
        <ClientListDialog
          teamKey={teamKey}
          isOpen={isSelectingClient}
          onClientSelected={onClientClicked}
          onCreateClicked={onCreateClientClicked}
        />
      )}
      {(isEditingClient || isCreatingClient) && (
        <ClientEditDialog
          teamKey={teamKey}
          clientKey={isCreatingClient ? undefined : clientKey || undefined}
          isOpen={isEditingClient || isCreatingClient}
          onCloseDialog={onCloseClientDialog}
          onSave={onSave}
        />
      )}
    </>
  )
}

export default ClientSummaryView
