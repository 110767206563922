import * as forge from "node-forge"

import BufferEncryptionResult from "model/crypto/BufferEncryptionResult"

class BufferEncryptionOperation {
  private readonly CHUNK_SIZE = 1024 * 64

  private readonly iv: string
  private readonly aesSecretBytes: forge.util.ByteBuffer
  private readonly cipher: forge.cipher.BlockCipher
  private readonly plainText: ArrayBuffer
  private cipherText: string
  private index: number

  constructor(plainText: ArrayBuffer, aesSecret: string) {
    this.iv = forge.random.getBytesSync(16)
    this.plainText = plainText
    this.aesSecretBytes = forge.util.createBuffer(
      forge.util.decode64(aesSecret)
    )
    this.cipher = forge.cipher.createCipher("AES-CBC", this.aesSecretBytes)
    this.cipherText = ""
    this.index = 0
  }

  start(): Promise<BufferEncryptionResult> {
    const promise = new Promise<BufferEncryptionResult>((resolve, reject) => {
      this.cipher.start({ iv: this.iv })
      this.process(resolve)
    })
    return promise
  }

  private process(resolve: (value: BufferEncryptionResult) => void) {
    if (this.index < this.plainText.byteLength) {
      this.cipherText += this.cipher.output.getBytes()
      const chunk = new forge.util.ByteStringBuffer(
        this.plainText.slice(this.index, this.index + this.CHUNK_SIZE)
      )
      this.cipher.update(chunk)
      this.index += this.CHUNK_SIZE
      setTimeout(() => this.process(resolve), 50)
    } else {
      this.cipher.finish()
      this.cipherText += this.cipher.output.getBytes()
      const encrypted = forge.util.binary.raw.decode(this.cipherText)
      resolve({
        data: encrypted,
        iv: forge.util.encode64(this.iv),
      })
    }
  }
}

export default BufferEncryptionOperation
