import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check"

import app from "./firebaseApp"

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(
    process.env.REACT_APP_RECAPTCHA_ENTERPRISE_KEY_ID || ""
  ),
  isTokenAutoRefreshEnabled: true,
})

export default appCheck
