import FeedListItem from "model/feed/item/FeedListItem"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import { CardActionArea } from "@mui/material"

const FeedItemContentView = ({
  item,
  webPortalPath,
}: {
  item: FeedListItem
  webPortalPath?: string | null
}) => {
  return (
    <>
      {webPortalPath ? (
        <CardActionArea href={webPortalPath}>
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
              {item.type}
            </Typography>
          </CardContent>
        </CardActionArea>
      ) : (
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {item.type}
          </Typography>
        </CardContent>
      )}
    </>
  )
}

export default FeedItemContentView
