class CookieProvider {
  public setSessionCookie(value: string) {
    this.setCookie("__session", value)
  }

  public deleteSessionCookie() {
    this.deleteCookie("__session")
  }

  private setCookie(name: string, value: string) {
    document.cookie = `${name}=${value}; path=/`
    console.log(`Cookie set for ${name}`)
  }

  private deleteCookie(name: string) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`
    console.log(`Cookie deleted for ${name}`)
  }
}

export default CookieProvider
