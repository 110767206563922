import * as shared from "probuild-shared"

function extractImagePath(options: {
  file:
    | shared.com.probuildsoftware.probuild.library.common.data.view.FileViewData
    | undefined
    | null
  width?: number
  height?: number
  download?: boolean
  fit?: "inside" | "outside"
}): string | null {
  if (options.file?.info.devicePath) {
    return options.file?.info.devicePath
  } else if (options.file?.info.generation) {
    const resizeParams =
      options.width || options.height
        ? `resize=${options.width || ""}x${options.height || ""}&`
        : ""
    const downloadParams = options.download
      ? `download=${options.download}&`
      : ""
    const fitParams = options.fit ? `fit=${options.fit}&` : ""
    return `/api/storage/${options.file?.info.path}?${resizeParams}${downloadParams}${fitParams}jpegQuality=80&rotate=true&generation=${options.file?.info.generation}`
  } else {
    return null
  }
}

export default extractImagePath
