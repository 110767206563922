const databaseName = "Probuild"
const objectStoreName = "Secrets"

async function fetchObjectStore(): Promise<IDBObjectStore> {
  return new Promise<IDBObjectStore>((resolve, reject) => {
    const openRequest = window.indexedDB.open(databaseName)
    openRequest.onupgradeneeded = () => {
      const database = openRequest.result
      database.createObjectStore(objectStoreName, { keyPath: "id" })
    }
    openRequest.onsuccess = () => {
      const database = openRequest.result
      const transaction = database.transaction(objectStoreName, "readwrite")
      const store = transaction.objectStore(objectStoreName)
      resolve(store)
    }
    openRequest.onerror = () => {
      reject(openRequest.error)
    }
  })
}

async function saveKey(keyIdentifier: string, key: CryptoKey) {
  return new Promise<void>(async (resolve, reject) => {
    const store = await fetchObjectStore()
    const putRequest = store.put({ id: keyIdentifier, key: key })
    putRequest.onsuccess = () => {
      resolve()
    }
    putRequest.onerror = () => {
      reject(putRequest.error)
    }
  })
}

async function loadKey(keyIdentifier: string): Promise<CryptoKey> {
  return new Promise(async (resolve, reject) => {
    const store = await fetchObjectStore()
    const getRequest = store.get(keyIdentifier)
    getRequest.onsuccess = () => {
      if (getRequest.result) {
        const keyPair = getRequest.result.key as CryptoKey
        resolve(keyPair)
      } else {
        reject()
      }
    }
    getRequest.onerror = () => {
      reject(getRequest.error)
    }
  })
}

export { saveKey, loadKey }
