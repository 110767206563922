import React from "react"

import NavigationDrawerContext from "contexts/NavigationDrawerContext"

const NavigationDrawerProvider = ({
  children,
}: {
  teamKey: string
  children: React.ReactNode
}) => {
  const [openNavigationDrawer, setOpenNavigationDrawer] = React.useState(false)
  const toggleNavigationDrawer = () => {
    setOpenNavigationDrawer(!openNavigationDrawer)
  }

  return (
    <NavigationDrawerContext.Provider
      value={{ openNavigationDrawer, toggleNavigationDrawer }}
    >
      {children}
    </NavigationDrawerContext.Provider>
  )
}

export default NavigationDrawerProvider
