import { useEffect, useState } from "react"
import {
  ArrowBack,
  ArrowBackIosNew,
  ArrowForwardIos,
  FileDownload,
} from "@mui/icons-material"
import { Button, IconButton, Modal, styled, Typography } from "@mui/material"

import * as shared from "probuild-shared"

import extractImagePath from "model/utils/extractImagePath"

const GalleryNavigationButton = styled(IconButton)({
  m: 1,
  color: "white",
  backgroundColor: "black",
  position: "fixed",
  bottom: "50%",
})

const GalleryImage = styled("img")({
  display: "block",
  position: "fixed",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  margin: "auto",
  maxWidth: "80vw",
  maxHeight: "80vh",
})

const PhotoIndicesLabel = styled(Typography)({
  position: "fixed",
  bottom: 20,
  left: "50%",
  borderRadius: 4,
  paddingLeft: 8,
  paddingRight: 8,
  paddingTop: 4,
  paddingBottom: 4,
  backgroundColor: "black",
  color: "white",
  transform: "translate(-50%, 0)",
})

const ImageGalleryView = ({
  files,
  initialFilePath,
  onPhotoViewed,
  onClose,
}: {
  files:
    | shared.com.probuildsoftware.probuild.library.common.data.view.FileViewData[]
    | null
  initialFilePath: string | null
  onPhotoViewed: (path: string) => void
  onClose: () => void
}) => {
  const [filePath, setFilePath] = useState<string | null>(null)
  useEffect(() => {
    setFilePath(initialFilePath)
  }, [initialFilePath])
  useEffect(() => {
    if (filePath) {
      onPhotoViewed(filePath)
    }
  }, [onPhotoViewed, filePath])
  const currentFileIndex = files?.findIndex(
    (file) => file.info.path === filePath
  )
  if (!files || currentFileIndex === undefined || currentFileIndex === -1) {
    return <></>
  }
  const currentFile = files[currentFileIndex]
  const previousFilePath = files[currentFileIndex - 1]?.info.path || null
  const onPreviousClicked = () => {
    setFilePath(previousFilePath)
  }
  const nextFilePath = files[currentFileIndex + 1]?.info.path || null
  const onNextClicked = () => {
    setFilePath(nextFilePath)
  }
  const downloadPath = extractImagePath({
    file: currentFile,
    download: true,
  })
  const imagePath = extractImagePath({ file: currentFile })
  return (
    <Modal open={files != null} onClose={onClose}>
      <>
        <Button
          onClick={onClose}
          sx={{ position: "fixed", top: 20, left: 10, color: "white" }}
        >
          <ArrowBack fontSize="large" />
        </Button>
        {downloadPath && (
          <a
            href={downloadPath}
            target="_blank"
            rel="noreferrer"
            style={{ position: "fixed", top: 20, right: 20, color: "white" }}
          >
            <FileDownload fontSize="large" />
          </a>
        )}

        <GalleryNavigationButton
          sx={{
            left: 20,
            visibility: previousFilePath ? "visible" : "hidden",
          }}
          onClick={onPreviousClicked}
          size="large"
        >
          <ArrowBackIosNew />
        </GalleryNavigationButton>
        {imagePath && (
          <GalleryImage
            key={imagePath}
            alt={`Photo ${currentFileIndex}`}
            src={imagePath}
          />
        )}
        <GalleryNavigationButton
          sx={{
            right: 20,
            visibility: nextFilePath ? "visible" : "hidden",
          }}
          onClick={onNextClicked}
          size="large"
        >
          <ArrowForwardIos />
        </GalleryNavigationButton>
        <PhotoIndicesLabel>
          {currentFileIndex + 1} / {files.length}
        </PhotoIndicesLabel>
      </>
    </Modal>
  )
}

export default ImageGalleryView
