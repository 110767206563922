import React, { useEffect } from "react"
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  styled,
  Typography,
} from "@mui/material"
import CommentIcon from "@mui/icons-material/Comment"
import { useInView } from "react-intersection-observer"

import * as shared from "probuild-shared"

import PostAttachmentView from "components/views/projects/feed/attachments/PostAttachmentView"
import PostListItemCardHeader from "components/views/projects/feed/PostListItemCardHeader"
import { grey } from "@mui/material/colors"
import PostListItemUploadStatusView from "./PostListItemUploadStatusView"

const AttachmentsBox = styled(Box)(({ theme }) => ({
  gap: theme.spacing(1),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
}))

const StyledButton = styled(Button)({
  "&.Mui-disabled": {
    color: grey[600],
  },
})

const PostListItemView = React.memo(
  ({
    teamKey,
    projectKey,
    viewData,
    children,
    onPostMenuActionSelected,
    onPhotoClicked,
    onPhotoViewed,
    onBecameVisible,
    isOpenPostButtonVisible,
    onCommentButtonClicked,
    isCommentButtonVisible,
    isCommentCountButtonDisabled,
    isAllPhotoAttachmentsVisible,
  }: {
    teamKey: string
    projectKey: string
    viewData: shared.com.probuildsoftware.probuild.library.projects.data.view.PostListItemViewData
    children?: React.ReactNode
    onPostMenuActionSelected: (postKey: string, actionKey: string) => void
    onPhotoClicked: (
      attachmentKey: string,
      viewData: shared.com.probuildsoftware.probuild.library.projects.data.view.PostListItemViewData
    ) => void
    onPhotoViewed: (postKey: string, attachmentKey: string) => void
    onBecameVisible: (postKey: string) => void
    isOpenPostButtonVisible?: boolean
    onCommentButtonClicked: (postKey: string) => void
    isCommentButtonVisible?: boolean
    isCommentCountButtonDisabled?: boolean
    isAllPhotoAttachmentsVisible: boolean
  }) => {
    const { ref, inView } = useInView({})
    useEffect(() => {
      if (inView) {
        onBecameVisible(viewData.postKey)
      }
    }, [onBecameVisible, inView, viewData.postKey])
    return (
      <Card ref={ref} sx={{ mb: 2 }}>
        <Box sx={{ position: "relative" }}>
          <PostListItemCardHeader
            teamKey={teamKey}
            projectKey={projectKey}
            viewData={viewData}
            onPostMenuActionSelected={onPostMenuActionSelected}
            isOpenPostButtonVisible={isOpenPostButtonVisible}
          />
          <CardContent sx={{ p: 0, m: 1 }}>
            {viewData.message && (
              <Typography
                variant="h5"
                sx={{ px: 2, py: 1, whiteSpace: "pre-line" }}
              >
                {viewData.message}
              </Typography>
            )}
            <AttachmentsBox>
              <PostAttachmentView
                teamKey={teamKey}
                projectKey={projectKey}
                postKey={viewData.postKey}
                viewData={viewData.attachments}
                onPhotoClicked={(attachmentKey: string) =>
                  onPhotoClicked(attachmentKey, viewData)
                }
                onPhotoViewed={(attachmentKey: string) => {
                  onPhotoViewed(viewData.postKey, attachmentKey)
                }}
                onDelete={null}
                isAllPhotoAttachmentsVisible={isAllPhotoAttachmentsVisible}
              />
            </AttachmentsBox>
            <PostListItemUploadStatusView
              uploadStatus={viewData.uploadStatus}
            />
          </CardContent>
        </Box>
        <CardActions
          sx={{
            "&:last-child": {
              p: 0,
            },
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Box>
              {viewData.commentCount && (
                <StyledButton
                  onClick={() => onCommentButtonClicked(viewData.postKey)}
                  sx={{ textTransform: "none", color: "gray", ml: 1 }}
                  disabled={isCommentCountButtonDisabled}
                >
                  {viewData.commentCount}{" "}
                  {viewData.unreadCommentCount &&
                    `(${viewData.unreadCommentCount})`}
                </StyledButton>
              )}
            </Box>
            <Divider />
            {isCommentButtonVisible && (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <StyledButton
                  sx={{ color: "gray", py: 2 }}
                  fullWidth={true}
                  startIcon={<CommentIcon />}
                  onClick={() => onCommentButtonClicked(viewData.postKey)}
                >
                  {viewData.commentButtonText}
                </StyledButton>
              </Box>
            )}
            {children}
          </Box>
        </CardActions>
      </Card>
    )
  }
)

export default PostListItemView
