import * as shared from "probuild-shared"

import { Box, Button, Typography, useMediaQuery } from "@mui/material"
import { styled, useTheme } from "@mui/material/styles"

import PostAttachmentPhotoView from "components/views/projects/feed/attachments/PostAttachmentPhotoView"
import paths from "model/utils/paths"
import { useMemo } from "react"

const PhotosRowDiv = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
})

const PhotosColumnDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
})

const MorePhotosOverlayBox = styled(Button)(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  right: 0,
  top: 0,
  left: 0,
  color: "white",
  backgroundColor: theme.palette.grey[600],
  opacity: 0.9,
  margin: 1,
  borderRadius: 0,
  "&:hover": {
    backgroundColor: theme.palette.grey[700],
  },
}))

const PostAttachmentGalleryView = ({
  teamKey,
  projectKey,
  postKey,
  viewData,
  onDelete,
  onPhotoClicked,
  onPhotoViewed,
  isAllPhotoAttachmentsVisible,
}: {
  teamKey: string
  viewData: shared.com.probuildsoftware.probuild.library.projects.data.view.PostAttachmentViewData
  onDelete: ((attachmentKey: string) => void) | null
  onPhotoClicked: (attachmentKey: string) => void
  onPhotoViewed: (attachmentKey: string) => void
} & (
  | {
      isAllPhotoAttachmentsVisible: true
      projectKey?: string
      postKey?: string
    }
  | {
      isAllPhotoAttachmentsVisible: false
      projectKey: string
      postKey: string
    }
)) => {
  const theme = useTheme()
  const isCompactScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const photosLayoutInfo = useMemo(() => {
    const maxPhotosForHeader = 4
    const maxPhotosPerRow = isCompactScreen ? 2 : 3
    const allPhotos = viewData.photos?.asJsReadonlyArrayView() || []
    const featuredPhotos = allPhotos.slice(0, maxPhotosForHeader)
    const remainingPhotos =
      allPhotos.length > maxPhotosForHeader
        ? allPhotos.slice(maxPhotosForHeader)
        : []
    const remainingPhotosByRows =
      remainingPhotos.length > 0
        ? splitPhotos(remainingPhotos, maxPhotosPerRow)
        : []
    return {
      featuredPhotos: featuredPhotos,
      remainingPhotosByRows: remainingPhotosByRows,
      remainingPhotosCount: remainingPhotos.length,
    }
  }, [isCompactScreen, viewData.photos])
  const overflowContent =
    !isAllPhotoAttachmentsVisible &&
    photosLayoutInfo.remainingPhotosCount > 0 ? (
      <MorePhotosOverlayBox
        variant="text"
        href={paths.postPreview(teamKey, projectKey, postKey)}
      >
        <Typography variant="h5">
          {"+"}
          {photosLayoutInfo.remainingPhotosCount}
        </Typography>
      </MorePhotosOverlayBox>
    ) : null
  return (
    <>
      {isCompactScreen ? (
        <PhotosGridCompact
          photos={photosLayoutInfo.featuredPhotos}
          onPhotoClicked={onPhotoClicked}
          onPhotoViewed={onPhotoViewed}
          onDelete={onDelete}
          overflowContent={overflowContent}
        />
      ) : (
        <PhotosGridMedium
          photos={photosLayoutInfo.featuredPhotos}
          onPhotoClicked={onPhotoClicked}
          onPhotoViewed={onPhotoViewed}
          onDelete={onDelete}
          overflowContent={overflowContent}
        />
      )}
      {isAllPhotoAttachmentsVisible &&
        photosLayoutInfo.remainingPhotosByRows.length > 0 &&
        photosLayoutInfo.remainingPhotosByRows.map((photosByRow) => (
          <PhotosRow
            allowPortraitAspectRatio={false}
            photos={photosByRow}
            onPhotoClicked={onPhotoClicked}
            onPhotoViewed={onPhotoViewed}
            onDelete={onDelete}
            overflowContent={null}
          />
        ))}
    </>
  )
}

const PhotosGridCompact = ({
  photos,
  onDelete,
  onPhotoClicked,
  onPhotoViewed,
  overflowContent,
}: {
  photos: shared.com.probuildsoftware.probuild.library.projects.data.view.PostAttachmentPhotoViewData[]
  onDelete: ((attachmentKey: string) => void) | null
  onPhotoClicked: (attachmentKey: string) => void
  onPhotoViewed: (attachmentKey: string) => void
  overflowContent: React.ReactNode | null
}) => {
  const firstPhoto = useMemo(() => photos[0] || [], [photos])
  const remainingPhotos = useMemo(
    () => (photos.length > 1 ? photos.slice(1) : []),
    [photos]
  )
  if (photos.length < 3 && photos.some((photo) => photo.isPortrait)) {
    return (
      <PhotosRow
        allowPortraitAspectRatio={true}
        photos={photos}
        onPhotoClicked={onPhotoClicked}
        onPhotoViewed={onPhotoViewed}
        onDelete={onDelete}
        overflowContent={overflowContent}
      />
    )
  } else if (firstPhoto.isPortrait) {
    const column1Weight = remainingPhotos.length - 1
    const column1AspectRatio = column1Weight / (column1Weight + 1)
    return (
      <PhotosRowDiv>
        <Box
          sx={{
            flexBasis: 0,
            flexGrow: column1Weight,
            flexShrink: column1Weight,
            aspectRatio: column1AspectRatio,
          }}
        >
          <PostAttachmentPhotoView
            onDelete={onDelete}
            viewData={firstPhoto}
            onPhotoClicked={() => {
              onPhotoClicked(firstPhoto.attachmentKey)
            }}
            onPhotoViewed={() => {
              onPhotoViewed(firstPhoto.attachmentKey)
            }}
          />
        </Box>
        <PhotosColumn
          columnWeight={1}
          photos={remainingPhotos}
          onPhotoClicked={onPhotoClicked}
          onPhotoViewed={onPhotoViewed}
          onDelete={onDelete}
          overflowContent={overflowContent}
        />
      </PhotosRowDiv>
    )
  } else {
    return (
      <PhotosColumnDiv>
        <Box
          sx={{
            aspectRatio: 3 / 2,
          }}
        >
          <PostAttachmentPhotoView
            onDelete={onDelete}
            viewData={firstPhoto}
            onPhotoClicked={() => {
              onPhotoClicked(firstPhoto.attachmentKey)
            }}
            onPhotoViewed={() => {
              onPhotoViewed(firstPhoto.attachmentKey)
            }}
          />
        </Box>
        <PhotosRow
          allowPortraitAspectRatio={false}
          photos={remainingPhotos}
          onPhotoClicked={onPhotoClicked}
          onPhotoViewed={onPhotoViewed}
          onDelete={onDelete}
          overflowContent={overflowContent}
        />
      </PhotosColumnDiv>
    )
  }
}

const PhotosGridMedium = ({
  photos,
  onDelete,
  onPhotoClicked,
  onPhotoViewed,
  overflowContent,
}: {
  photos: shared.com.probuildsoftware.probuild.library.projects.data.view.PostAttachmentPhotoViewData[]
  onDelete: ((attachmentKey: string) => void) | null
  onPhotoClicked: (attachmentKey: string) => void
  onPhotoViewed: (attachmentKey: string) => void
  overflowContent: React.ReactNode | null
}) => {
  const focusedPhotos = useMemo(() => photos.slice(0, 2), [photos])
  const remainingPhotos = useMemo(
    () => (photos.length > 2 ? photos.slice(2) : []),
    [photos]
  )
  if (photos.length < 4) {
    return (
      <PhotosRow
        allowPortraitAspectRatio={true}
        photos={photos}
        onPhotoClicked={onPhotoClicked}
        onPhotoViewed={onPhotoViewed}
        onDelete={onDelete}
        overflowContent={overflowContent}
      />
    )
  } else {
    return (
      <PhotosRowDiv>
        {focusedPhotos.map((photo) => {
          const aspectRatio = photo.isPortrait ? 1 : 3 / 2
          return (
            <Box
              sx={{
                flexBasis: 0,
                flexGrow: aspectRatio,
                flexShrink: aspectRatio,
                aspectRatio: aspectRatio,
              }}
            >
              <PostAttachmentPhotoView
                onDelete={onDelete}
                viewData={photo}
                onPhotoClicked={() => {
                  onPhotoClicked(photo.attachmentKey)
                }}
                onPhotoViewed={() => {
                  onPhotoViewed(photo.attachmentKey)
                }}
              />
            </Box>
          )
        })}
        <PhotosColumn
          columnWeight={1 / remainingPhotos.length}
          photos={remainingPhotos}
          onPhotoClicked={onPhotoClicked}
          onPhotoViewed={onPhotoViewed}
          onDelete={onDelete}
          overflowContent={overflowContent}
        />
      </PhotosRowDiv>
    )
  }
}

const PhotosRow = ({
  allowPortraitAspectRatio,
  photos,
  onDelete,
  onPhotoClicked,
  onPhotoViewed,
  overflowContent,
}: {
  allowPortraitAspectRatio: boolean
  photos: shared.com.probuildsoftware.probuild.library.projects.data.view.PostAttachmentPhotoViewData[]
  onDelete: ((attachmentKey: string) => void) | null
  onPhotoClicked: (attachmentKey: string) => void
  onPhotoViewed: (attachmentKey: string) => void
  overflowContent: React.ReactNode | null
}) => {
  const isAllPortrait = useMemo(
    () => photos.every((photo) => photo.isPortrait),
    [photos]
  )
  return (
    <PhotosRowDiv>
      {photos.map((photo, index) => {
        const columnWeight = photo.isPortrait ? 1 : 3 / 2
        const columnAspectRatio =
          allowPortraitAspectRatio && isAllPortrait ? 2 / 3 : columnWeight
        return (
          <Box
            sx={{
              position: "relative",
              flexBasis: 0,
              flexGrow: columnWeight,
              flexShrink: columnWeight,
              aspectRatio: columnAspectRatio,
              maxHeight: "80vh",
            }}
          >
            <PostAttachmentPhotoView
              onDelete={onDelete}
              viewData={photo}
              onPhotoClicked={() => {
                onPhotoClicked(photo.attachmentKey)
              }}
              onPhotoViewed={() => {
                onPhotoViewed(photo.attachmentKey)
              }}
            />
            {index === photos.length - 1 && overflowContent}
          </Box>
        )
      })}
    </PhotosRowDiv>
  )
}

const PhotosColumn = ({
  columnWeight,
  photos,
  onDelete,
  onPhotoClicked,
  onPhotoViewed,
  overflowContent,
}: {
  columnWeight: number
  photos: shared.com.probuildsoftware.probuild.library.projects.data.view.PostAttachmentPhotoViewData[]
  onDelete: ((attachmentKey: string) => void) | null
  onPhotoClicked: (attachmentKey: string) => void
  onPhotoViewed: (attachmentKey: string) => void
  overflowContent: React.ReactNode | null
}) => {
  return (
    <Box
      sx={{
        flexBasis: 0,
        flexGrow: columnWeight,
        flexShrink: columnWeight,
      }}
    >
      {photos.map((photo, index) => (
        <Box
          sx={{
            position: "relative",
            flexBasis: 0,
            flexShrink: 1,
            flexGrow: 1,
            aspectRatio: 1,
          }}
        >
          <PostAttachmentPhotoView
            onDelete={onDelete}
            viewData={photo}
            onPhotoClicked={() => {
              onPhotoClicked(photo.attachmentKey)
            }}
            onPhotoViewed={() => {
              onPhotoViewed(photo.attachmentKey)
            }}
          />
          {index === photos.length - 1 && overflowContent}
        </Box>
      ))}
    </Box>
  )
}

function splitPhotos(
  photos: shared.com.probuildsoftware.probuild.library.projects.data.view.PostAttachmentPhotoViewData[],
  chunkSize: number
): shared.com.probuildsoftware.probuild.library.projects.data.view.PostAttachmentPhotoViewData[][] {
  const chunks =
    Array<
      shared.com.probuildsoftware.probuild.library.projects.data.view.PostAttachmentPhotoViewData[]
    >()
  for (let i = 0; i < photos.length; i += chunkSize) {
    chunks.push(photos.slice(i, i + chunkSize))
  }
  return chunks
}

export default PostAttachmentGalleryView
