import { createContext } from "react"

import * as shared from "probuild-shared"

const DependencyProviderContext =
  createContext<shared.com.probuildsoftware.probuild.library.common.model.dependencies.DependencyProvider | null>(
    null
  )

export default DependencyProviderContext
