import {
  Query,
  QuerySnapshot,
  limit,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore"

import DocumentObjectSnapshot from "./DocumentObjectSnapshot"
import LiveListFilter from "./LiveListFilter"
import LiveListReducer from "./LiveListReducer"
import LiveListSorter from "./LiveListSorter"

class LiveList<T> {
  public lastSnapshot: QuerySnapshot | null
  public rawData: Array<DocumentObjectSnapshot<T>>
  public data: Array<DocumentObjectSnapshot<T>>
  public isLoading: boolean
  public filter: LiveListFilter<T> | null
  public sorter: LiveListSorter<T> | null
  public reducer: LiveListReducer<T> | null

  private query: Query
  private sortedQuery: Query
  private limitTo: number
  private limitIncrement: number
  private unsubscribe: (() => void) | null
  private observer: (() => void) | null

  constructor(query: Query, limitTo = 25, limitIncrement = 25) {
    this.query = query
    this.sortedQuery = query
    this.limitTo = limitTo
    this.limitIncrement = limitIncrement
    this.unsubscribe = null
    this.observer = null
    this.data = []
    this.lastSnapshot = null
    this.rawData = []
    this.isLoading = false
    this.filter = null
    this.sorter = null
    this.reducer = null
  }

  public startListening(observer: () => void) {
    this.observer = observer
    this.loadData()
  }

  public updateSortBy(field: string) {
    this.sortedQuery = query(this.query, orderBy(field, "desc"))
    this.loadData()
  }

  public loadMoreData() {
    if (this.lastSnapshot == null || this.limitTo === this.lastSnapshot.size) {
      this.limitTo += this.limitIncrement
      this.loadData()
    }
  }

  public stopListening() {
    if (this.unsubscribe != null) {
      this.unsubscribe()
    }
  }

  private loadData() {
    this.stopListening()
    this.isLoading = true
    this.unsubscribe = onSnapshot(
      query(this.sortedQuery, limit(this.limitTo)),
      (snapshot) => {
        this.transformSnapshot(snapshot)
        this.isLoading = false
      }
    )
  }

  private transformSnapshot(snapshot: QuerySnapshot) {
    this.lastSnapshot = snapshot
    const newData: Array<DocumentObjectSnapshot<T>> = []
    snapshot.forEach((doc) => {
      const data = doc.data()
      const documentObjectSnapshot: DocumentObjectSnapshot<T> = {
        id: doc.id,
        value: data as T,
      }
      if (this.filter == null || this.filter(documentObjectSnapshot)) {
        newData.push(documentObjectSnapshot)
      }
    })
    this.rawData = newData
    if (this.sorter != null) {
      newData.sort(this.sorter)
    }
    if (this.reducer != null) {
      this.data = this.reducer(newData)
    } else {
      this.data = newData
    }
    if (this.observer == null) {
      return
    }
    this.observer()
  }
}

export default LiveList
