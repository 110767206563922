import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material"

import * as shared from "probuild-shared"

const ConfirmationDialog = ({
  open,
  dialogViewData,
  onConfirm,
  onCancel,
}: {
  open: boolean
  dialogViewData:
    | shared.com.probuildsoftware.probuild.library.common.data.view.DialogViewData
    | null
    | undefined
  onConfirm: () => void
  onCancel: () => void
}) => {
  return (
    (dialogViewData || null) && (
      <Dialog
        open={open}
        aria-labelledby="confirmation-dialog-title"
        aria-describedby="confirmation-dialog-description"
      >
        <DialogTitle id="confirmation-dialog-title">
          {dialogViewData?.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirmation-dialog-description">
            {dialogViewData?.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="primary">
            {dialogViewData?.cancelButtonText}
          </Button>
          {dialogViewData?.confirmButtonText && (
            <Button
              onClick={onConfirm}
              variant="contained"
              color={
                dialogViewData?.confirmButtonIsDestructive ? "error" : "primary"
              }
              autoFocus
            >
              {dialogViewData?.confirmButtonText}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    )
  )
}

export default ConfirmationDialog
