import "core-js/es"
import { createRoot } from "react-dom/client"

import * as serviceWorker from "registerServiceWorker"
import App from "components/App"

const root = createRoot(document.getElementById("root") as HTMLElement)
root.render(<App />)

serviceWorker.unregister()
