import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import { Box } from "@mui/system"

import * as shared from "probuild-shared"

import ViewDataTextField from "components/views/generic/ViewDataTextField"

const StyledBox = styled(Box)({
  display: "flex",
  width: "fit-content",
  margin: "auto",
  paddingTop: 16,
})

const TextFieldDialog = ({
  open,
  dialogViewData,
  onTextChange,
  onConfirm,
  onCancel,
}: {
  open: boolean
  dialogViewData: shared.com.probuildsoftware.probuild.library.common.data.view.TextFieldDialogViewData | null
  onTextChange: (text: string) => void
  onConfirm: () => void
  onCancel: () => void
}) => {
  return (
    dialogViewData && (
      <Dialog
        open={open}
        aria-labelledby="text-field-dialog-title"
        aria-describedby="text-field-dialog-description"
      >
        <DialogTitle id="text-field-dialog-title">
          {dialogViewData?.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="text-field-dialog-description">
            {dialogViewData?.message}
          </DialogContentText>
          {dialogViewData?.textField && (
            <StyledBox>
              <ViewDataTextField
                viewData={dialogViewData?.textField}
                variant={"outlined"}
                onChange={onTextChange}
              />
            </StyledBox>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="primary">
            {dialogViewData?.cancelButtonText}
          </Button>
          <Button
            color="primary"
            onClick={() => {
              onConfirm()
            }}
          >
            {dialogViewData?.confirmButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    )
  )
}

export default TextFieldDialog
