import { useMemo, useState } from "react"

import * as shared from "probuild-shared"

import useDependencyProvider from "model/utils/useDependencyProvider"
import usePresenter from "model/presenter/usePresenter"

function useAuthPhoneNumber(): [
  boolean,
  shared.com.probuildsoftware.probuild.library.auth.data.view.AuthPhoneNumberViewData | null,
  shared.com.probuildsoftware.probuild.library.auth.AuthPhoneNumberInput | null
] {
  const [navigateToSecureCodeEntry, setNavigateToSecureCodeEntry] =
    useState<boolean>(false)
  const dependencyProvider = useDependencyProvider()
  const eventsObserver = useMemo(() => {
    return {
      onCompletion() {
        setNavigateToSecureCodeEntry(true)
      },
    }
  }, [])
  const presenter = useMemo(() => {
    if (!dependencyProvider) {
      return
    }
    return new shared.com.probuildsoftware.probuild.library.auth.AuthPhoneNumberPresenter(
      dependencyProvider
    )
  }, [dependencyProvider])
  const [viewData, input] = usePresenter(presenter, eventsObserver)
  return [navigateToSecureCodeEntry, viewData, input]
}

export default useAuthPhoneNumber
