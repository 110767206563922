import { Box, Typography } from "@mui/material"

const PageHeader = ({
  title,
  subtitle,
}: {
  title: string | undefined
  subtitle: string | undefined
}) => {
  return (
    <div>
      <Box m={1}>
        <Typography variant="h4">{title}</Typography>
      </Box>
      <Box my={2} mx={1}>
        <Typography>{subtitle}</Typography>
      </Box>
    </div>
  )
}

export default PageHeader
