import AuthAccountSetupPage from "components/pages/AuthAccountSetupPage"
import AuthPhonePage from "components/pages/AuthPhoneNumberPage"
import AuthSecureCodePage from "components/pages/AuthSecureCodePage"
import NotFoundPage from "components/pages/NotFoundPage"
import useAccountsList from "model/app/useAccountsList"
import paths from "model/utils/paths"
import { Routes, Route, Navigate, Outlet } from "react-router-dom"

const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<AuthRootRoute />}>
        <Route path="phone" element={<AuthPhonePage />} />
        <Route path="code" element={<AuthSecureCodePage />} />
        <Route path="setup" element={<AuthAccountSetupPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  )
}

const AuthRootRoute = () => {
  const [accounts] = useAccountsList()
  const currentAccountTeamKey = accounts?.currentAccount?.teamKey
  if (!accounts) {
    return <></>
  } else if (currentAccountTeamKey) {
    return <Navigate to={paths.team(currentAccountTeamKey)} replace={true} />
  } else if (accounts.isSupportAgent) {
    return <Navigate to={paths.supportAgentFeed()} replace={true} />
  } else {
    return <Outlet />
  }
}

export default AuthRoutes
