import shared from "probuild-shared"

import { getDatabase, ref, DataSnapshot, onValue, off } from "firebase/database"

class LiveConnectivityApi
  implements
    shared.com.probuildsoftware.probuild.library.common.model.connectivity.api
      .ConnectivityApi
{
  observe(onChange: (isOnline: boolean) => void): () => void {
    const connectedRef = ref(getDatabase(), ".info/connected")
    const snapshotObserver = (snapshot: DataSnapshot | null) => {
      onChange(snapshot?.val() === true)
    }
    onValue(connectedRef, snapshotObserver)
    return () => {
      off(connectedRef, "value", snapshotObserver)
    }
  }
}

export default LiveConnectivityApi
