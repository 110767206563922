import FeedListItem from "model/feed/item/FeedListItem"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import DescriptionIcon from "@mui/icons-material/Description"
import FeedItemHeaderView from "components/views/feed/FeedItemHeaderView"
import FeedItemContentView from "../FeedItemContentView"

const WorkflowItemView = ({
  item,
  showTeamName,
}: {
  item: FeedListItem
  showTeamName: boolean
}) => {
  return (
    <Box mb={1}>
      <Card>
        <FeedItemHeaderView
          info={item.info}
          links={[
            {
              name: "View Report (Local)",
              url: item.workflowContent?.pdfReportLocal || null,
              icon: <DescriptionIcon />,
            },
            {
              name: "View Report (Existing)",
              url: item.workflowContent?.pdfReportExisting || null,
              icon: <DescriptionIcon />,
            },
            {
              name: "View Document (Preview)",
              url: item.workflowContent?.documentPath || null,
              icon: <DescriptionIcon />,
            },
          ]}
          showTeamName={showTeamName}
        />
        <FeedItemContentView
          item={item}
          webPortalPath={item.workflowContent?.pdfReportFile}
        />
      </Card>
    </Box>
  )
}

export default WorkflowItemView
