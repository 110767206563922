import { styled } from "@mui/material/styles"
import { Button, Typography } from "@mui/material"
import PhoneIcon from "@mui/icons-material/Phone"
import EmailIcon from "@mui/icons-material/Email"
import WebIcon from "@mui/icons-material/Web"

import TeamPageLayout from "components/views/layouts/TeamPageLayout"
import useHelpAndFeedback from "model/help/useHelpAndFeedback"

const BodyTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(5),
}))

const HelpOptionsDiv = styled("div")({
  display: "flex",
  justifyContent: "space-evenly",
})

const HelpOptionDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  color: theme.palette.primary.light,
  "& > *": {
    margin: theme.spacing(1),
    flexGrow: 1,
    textTransform: "none",
  },
}))

const HelpAndFeedbackPage = ({ teamKey }: { teamKey: string }) => {
  const viewData = useHelpAndFeedback({ teamKey })
  return (
    <TeamPageLayout title={viewData?.title} showLoadingSpinner={!viewData}>
      {viewData && (
        <>
          <BodyTypography>{viewData.body}</BodyTypography>
          <HelpOptionsDiv>
            <Button variant="outlined" href={viewData.phoneButtonActionUrl}>
              <HelpOptionDiv>
                <PhoneIcon fontSize="large" />
                <Typography>{viewData.phoneButtonText}</Typography>
              </HelpOptionDiv>
            </Button>
            <Button variant="outlined" href={viewData.emailButtonActionUrl}>
              <HelpOptionDiv>
                <EmailIcon fontSize="large" />
                <Typography>{viewData.emailButtonText}</Typography>
              </HelpOptionDiv>
            </Button>
            <Button variant="outlined" href={viewData.websiteButtonActionUrl}>
              <HelpOptionDiv>
                <WebIcon fontSize="large" />
                <Typography>{viewData.websiteButtonText}</Typography>
              </HelpOptionDiv>
            </Button>
          </HelpOptionsDiv>
        </>
      )}
    </TeamPageLayout>
  )
}

export default HelpAndFeedbackPage
