import { Box, Typography } from "@mui/material"

import { styled } from "@mui/material/styles"

import * as shared from "probuild-shared"

import NavigationBarDialog from "components/dialogs/NavigationBarDialog"
import SaveButton from "components/views/generic/SaveButton"
import ViewDataTextField from "components/views/generic/ViewDataTextField"
import LabeledCard from "components/views/generic/LabeledCard"
import ViewDataToggleButtonGroup from "components/views/generic/ViewDataToggleButtonGroup"

const CenteredBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
})

const DocumentEditingMarkupOrDiscountDialog = ({
  input,
  viewData,
}: {
  input: shared.com.probuildsoftware.probuild.library.documents.DocumentInput
  viewData: shared.com.probuildsoftware.probuild.library.documents.data.view.dialogs.DocumentMarkupOrDiscountDialogViewData
}) => {
  return (
    <NavigationBarDialog
      isOpen={true}
      onClose={() => {
        input.closeMarkupOrDiscountDialog()
      }}
      title={viewData.title}
      dynamicHeight={true}
      barItems={
        <SaveButton
          text={viewData.doneButtonText}
          isEnabled={true}
          onClick={() => {
            input.closeMarkupOrDiscountDialog()
          }}
        />
      }
    >
      <LabeledCard text="">
        <div>
          <CenteredBox>
            {viewData.topExplanationText && (
              <Typography sx={{ width: "300px" }} color={"textSecondary"}>
                {viewData.topExplanationText}
              </Typography>
            )}
            <ViewDataToggleButtonGroup
              sx={{ mt: 1 }}
              viewData={viewData.unitToggleButtonGroup}
              onChange={(selectedUnitIdentifier) => {
                input.changeMarkupOrDiscountUnit(
                  viewData.markupOrDiscountUnitElementKey,
                  selectedUnitIdentifier
                )
              }}
            />
            <Box marginTop={1}>
              <ViewDataTextField
                sx={{ width: "300px" }}
                viewData={viewData.amountTextField}
                onChange={(newText) => {
                  input?.changeElementText(
                    viewData.documentAnswerKey,
                    viewData.markupOrDiscountAmountElementKey,
                    newText
                  )
                }}
              />
            </Box>
          </CenteredBox>
        </div>
      </LabeledCard>
    </NavigationBarDialog>
  )
}

export default DocumentEditingMarkupOrDiscountDialog
