import { useMemo } from "react"

import * as shared from "probuild-shared"

import useDependencyProvider from "model/utils/useDependencyProvider"
import useAppUser from "model/utils/useAppUser"
import usePresenter from "model/presenter/usePresenter"

function useTimesheetUser({
  teamKey,
  userKey,
  projectKey,
}: {
  teamKey: string
  userKey: string
  projectKey: string | undefined
}): [
  shared.com.probuildsoftware.probuild.library.timesheets.data.view.TimesheetUserViewData | null,
  shared.com.probuildsoftware.probuild.library.common.model.presenter.EmptyInput | null
] {
  const appUser = useAppUser({ teamKey })
  const dependencyProvider = useDependencyProvider()
  const presenter = useMemo(() => {
    if (dependencyProvider && appUser && projectKey) {
      console.log(
        `Creating timesheet user presenter teamKey = ${appUser.teamKey} userKey = ${userKey} projectKey = ${projectKey}`
      )
      return new shared.com.probuildsoftware.probuild.library.timesheets.TimesheetUserPresenter(
        dependencyProvider,
        appUser,
        {
          userKey,
          projectKey,
        }
      )
    }
  }, [appUser, userKey, projectKey, dependencyProvider])
  return usePresenter(presenter)
}

export default useTimesheetUser
