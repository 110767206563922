import useAppUser from "model/utils/useAppUser"
import useDependencyProvider from "model/utils/useDependencyProvider"
import { useEffect, useState } from "react"

import * as shared from "probuild-shared"

function useDocumentViewData({
  teamKey,
  documentKey,
  documentType,
  observer,
}: {
  teamKey: string
  documentKey: string | undefined
  documentType: string | undefined
  observer: shared.com.probuildsoftware.probuild.library.documents.DocumentEventObserver
}): [
  shared.com.probuildsoftware.probuild.library.documents.data.view.DocumentViewData | null,
  shared.com.probuildsoftware.probuild.library.documents.DocumentInput | null
] {
  const [viewData, setViewData] =
    useState<shared.com.probuildsoftware.probuild.library.documents.data.view.DocumentViewData | null>(
      null
    )
  const [input, setInput] =
    useState<shared.com.probuildsoftware.probuild.library.documents.DocumentInput | null>(
      null
    )
  const dependencyProvider = useDependencyProvider()
  const appUser = useAppUser({ teamKey })
  const documentDefinitionKey = determineDocumentDefinitionKey(documentType)
  useEffect(() => {
    if (
      dependencyProvider &&
      appUser &&
      (documentKey || documentDefinitionKey)
    ) {
      console.log(
        `Creating document presenter teamKey = ${appUser.teamKey} userKey = ${appUser.userKey} documentKey = ${documentKey} documentDefinitionKey = ${documentDefinitionKey}`
      )
      const presenter =
        new shared.com.probuildsoftware.probuild.library.documents.DocumentPresenter(
          dependencyProvider,
          null,
          appUser,
          {
            projectKey: null,
            documentDefinitionKey,
            documentKey,
            restoreStateJson: null,
            isCopyAndConvertSupported: false,
            isPrintSupported: false,
            isSharePdfSupported: false,
            isDeleteSupported: true,
          }
        )
      setInput(presenter.input)
      const cleanUpEvents = presenter.observeEvents(observer)
      const cleanUpViewData = presenter.observeViewData({
        onDocumentViewDataChanged(
          viewData: shared.com.probuildsoftware.probuild.library.documents.data.view.DocumentViewData
        ) {
          setViewData(viewData)
        },
      })
      return () => {
        cleanUpEvents()
        cleanUpViewData()
      }
    }
  }, [
    appUser,
    dependencyProvider,
    observer,
    documentKey,
    documentDefinitionKey,
  ])
  return [viewData, input]
}

function determineDocumentDefinitionKey(
  documentType: string | undefined
): string | null {
  const Companion =
    shared.com.probuildsoftware.probuild.library.documents.DocumentPresenter
      .Companion
  if (documentType === "estimate") {
    return Companion.DEFAULT_ESTIMATE_DEFINITION_KEY
  } else if (documentType === "invoice") {
    return Companion.DEFAULT_INVOICE_DEFINITION_KEY
  }
  return null
}

export default useDocumentViewData
