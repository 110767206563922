import React, { useState, useEffect } from "react"

import UserService from "model/user/UserService"
import CookieProvider from "model/cookies/CookieProvider"
import SignedInUser from "model/user/SignedInUser"
import UserState from "model/user/UserState"
import UserContext from "contexts/UserContext"

const userService = new UserService()
const cookieProvider = new CookieProvider()

const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [userState, setUserState] = useState<UserState>({
    signedInUser: null,
    checked: false,
  })
  useEffect(() => {
    return userService.addSignedInUserChangedObserver({
      onSignedInUserChanged(signedInUser: SignedInUser | null) {
        if (signedInUser) {
          cookieProvider.setSessionCookie(signedInUser.idToken)
        } else {
          cookieProvider.deleteSessionCookie()
        }
        setUserState({
          signedInUser: signedInUser,
          checked: true,
        })
      },
    })
  }, [])
  return (
    <UserContext.Provider value={userState}>{children}</UserContext.Provider>
  )
}

export { userService }
export default UserProvider
