import React from "react"
import { Avatar, Box, ListItem, Paper, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import { Timer } from "@mui/icons-material"
import { green } from "@mui/material/colors"

import NavigationBarDialog from "components/dialogs/NavigationBarDialog"
import ListDialogContent from "components/dialogs/ListDialogContent"
import useTimesheetUser from "model/timesheets/useTimesheetUser"

const TextContainerDiv = styled("div")(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
}))

const TableCellContent = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
})

const DayHeaderBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  backgroundColor: "lightgray",
  borderTopLeftRadius: "15px",
  borderBottomLeftRadius: "15px",
}))

const TotalBackground = styled(Paper)(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  display: "flex",
  justifyContent: "space-between",
}))

const ListItemChildDiv = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
})

const TimesheetUserDialog = ({
  teamKey,
  projectKey,
  userKey,
  isOpen,
  onClose,
}: {
  teamKey: string
  projectKey: string | undefined
  userKey: string
  isOpen: boolean
  onClose: () => void
}) => {
  const [viewData] = useTimesheetUser({ teamKey, userKey, projectKey })
  const userListItems = viewData?.items?.asJsReadonlyArrayView()
  return (
    <NavigationBarDialog
      isOpen={isOpen}
      onClose={onClose}
      title={viewData?.userName}
      barItems={<></>}
    >
      <ListDialogContent emptyViewData={viewData?.empty} emptyIcon={null}>
        <TotalBackground>
          <Typography>{viewData?.totalTimeLabel}</Typography>
          <Typography>{viewData?.totalTime}</Typography>
        </TotalBackground>
        {userListItems?.map((listItem) => {
          return (
            <>
              {listItem.header && (
                <Box sx={{ display: "flex", pl: 3 }}>
                  <Timer sx={{ color: "lightgray", visibility: "hidden" }} />
                  <DayHeaderBox>
                    <TableCellContent>
                      <TextContainerDiv>
                        <Box>{listItem.header?.date}</Box>
                      </TextContainerDiv>
                    </TableCellContent>
                    <Typography sx={{ pr: 3 }}>
                      {listItem.header?.totalTime}
                    </Typography>
                  </DayHeaderBox>
                </Box>
              )}
              {listItem.timesheet && (
                <ListItem
                  key={listItem.viewKey}
                  sx={{ width: "100%", px: 3 }}
                  divider={false}
                >
                  <ListItemChildDiv>
                    <TableCellContent>
                      <Avatar
                        sx={{
                          backgroundColor: listItem.timesheet.isClockedIn
                            ? green[500]
                            : "#00000000",
                        }}
                      >
                        <Timer
                          sx={{
                            color: listItem.timesheet.isClockedIn
                              ? "white"
                              : "lightgray",
                          }}
                        />
                      </Avatar>
                      <TextContainerDiv>
                        <Box>{listItem.timesheet?.projectName}</Box>
                        <Box sx={{ color: "gray" }}>
                          {listItem.timesheet?.clockInTime} -{" "}
                          {listItem.timesheet?.clockOutTime}
                        </Box>
                      </TextContainerDiv>
                    </TableCellContent>
                    <Typography>{listItem.timesheet?.totalTime}</Typography>
                  </ListItemChildDiv>
                </ListItem>
              )}
            </>
          )
        })}
      </ListDialogContent>
    </NavigationBarDialog>
  )
}

export default TimesheetUserDialog
