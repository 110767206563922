import { useMemo } from "react"

import * as shared from "probuild-shared"

import useDependencyProvider from "model/utils/useDependencyProvider"
import usePresenter from "model/presenter/usePresenter"

function useAuthIntentSelectionViewData(): shared.com.probuildsoftware.probuild.library.auth.data.view.AuthIntentSelectionViewData | null {
  const dependencyProvider = useDependencyProvider()
  const presenter = useMemo(() => {
    if (!dependencyProvider) {
      return
    }
    return new shared.com.probuildsoftware.probuild.library.auth.AuthIntentSelectionPresenter(
      dependencyProvider
    )
  }, [dependencyProvider])
  const [viewData] = usePresenter(presenter)
  return viewData
}

export default useAuthIntentSelectionViewData
