import React from "react"
import { styled } from "@mui/material/styles"
import { Button } from "@mui/material"

import ProbuildNavigationBar from "components/views/ProbuildNavigationBar"
import useAccountsList from "model/app/useAccountsList"

const LogoutButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}))

const ContentDiv = styled("div")({
  margin: "70px auto 20px auto",
  maxWidth: 1000,
})

const SupportAgentPageLayout = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [, input] = useAccountsList()
  return (
    <div>
      <ProbuildNavigationBar>
        <LogoutButton
          onClick={() => {
            input?.signOut()
          }}
        >
          Logout
        </LogoutButton>
      </ProbuildNavigationBar>
      <ContentDiv>{children}</ContentDiv>
    </div>
  )
}

export default SupportAgentPageLayout
