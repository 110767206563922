import { useState } from "react"
import { styled } from "@mui/material/styles"
import { Button, Typography } from "@mui/material"
import ReactSignatureCanvas from "react-signature-canvas"

import * as shared from "probuild-shared"

import ImageView from "components/views/generic/ImageView"
import NavigationBarDialog from "components/dialogs/NavigationBarDialog"

const signatureCanvasWidth = 600
const signatureLinePadding = 50
const signatureCanvasHeight = 300

const ContainerDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
})

const SigningContainerDiv = styled("div")(({ theme }) => ({
  width: `${signatureCanvasWidth}px`,
  height: `${signatureCanvasHeight}px`,
  backgroundColor: theme.palette.background.paper,
}))

const SignatureCanvasDiv = styled("div")({
  position: "relative",
  top: 0,
  left: 0,
})

const SignatureLineContainerDiv = styled("div")({
  position: "absolute",
  top: `${signatureCanvasHeight - signatureLinePadding}px`,
  left: `${signatureLinePadding}px`,
  width: `${signatureCanvasWidth - 2 * signatureLinePadding}px`,
})

const SignatureLineDiv = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  height: "4px",
}))

const SignatureLineTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.light,
  userSelect: "none",
}))

const SignatureImageView = styled(ImageView)(({ theme }) => ({
  width: "400px",
  height: "200px",
  objectFit: "contain",
  marginBottom: theme.spacing(2),
}))

const RepeatItemRow = ({
  documentAnswerKey,
  userKey,
  element,
  input,
}: {
  documentAnswerKey: string
  userKey: string
  element: shared.com.probuildsoftware.probuild.library.documents.data.view.elements.ElementViewData
  input: shared.com.probuildsoftware.probuild.library.documents.DocumentInput
}) => {
  const [isSigning, setIsSigning] = useState<boolean>(false)
  const [signatureCanvas, setSignatureCanvas] =
    useState<ReactSignatureCanvas | null>(null)

  const file = element.files?.asJsReadonlyArrayView()?.find((x) => true) || null
  const onSignatureCompletion = () => {
    const base64Signature = signatureCanvas?.getTrimmedCanvas()?.toDataURL()
    if (base64Signature && signatureCanvas?.isEmpty() === false) {
      const signatureBlob = convertBase64ToBlob(base64Signature)
      const signatureUrl = URL.createObjectURL(signatureBlob)
      input.addFile(
        documentAnswerKey,
        element.elementKey,
        documentAnswerKey,
        new shared.com.probuildsoftware.probuild.library.common.data.files.InputFile(
          signatureUrl,
          createSignatureFilename(),
          "image/png",
          signatureBlob.size
        )
      )
    }
    setIsSigning(false)
  }
  const onSignatureClear = () => {
    signatureCanvas?.clear()
  }
  const signature = element.signature
  const changeButtonText = signature?.changeButtonText
  const removeButtonText = signature?.removeButtonText
  return (
    <div>
      <ContainerDiv>
        {file && (
          <SignatureImageView
            file={file}
            alt={element.labelText || ""}
            width={400}
            height={200}
          />
        )}
        <div>
          {changeButtonText && (
            <Button
              onClick={() => setIsSigning(true)}
              variant="contained"
              color="primary"
            >
              {changeButtonText}
            </Button>
          )}
          {removeButtonText && (
            <Button
              sx={{ ml: 1 }}
              onClick={() =>
                file?.info.path && input.removeFile(file?.info.path)
              }
              color="primary"
            >
              {removeButtonText}
            </Button>
          )}
        </div>
      </ContainerDiv>
      <NavigationBarDialog
        title={"Signature"}
        isOpen={isSigning}
        onClose={onSignatureCompletion}
        dynamicHeight
        barItems={
          <>
            <Button
              variant="outlined"
              color="primary"
              onClick={onSignatureClear}
            >
              Clear
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={onSignatureCompletion}
            >
              Done
            </Button>
          </>
        }
      >
        <SigningContainerDiv>
          <SignatureLineContainerDiv>
            <SignatureLineTypography variant="h6">X</SignatureLineTypography>
            <SignatureLineDiv></SignatureLineDiv>
            <SignatureLineTypography
              style={{ width: "100%", textAlign: "center" }}
              variant="h6"
            >
              Sign Here
            </SignatureLineTypography>
          </SignatureLineContainerDiv>
          <SignatureCanvasDiv>
            <ReactSignatureCanvas
              ref={(ref) => {
                setSignatureCanvas(ref)
              }}
              penColor="black"
              canvasProps={{
                width: signatureCanvasWidth,
                height: signatureCanvasHeight,
              }}
            />
          </SignatureCanvasDiv>
        </SigningContainerDiv>
      </NavigationBarDialog>
    </div>
  )
}

const convertBase64ToBlob = (base64Image: string) => {
  const parts = base64Image.split(";base64,")
  const imageType = parts[0].split(":")[1]
  const decodedData = window.atob(parts[1])
  const uInt8Array = new Uint8Array(decodedData.length)
  for (let i = 0; i < decodedData.length; ++i) {
    uInt8Array[i] = decodedData.charCodeAt(i)
  }
  return new Blob([uInt8Array], { type: imageType })
}

const createSignatureFilename = (): string => {
  const now = new Date()
  const year = now.getFullYear()
  const month = `${now.getMonth() + 1}`.padStart(2, "0")
  const day = `${now.getDate()}`.padStart(2, "0")
  const hour = `${now.getHours()}`.padStart(2, "0")
  const minute = `${now.getMinutes()}`.padStart(2, "0")
  const second = `${now.getSeconds()}`.padStart(2, "0")
  return `IMG_${year}${month}${day}_${hour}${minute}${second}.png`
}

export default RepeatItemRow
