import { TextField, Typography, styled } from "@mui/material"
import * as shared from "probuild-shared"
import { NavigateSafeState } from "model/navigation/useNavigateSafe"
import usePromptSafe from "model/navigation/usePromptSafe"
import LabeledCard from "components/views/generic/LabeledCard"
import ViewDataTextField from "components/views/generic/ViewDataTextField"
import ConfirmationDialog from "components/dialogs/ConfirmationDialog"
import LoadingModal from "components/views/generic/LoadingModal"
import { useCallback } from "react"

const StyledDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
}))

const PhoneNumberChangeAddView = ({
  viewData,
  input,
  navigateSafeState,
}: {
  viewData: shared.com.probuildsoftware.probuild.library.users.data.view.PhoneNumberChangeAddViewData | null
  input: shared.com.probuildsoftware.probuild.library.users.PhoneNumberChangeAddInput | null
  navigateSafeState: NavigateSafeState
}) => {
  usePromptSafe({
    when: viewData?.shouldConfirmDiscard === true,
    message: viewData?.discardConfirmationDialog.message || "",
    navigateSafeState: navigateSafeState,
  })
  const onDialogCancel = useCallback(async () => {
    input?.infoDialogDismiss()
  }, [input])
  if (!input || !viewData) return null
  return (
    <StyledDiv>
      <Typography sx={{ mt: 2 }} variant="body1" textAlign="center">
        {viewData.changeRequestMessage}
      </Typography>
      <LabeledCard text={""}>
        <TextField
          label={viewData.currentPhoneNumber.label}
          value={viewData.currentPhoneNumber.value}
          disabled={true}
        />
        {viewData.newPhoneNumber && (
          <ViewDataTextField
            viewData={viewData.newPhoneNumber}
            onChange={(text) => input?.updatePhoneNumber(text)}
          />
        )}
      </LabeledCard>
      <ConfirmationDialog
        open={viewData?.infoDialog !== null}
        dialogViewData={viewData?.infoDialog}
        onConfirm={onDialogCancel}
        onCancel={onDialogCancel}
      />
      <LoadingModal open={viewData.isLoadingIndicatorVisible === true} />
    </StyledDiv>
  )
}

export default PhoneNumberChangeAddView
