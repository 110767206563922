import { styled } from "@mui/material/styles"
import { Button, CircularProgress } from "@mui/material"

const ProgressContainerDiv = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  position: "absolute",
  top: 0,
  left: 0,
  padding: theme.spacing(1),
}))

const LoadingButton = ({
  className,
  isLoading,
  variant,
  onClick,
  title,
  disabled,
}: {
  className?: string
  isLoading: boolean
  variant?: "text" | "outlined" | "contained" | undefined
  onClick: () => void
  title: string
  disabled?: boolean
}) => {
  return (
    <Button
      className={className}
      disabled={disabled || isLoading}
      variant={variant}
      color={"primary"}
      onClick={onClick}
    >
      {title}
      {isLoading && (
        <ProgressContainerDiv>
          <CircularProgress
            sx={{
              color: variant === "contained" ? "#FFFFFF" : undefined,
            }}
            size={20}
          />
        </ProgressContainerDiv>
      )}
    </Button>
  )
}

export default LoadingButton
