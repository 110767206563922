import { createContext } from "react"

export interface SnackbarData {
  severity: "success" | "error"
  message: string
}

const SnackbarContext = createContext<{
  snackbar: SnackbarData | null
  setSnackbar: (snackbar: SnackbarData | null) => void
}>({
  snackbar: null,
  setSnackbar: () => {},
})

export default SnackbarContext
