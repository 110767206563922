import { useCallback, useContext } from "react"

import SnackbarContext from "contexts/SnackbarContext"

function useSnackbar(): (
  message: string,
  severity: "success" | "error"
) => void {
  const { setSnackbar } = useContext(SnackbarContext)
  return useCallback(
    (message: string, severity: "success" | "error") => {
      setSnackbar({ message: message, severity: severity })
    },
    [setSnackbar]
  )
}

export default useSnackbar
