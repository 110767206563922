import { styled } from "@mui/material/styles"
import { Typography } from "@mui/material"

import * as shared from "probuild-shared"

const RootDiv = styled("div")({
  display: "flex",
  justifyContent: "space-between",
})

const MarginlessTypography = styled(Typography)({
  margin: "0px",
})

const Summary = ({
  element,
}: {
  element: shared.com.probuildsoftware.probuild.library.documents.data.view.elements.ElementViewData
}) => {
  return (
    <RootDiv>
      <MarginlessTypography>{element.labelText}</MarginlessTypography>
      <MarginlessTypography>{element.textField?.text}</MarginlessTypography>
    </RootDiv>
  )
}

export default Summary
