import { useMemo } from "react"

import * as shared from "probuild-shared"

import useDependencyProvider from "model/utils/useDependencyProvider"
import useAppUser from "model/utils/useAppUser"
import usePresenter from "model/presenter/usePresenter"

function usePostPreview({
  teamKey,
  projectKey,
  postKey,
  observer,
}: {
  teamKey: string
  projectKey: string | undefined
  postKey: string | undefined
  observer: shared.com.probuildsoftware.probuild.library.projects.PostPreviewEventObserver
}): [
  shared.com.probuildsoftware.probuild.library.projects.data.view.PostPreviewViewData | null,
  shared.com.probuildsoftware.probuild.library.projects.PostPreviewInput | null
] {
  const appUser = useAppUser({ teamKey })
  const dependencyProvider = useDependencyProvider()
  const presenter = useMemo(() => {
    if (dependencyProvider && appUser && projectKey && postKey) {
      console.log(
        `Creating post preview presenter teamKey = ${appUser.teamKey} userKey = ${appUser.userKey} projectKey = ${projectKey} postKey = ${postKey}`
      )
      return new shared.com.probuildsoftware.probuild.library.projects.PostPreviewPresenter(
        dependencyProvider,
        null,
        appUser,
        {
          projectKey,
          postKey,
          isShareFilesSupported: false,
          isSendEmailSupported: false,
        }
      )
    }
  }, [appUser, projectKey, postKey, dependencyProvider])
  return usePresenter(presenter, observer)
}

export default usePostPreview
