import React, { useState } from "react"
import {
  Button,
  ListItemText,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { Add } from "@mui/icons-material"
import { Link } from "react-router-dom"

import CreateButton from "./CreateButton"

const CollapsibleCreateButtons = ({
  buttons,
}: {
  buttons: { to: string; text: string }[]
}) => {
  const [moreAnchorElement, setMoreAnchorElement] =
    useState<null | HTMLElement>(null)
  const handleMoreClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMoreAnchorElement(event.currentTarget)
  }
  const handleMoreClosed = () => {
    setMoreAnchorElement(null)
  }
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"))
  if (buttons.length === 0) return null
  if (isSmallScreen) {
    const firstButton = buttons[0]
    return (
      <>
        <Button
          variant="contained"
          aria-label={firstButton.text}
          onClick={handleMoreClicked}
          color="primary"
          component={buttons.length > 1 ? "button" : Link}
          to={firstButton.to}
        >
          <Add />
        </Button>
        <Menu
          anchorEl={moreAnchorElement}
          keepMounted
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(moreAnchorElement)}
          onClick={handleMoreClosed}
          onClose={handleMoreClosed}
        >
          {buttons.map((button) => (
            <MenuItem component={Link} to={button.to}>
              <ListItemText>{button.text}</ListItemText>
            </MenuItem>
          ))}
        </Menu>
      </>
    )
  } else {
    return (
      <>
        {buttons.map((button) => (
          <CreateButton to={button.to} text={button.text} />
        ))}
      </>
    )
  }
}

export default CollapsibleCreateButtons
