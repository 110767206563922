import { Badge, useTheme } from "@mui/material"

import * as shared from "probuild-shared"

import UserAvatar from "components/views/users/UserAvatar"
import StyledAvatar from "components/views/generic/StyledAvatar"
import colorToCss from "model/utils/colorToCss"

const AccountAvatars = ({
  accountViewData,
  light,
  size,
}: {
  accountViewData: shared.com.probuildsoftware.probuild.library.app.data.view.AccountViewData | null
  light: boolean
  size: number
}) => {
  const theme = useTheme()
  const userAvatarSize = size / 2
  const borderColor = light
    ? theme.palette.common.white
    : theme.palette.primary.main
  return (
    <Badge
      overlap="circular"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      badgeContent={
        <UserAvatar
          viewData={(accountViewData && accountViewData?.userAvatar) || null}
          size={userAvatarSize}
          hideClockedInBadge={true}
          borderColor={borderColor}
        />
      }
    >
      <StyledAvatar
        alt={accountViewData?.teamName}
        variant="rounded"
        file={accountViewData?.teamLogo}
        placeholder={accountViewData?.teamLogoPlaceholder?.oneInitial}
        backgroundColor={colorToCss(
          accountViewData?.teamLogoPlaceholder?.color
        )}
        size={size}
        borderColor={borderColor}
        contain={true}
      />
    </Badge>
  )
}

export default AccountAvatars
