import { Alert, Snackbar, SnackbarCloseReason } from "@mui/material"
import { useContext } from "react"
import SnackbarContext from "contexts/SnackbarContext"

const SnackbarAlert = () => {
  const { snackbar, setSnackbar } = useContext(SnackbarContext)
  const handleClose = (
    _: React.SyntheticEvent<any> | Event,
    reason: SnackbarCloseReason
  ) => {
    if (reason !== "clickaway") setSnackbar(null)
  }
  return (
    snackbar && (
      <Snackbar
        open={true}
        onClose={handleClose}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert variant="filled" severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    )
  )
}

export default SnackbarAlert
