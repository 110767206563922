import {
  getFirestore,
  collection,
  query,
  where,
  orderBy,
  Query,
} from "firebase/firestore"

import * as shared from "probuild-shared"

import LiveList from "model/livelist/LiveList"
import DocumentObjectSnapshot from "model/livelist/DocumentObjectSnapshot"
import paths from "model/utils/paths"
import FeedListObserver from "./FeedListObserver"
import FeedList from "./FeedList"
import FeedListItem from "./item/FeedListItem"
import FeedItem from "./item/FeedItem"
import FeedListItemWorkflow from "./item/FeedListItemWorkflow"
import FeedListItemInfo from "./item/FeedListItemInfo"
import FileLink from "model/files/FileLink"
import CryptoService from "model/crypto/CryptoService"
import TeamDecryptionService from "model/crypto/TeamDecryptionService"
import LiveEnvironmentApi from "model/environment/LiveEnvironmentApi"
import LiveFirebaseAuthApi from "model/auth/LiveFirebaseAuthApi"
import { get, getDatabase, ref } from "firebase/database"
import { useCallback, useEffect, useState } from "react"

class FeedListViewModel {
  private cryptoService = new CryptoService()
  private aesSecretStorageApi =
    new shared.com.probuildsoftware.probuild.library.common.model.crypto.SupportAgentAesSecretStorageApi(
      new LiveFirebaseAuthApi(),
      new LiveEnvironmentApi()
    )
  private teamDecryptionService = new TeamDecryptionService(
    this.aesSecretStorageApi,
    this.cryptoService
  )
  private cachedWorkflowMap = new Map<string, any>()
  private cachedTeamInfoMap = new Map<string, { name: string | null }>()
  private cachedUserInfoMap = new Map<
    string,
    { firstName: string | null; lastName: string | null; userKey: string }
  >()
  private liveList: LiveList<FeedItem>

  constructor(teamKey: string | null, type: string | null) {
    this.liveList = new LiveList<FeedItem>(
      this.createQuery(teamKey, type),
      10,
      10
    )
    this.liveList.filter = (snapshot: DocumentObjectSnapshot<FeedItem>) => {
      return snapshot.value.visible !== false
    }
  }

  observe(observer: FeedListObserver): () => void {
    this.liveList.startListening(async () => {
      const feedList = await this.createFeedList(this.liveList.data)
      observer.onFeedListUpdated(feedList)
    })
    return () => {
      this.liveList.stopListening()
    }
  }

  loadMoreItems() {
    this.liveList.loadMoreData()
  }

  private createQuery(teamKey: string | null, type: string | null): Query {
    if (teamKey && type) {
      return query(
        collection(getFirestore(), "feed"),
        where("teamKey", "==", teamKey),
        where("type", "==", type),
        orderBy("createdAt", "desc")
      )
    } else if (teamKey) {
      return query(
        collection(getFirestore(), "feed"),
        where("teamKey", "==", teamKey),
        orderBy("createdAt", "desc")
      )
    } else if (type) {
      return query(
        collection(getFirestore(), "feed"),
        where("type", "==", type),
        orderBy("createdAt", "desc")
      )
    } else {
      return query(
        collection(getFirestore(), "feed"),
        orderBy("createdAt", "desc")
      )
    }
  }

  private async createFeedList(
    snapshots: DocumentObjectSnapshot<FeedItem>[]
  ): Promise<FeedList> {
    const items = await Promise.all(
      snapshots.map(async (snapshot: DocumentObjectSnapshot<FeedItem>) => {
        const info = await this.createInfoFeedContent(snapshot)
        const item: FeedListItem = {
          key: snapshot.id,
          type: snapshot.value.type,
          info: info,
          webPortalPath: null,
          workflowContent: null,
        }

        let portalPath: string | null = null
        switch (snapshot.value.type) {
          case "documentCreated":
            portalPath = snapshot.value.params.documentKey
              ? paths.document(
                  snapshot.value.teamKey,
                  snapshot.value.params.documentKey
                )
              : null
            break
          case "userPublicCreated":
            portalPath = snapshot.value.params.userKey
              ? paths.userEdit(
                  snapshot.value.teamKey,
                  snapshot.value.params.userKey
                )
              : null
            break
          case "teamCreated":
            portalPath = snapshot.value.teamKey
              ? paths.profile(snapshot.value.teamKey)
              : null
            break
          case "clientCreated":
            portalPath = snapshot.value.params.clientKey
              ? paths.clientEdit(
                  snapshot.value.teamKey,
                  snapshot.value.params.clientKey
                )
              : null
            break
          case "itemCreated":
            portalPath = snapshot.value.params.itemKey
              ? paths.itemEdit(
                  snapshot.value.teamKey,
                  snapshot.value.params.itemKey
                )
              : null
            break
          case "subscriptionCreated":
            portalPath = snapshot.value.teamKey
              ? paths.subscriptions(snapshot.value.teamKey)
              : null
            break
          case "projectCreated":
            portalPath = snapshot.value.params.projectKey
              ? paths.project(
                  snapshot.value.teamKey,
                  snapshot.value.params.projectKey
                )
              : null
            break
          case "postCreated":
            portalPath =
              snapshot.value.params.projectKey && snapshot.value.params.postKey
                ? paths.postPreview(
                    snapshot.value.teamKey,
                    snapshot.value.params.projectKey,
                    snapshot.value.params.postKey
                  )
                : null
            break
          default:
            portalPath = null
        }

        item.webPortalPath = portalPath

        switch (snapshot.value.type) {
          case "workflowCreated":
            item.workflowContent = await this.createWorkflowFeedContent(
              snapshot
            )
            break
          default:
            break
        }

        return item
      })
    )

    const filteredItems = items.filter((x): x is FeedListItem => x !== null)
    return { items: filteredItems }
  }

  private async createInfoFeedContent(
    snapshot: DocumentObjectSnapshot<FeedItem>
  ): Promise<FeedListItemInfo> {
    const teamKey = snapshot.value.teamKey
    const createdBy = snapshot.value.createdBy
    const teamInfo = await this.fetchTeamInfo(teamKey)
    const userInfo = await this.fetchUserInfo(teamKey, createdBy)
    const date = new Date(snapshot.value.createdAt)
    const formattedDate = date.toLocaleDateString("en-us", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    })
    return {
      teamKey: teamKey,
      userKey: userInfo.userKey,
      userName: `${userInfo.firstName} ${userInfo.lastName}`,
      teamName: `${teamInfo.name}`,
      initials: `${userInfo.firstName?.charAt(0)}${userInfo.lastName?.charAt(
        0
      )}`,
      createdAt: formattedDate,
    }
  }

  private async createWorkflowFeedContent(
    snapshot: DocumentObjectSnapshot<FeedItem>
  ): Promise<FeedListItemWorkflow | null> {
    const teamKey = snapshot.value.teamKey
    const workflowKey = snapshot.value.params?.workflowKey
    if (!workflowKey) {
      return null
    }
    var workflow = this.cachedWorkflowMap.get(workflowKey)
    if (!workflow) {
      const workflowSnapshot = await get(
        ref(getDatabase(), `teams/${teamKey}/workflows/${workflowKey}`)
      )
      workflow = workflowSnapshot.val()
      this.cachedWorkflowMap.set(workflowKey, workflow)
    }
    const documentKey = workflow.data?.documentKey
    const files: Array<FileLink> = []
    if (workflow.files) {
      Object.keys(workflow.files).forEach((key) => {
        const name =
          workflow.files[key].filename ||
          decodeURIComponent(decodeURIComponent(key))
        const mimeType = workflow.files[key].mimeType || null
        const url = `/api/storage/teams/${teamKey}/workflows/${workflowKey}/files/${key}`
        files.push({ name: name, mimeType: mimeType, url: url })
      })
    }
    return {
      pdfReportLocal: workflow.sharedId
        ? paths.reportPdfLocal(teamKey, workflow.sharedId)
        : null,
      pdfReportExisting: workflow.sharedId
        ? paths.reportPdfExisting(teamKey, workflow.sharedId)
        : null,
      pdfReportFile: workflow.sharedId
        ? paths.reportPdfFile(teamKey, workflow.sharedId)
        : null,
      documentPath: documentKey ? paths.document(teamKey, documentKey) : null,
      files: files,
    }
  }

  private async fetchTeamInfo(
    teamKey: string
  ): Promise<{ name: string | null }> {
    const cachedTeamInfo = this.cachedTeamInfoMap.get(teamKey)
    if (cachedTeamInfo) {
      return cachedTeamInfo
    }
    const teamInfoSnapshot = await get(
      ref(getDatabase(), `teams/${teamKey}/info`)
    )
    const name = await this.teamDecryptionService.decrypt(
      teamKey,
      teamInfoSnapshot.val()?.name
    )
    const teamInfo = { name: name }
    this.cachedTeamInfoMap.set(teamKey, teamInfo)
    return teamInfo
  }

  private async fetchUserInfo(
    teamKey: string,
    userKey: string
  ): Promise<{
    firstName: string | null
    lastName: string | null
    userKey: string
  }> {
    const key = teamKey + userKey
    const cachedUserInfo = this.cachedUserInfoMap.get(key)
    if (cachedUserInfo) {
      return cachedUserInfo
    }
    const userInfoSnapshot = await get(
      ref(getDatabase(), `teams/${teamKey}/usersPublic/${userKey}/info`)
    )
    const userInfoVal = userInfoSnapshot.val()
    const firstName = await this.teamDecryptionService.decrypt(
      teamKey,
      userInfoVal?.firstName
    )
    const lastName = await this.teamDecryptionService.decrypt(
      teamKey,
      userInfoVal?.lastName
    )
    const userInfo = {
      firstName: firstName,
      lastName: lastName,
      userKey: userKey,
    }
    this.cachedUserInfoMap.set(teamKey, userInfo)
    return userInfo
  }
}

interface FeedListView {
  feedList: FeedList | null
  typeFilter: string
  changeTypeFilter: (type: string) => void
  loadMoreAtBottom: () => void
}

function useFeedListView({
  teamKey,
}: {
  teamKey: string | null
}): FeedListView {
  const [typeFilter, setTypeFilter] = useState("")
  const [feedListViewModel, setFeedListViewModel] = useState(
    new FeedListViewModel(teamKey, typeFilter)
  )
  const [feedList, setFeedList] = useState<FeedList | null>(null)
  const changeTypeFilter = useCallback(
    (updatedTypeFilter: string) => {
      console.log(`Filter changed to ${updatedTypeFilter}`)
      setTypeFilter(updatedTypeFilter)
      setFeedListViewModel(new FeedListViewModel(teamKey, updatedTypeFilter))
    },
    [teamKey]
  )
  const loadMoreAtBottom = useCallback(() => {
    feedListViewModel.loadMoreItems()
  }, [feedListViewModel])
  useEffect(() => {
    return feedListViewModel.observe({
      onFeedListUpdated(feedList: FeedList) {
        setFeedList(feedList)
      },
    })
  }, [feedListViewModel])
  return {
    feedList,
    typeFilter,
    changeTypeFilter,
    loadMoreAtBottom,
  }
}

export default useFeedListView
