import { Box, Button, IconButton, Link, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import { grey } from "@mui/material/colors"
import { Delete } from "@mui/icons-material"
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined"

import * as shared from "probuild-shared"

import extractFilePath from "model/utils/extractFilePath"

const StyledDiv = styled("div")({
  width: "100%",
})

const StyledBox = styled(Box)(({ theme }) => ({
  gap: theme.spacing(1),
  position: "relative",
  flex: "1 1 0",
  maxWidth: 450,
  margin: "0 auto",
}))

const DeleteBox = styled(Box)(({ theme }) => ({
  display: "flex",
  position: "absolute",
  right: 0,
  alignItems: "center",
  backgroundColor: theme.palette.primary.light,
  borderTopRightRadius: theme.spacing(1),
  borderBottomRightRadius: theme.spacing(1),
  height: "100%",
}))

const PostAttachmentFileView = ({
  viewData,
  onDelete,
}: {
  viewData: shared.com.probuildsoftware.probuild.library.projects.data.view.PostAttachmentFileViewData
  onDelete: ((attachmentKey: string) => void) | null
}) => {
  if (!viewData) return <></>
  if (onDelete) {
    return (
      <StyledDiv>
        <StyledBox>
          <DeleteBox>
            <IconButton
              onClick={() => {
                onDelete && onDelete(viewData.attachmentKey)
              }}
              sx={{ color: "white", alignSelf: "center" }}
            >
              <Delete />
            </IconButton>
          </DeleteBox>
          <Content viewData={viewData} />
        </StyledBox>
      </StyledDiv>
    )
  } else {
    const downloadPath = extractFilePath(viewData.file)
    return (
      <StyledDiv>
        <StyledBox>
          <Button
            component={Link}
            href={downloadPath}
            target="_blank"
            rel="noreferrer"
            sx={{
              textTransform: "none",
              height: "fit-content",
              alignSelf: "center",
              padding: 0,
            }}
          >
            <Content viewData={viewData} />
          </Button>
        </StyledBox>
      </StyledDiv>
    )
  }
}

const ContentRootBox = styled(Box)(({ theme }) => ({
  minWidth: "450px",
  borderStyle: "solid",
  borderWidth: "1px",
  borderColor: grey[500],
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  borderRadius: theme.spacing(1),
  display: "flex",
  alignItems: "center",
}))

const Content = ({
  viewData,
}: {
  viewData: shared.com.probuildsoftware.probuild.library.projects.data.view.PostAttachmentFileViewData
}) => {
  return (
    <ContentRootBox>
      <InsertDriveFileOutlinedIcon fontSize="large" />
      <Box>
        <Typography px={1}>{viewData.name}</Typography>
        <Typography px={1}>{viewData.size}</Typography>
      </Box>
    </ContentRootBox>
  )
}

export default PostAttachmentFileView
