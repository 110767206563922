import { Box, Typography } from "@mui/material"
import UploadIcon from "@mui/icons-material/Upload"

import * as shared from "probuild-shared"

const colors = {
  INFO: {
    background: "rgba(255, 255, 255, 0.7)",
    text: "#001D31",
    icon: "#4a9ad5",
  },
  WARNING: {
    background: "rgba(255,244,229, 0.7)",
    text: "#ef7a25",
    icon: "#ef7a25",
  },
  ERROR: {
    background: "rgba(253, 237, 237, 0.7)",
    text: "#d7413f",
    icon: "#d7413f",
  },
}

const PostListItemUploadStatusView = ({
  uploadStatus,
}: {
  uploadStatus:
    | shared.com.probuildsoftware.probuild.library.projects.data.view.PostUploadStatusViewData
    | null
    | undefined
}) => {
  if (uploadStatus == null) return null
  const color = colors[uploadStatus.severity.name]
  if (color == null) {
    throw Error(`Unhandled severity ${uploadStatus.severity.name}`)
  }
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        bgcolor: "rgba(255, 255, 255, 0.7)",
        pointerEvents: "none",
      }}
    >
      <Box
        sx={{
          maxHeight: 500,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Box
          sx={{
            bgcolor: color.background,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            padding: 4,
            borderRadius: 5,
          }}
        >
          <UploadIcon sx={{ color: color.icon }} fontSize="large" />
          <Typography sx={{ color: color.text }}>
            {uploadStatus.statusText}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default PostListItemUploadStatusView
