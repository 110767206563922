import React from "react"
import { styled } from "@mui/material/styles"
import { MenuItem } from "@mui/material"

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.error.main,
}))

const DeleteMenuItem = React.forwardRef<
  HTMLLIElement,
  {
    text: string | null | undefined
    onClick: () => void
  }
>((props, ref) => {
  return (
    <StyledMenuItem ref={ref} onClick={props.onClick}>
      {props.text}
    </StyledMenuItem>
  )
})

export default DeleteMenuItem
