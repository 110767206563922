import { useCallback, useMemo, useState } from "react"
import { useLocation, useParams } from "react-router-dom"

import * as shared from "probuild-shared"

import TeamPageLayout from "components/views/layouts/TeamPageLayout"
import DocumentReportView from "components/views/documents/DocumentReportView"
import useFirebaseReportData from "model/documents/reports/useFirebaseReportData"
import paths from "model/utils/paths"
import useDocumentViewData from "model/documents/editing/useDocumentViewData"
import EmailComposerDialog from "components/dialogs/EmailComposerDialog"
import DocumentPreviewToolbar from "components/views/documents/preview/DocumentPreviewToolbar"
import OverflowMenu from "components/views/generic/OverflowMenu"
import useSnackbar from "model/snackbar/useSnackbar"
import PaymentListDialog from "components/dialogs/PaymentListDialog"
import PaymentEditDialog from "components/dialogs/PaymentEditDialog"
import useUserKey from "model/utils/useUserKey"
import ConfirmationDialog from "components/dialogs/ConfirmationDialog"
import useNavigateSafe from "model/navigation/useNavigateSafe"
import EditButton from "components/views/generic/EditButton"

const DocumentReportPage = ({ teamKey }: { teamKey: string }) => {
  const { documentKey, documentType } = useParams()
  const location = useLocation()
  const [isPaymentsDialogOpen, setIsPaymentsDialogOpen] =
    useState<boolean>(false)
  const [paymentKeyForOpenDialog, setPaymentKeyForOpenDialog] = useState<
    string | null | undefined
  >(undefined)
  const userKey = useUserKey(teamKey)
  const reportData = useFirebaseReportData({ teamKey, documentKey })
  const showSnackbar = useSnackbar()
  const [navigate, navigateSafeState] = useNavigateSafe()
  const isEmailComposerDialogOpen =
    location.state?.isEmailComposerDialogOpen === true
  const setIsEmailComposerDialogOpen = useCallback(
    (updatedIsOpen: boolean) => {
      navigate(location.pathname, {
        state: {
          isEmailComposerDialogOpen: updatedIsOpen,
        },
      })
    },
    [location, navigate]
  )
  const observer = useMemo(() => {
    return {
      onOpenRowEvent() {},
      onScrollToElementEvent() {},
      onWorkflowCreatedEvent() {},
      onDeleteError(message: string) {
        showSnackbar(message, "error")
      },
      onDeleteSuccess(message: string) {
        showSnackbar(message, "success")
        navigate(-1)
      },
      onSaveSuccess(
        message: string,
        metadata: shared.com.probuildsoftware.probuild.library.documents.data.view.DocumentMetadataViewData,
        exitOnComplete: boolean
      ) {},
      onSaveError(message: string) {},
      onCopyCreated(
        metadata: shared.com.probuildsoftware.probuild.library.documents.data.view.DocumentMetadataViewData
      ) {},
      onConversionToInvoice(
        metadata: shared.com.probuildsoftware.probuild.library.documents.data.view.DocumentMetadataViewData
      ) {},
      onPrintInitiated() {},
      onSharePdf() {},
      onLaunchPayments() {
        setIsPaymentsDialogOpen(true)
      },
    }
  }, [navigate, showSnackbar])
  const [viewData, input] = useDocumentViewData({
    teamKey,
    documentKey,
    documentType,
    observer,
  })
  const onSendClicked = () => {
    setIsEmailComposerDialogOpen(true)
  }
  const onCloseEmailComposerDialog = useCallback(
    () => setIsEmailComposerDialogOpen(false),
    [setIsEmailComposerDialogOpen]
  )
  const onClosePaymentEditDialog = useCallback(() => {
    setPaymentKeyForOpenDialog(undefined)
  }, [])
  return (
    <TeamPageLayout
      title={viewData?.info.name}
      barItems={
        <>
          {viewData?.editButtonText && documentKey && (
            <EditButton
              text={viewData?.editButtonText}
              to={paths.documentEdit(teamKey, documentKey)}
            />
          )}
          {viewData?.menu && (
            <OverflowMenu
              viewData={viewData?.menu}
              onActionSelected={(actionKey) => {
                if (!userKey) return
                input?.onDocumentOverflowMenuAction(actionKey, userKey)
              }}
            />
          )}
        </>
      }
      showLoadingSpinner={!viewData}
    >
      {viewData && (
        <>
          <DocumentReportView reportData={reportData} />
          <DocumentPreviewToolbar
            viewData={viewData}
            onSendClicked={onSendClicked}
          />
          {isEmailComposerDialogOpen && documentKey && (
            <EmailComposerDialog
              teamKey={teamKey}
              documentKey={documentKey}
              isOpen={isEmailComposerDialogOpen}
              onClose={onCloseEmailComposerDialog}
            />
          )}
          {isPaymentsDialogOpen && (
            <PaymentListDialog
              teamKey={teamKey}
              documentKey={documentKey}
              isOpen={isPaymentsDialogOpen}
              onPaymentSelected={(paymentKey: string) => {
                setPaymentKeyForOpenDialog(paymentKey)
              }}
              onCreateClicked={() => {
                setPaymentKeyForOpenDialog(null)
              }}
              onClose={() => {
                setIsPaymentsDialogOpen(false)
              }}
            />
          )}
          <ConfirmationDialog
            open={viewData.deleteConfirmationDialog != null}
            onConfirm={() => input?.deleteDocumentConfirmed()}
            onCancel={() => input?.deleteDocumentCancelled()}
            dialogViewData={viewData.deleteConfirmationDialog}
          />
          {(paymentKeyForOpenDialog || paymentKeyForOpenDialog === null) && (
            <PaymentEditDialog
              teamKey={teamKey}
              documentKey={documentKey}
              paymentKey={paymentKeyForOpenDialog}
              isOpen={true}
              navigateSafeState={navigateSafeState}
              onCloseDialog={onClosePaymentEditDialog}
            />
          )}
        </>
      )}
    </TeamPageLayout>
  )
}

export default DocumentReportPage
