import { browserLocalPersistence, getAuth } from "firebase/auth"

import shared from "probuild-shared"

import { userService } from "contexts/providers/UserProvider"

class LiveFirebaseAuthApi
  implements
    shared.com.probuildsoftware.probuild.library.common.model.auth
      .FirebaseAuthApi
{
  async signInWithCustomToken(
    token: string,
    completion: (success: boolean) => void
  ) {
    const success = await userService.signIn(token)
    completion(success)
  }

  signOut(completion: (success: boolean) => void) {
    getAuth()
      .setPersistence(browserLocalPersistence)
      .then(() => {
        getAuth()
          .signOut()
          .then(() => completion(true))
          .catch((error) => completion(false))
      })
  }

  async fetchIdToken(completion: (idToken: string | null) => void) {
    const idToken = await userService.fetchIdToken()
    return completion(idToken)
  }
}

export default LiveFirebaseAuthApi
