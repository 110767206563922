import { useMemo } from "react"
import { useParams } from "react-router-dom"
import { Button } from "@mui/material"

import * as shared from "probuild-shared"

import TeamPageLayout from "components/views/layouts/TeamPageLayout"
import useUserInvite from "model/users/invite/useUserInvite"
import UserInviteView from "components/views/users/invite/UserInviteView"
import useSnackbar from "model/snackbar/useSnackbar"
import useNavigateSafe from "model/navigation/useNavigateSafe"
import usePromptSafe from "model/navigation/usePromptSafe"

const UserInvitePage = ({ teamKey }: { teamKey: string }) => {
  const { userKey, inviteType } = useParams()
  const showSnackbar = useSnackbar()
  const observer: shared.com.probuildsoftware.probuild.library.users.UserInviteEventObserver =
    useMemo(() => {
      return {
        onShowSuccessSnackbar(message: string) {
          showSnackbar(message, "success")
        },
      }
    }, [showSnackbar])
  const [viewData, userInviteInput] = useUserInvite({
    teamKey,
    userKey,
    observer,
    isFirstInvitation: inviteType === "invite",
  })
  const [navigate, navigateSafeState] = useNavigateSafe()
  usePromptSafe({
    when: viewData?.shouldConfirmDiscard === true,
    message: viewData?.discardConfirmationDialog.message || "",
    navigateSafeState: navigateSafeState,
  })
  return (
    <TeamPageLayout
      title={viewData?.title}
      barItems={
        <>
          <Button
            onClick={() => {
              navigate(-1)
            }}
            variant="contained"
            color="primary"
            sx={{ flexShrink: 0 }}
          >
            {viewData?.doneButtonTitle}
          </Button>
        </>
      }
      showLoadingSpinner={!viewData}
    >
      {viewData && (
        <UserInviteView viewData={viewData} input={userInviteInput} />
      )}
    </TeamPageLayout>
  )
}

export default UserInvitePage
