import { useMemo } from "react"
import { Box } from "@mui/material"

import NavigationBarDialog from "components/dialogs/NavigationBarDialog"
import useClient from "model/clients/edit/useClient"
import useSnackbar from "model/snackbar/useSnackbar"
import SaveButton from "components/views/generic/SaveButton"
import ClientEditView from "components/views/clients/edit/ClientEditView"
import useNavigateSafe from "model/navigation/useNavigateSafe"

const ClientEditDialog = ({
  isOpen,
  teamKey,
  clientKey,
  onSave,
  onCloseDialog,
}: {
  isOpen: boolean
  teamKey: string
  clientKey: string | undefined
  onSave: (clientKey: string) => void
  onCloseDialog: () => void
}) => {
  const showSnackbar = useSnackbar()
  const [, navigateSafeState] = useNavigateSafe()
  const observer = useMemo(() => {
    return {
      onShowSuccessSnackbar(message: string) {
        showSnackbar(message, "success")
      },
      onShowErrorSnackbar(message: string) {
        showSnackbar(message, "error")
      },
      onNavigateBack() {
        onCloseDialog()
      },
      onSave(clientKey: string) {
        onSave(clientKey)
      },
    }
  }, [showSnackbar, onSave, onCloseDialog])
  const onSaveClicked = () => input?.save()
  const onDialogClosed = () => onCloseDialog()
  const [viewData, input] = useClient({
    teamKey,
    clientKey,
    observer,
  })
  return (
    <NavigationBarDialog
      isOpen={isOpen}
      onClose={onDialogClosed}
      title={viewData?.title}
      barItems={
        <SaveButton
          text={viewData?.actions.saveButtonText}
          isEnabled={viewData?.status.isModified}
          onClick={onSaveClicked}
        />
      }
    >
      <Box px={3}>
        <ClientEditView
          viewData={viewData}
          input={input}
          navigateSafeState={navigateSafeState}
        />
      </Box>
    </NavigationBarDialog>
  )
}

export default ClientEditDialog
