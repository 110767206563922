import shared from "probuild-shared"

class PlatformTimeZoneApi
  implements
    shared.com.probuildsoftware.probuild.library.common.model.timezone.api
      .PlatformTimeZoneApi
{
  observeTimeZone(
    observer: shared.com.probuildsoftware.probuild.library.common.model.timezone.api.PlatformTimeZoneApiObserver
  ): () => void {
    observer.onPlatformTimeZoneChanged(
      Intl.DateTimeFormat().resolvedOptions().timeZone
    )
    return () => {}
  }
}

export default PlatformTimeZoneApi
