import { Box, ListItem } from "@mui/material"
import ContactsIcon from "@mui/icons-material/Contacts"
import { styled } from "@mui/material/styles"

import NavigationBarDialog from "components/dialogs/NavigationBarDialog"
import SearchBar from "components/views/generic/SearchBar"
import ListDialogContent from "components/dialogs/ListDialogContent"
import useDocumentAllList from "model/documents/list/useDocumentAllList"
import TextWithMobileFallbackView from "components/views/generic/TextWithMobileFallbackView"

const BoldTextWithFallbackView = styled(TextWithMobileFallbackView)({
  fontWeight: "bold",
})

const NameTotalDiv = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
})

const DocumentListDialog = ({
  teamKey,
  isOpen,
  onDocumentSelected,
}: {
  teamKey: string
  isOpen: boolean
  onDocumentSelected: (documentKey: string | null) => void
}) => {
  const [viewData, input] = useDocumentAllList({ teamKey, isSelecting: true })
  const documentListItems = viewData?.items?.asJsReadonlyArrayView()
  return (
    <NavigationBarDialog
      isOpen={isOpen}
      onClose={() => {
        onDocumentSelected(null)
        input?.changeSearchQuery("")
      }}
      title={viewData?.title}
      barItems={
        <>
          <SearchBar
            placeholder={viewData?.status.searchPlaceholder}
            onChange={(text: string) => {
              input?.changeSearchQuery(text)
            }}
            autoFocus={true}
          />
        </>
      }
    >
      <ListDialogContent
        emptyViewData={viewData?.status.empty}
        emptyIcon={ContactsIcon}
      >
        {documentListItems?.map((listItem) => {
          return (
            <ListItem
              key={listItem.viewKey}
              divider={true}
              button
              onClick={() => {
                onDocumentSelected(listItem.viewKey)
              }}
            >
              <Box px={1} style={{ width: "100%" }}>
                <NameTotalDiv>
                  <BoldTextWithFallbackView data={listItem.documentName} />
                  <TextWithMobileFallbackView data={listItem.total} />
                </NameTotalDiv>
                <TextWithMobileFallbackView data={listItem.emailStatus} />
                <TextWithMobileFallbackView data={listItem.client} />
                <TextWithMobileFallbackView data={listItem.project} />
              </Box>
            </ListItem>
          )
        })}
      </ListDialogContent>
    </NavigationBarDialog>
  )
}

export default DocumentListDialog
