import { useState } from "react"
import SmsIcon from "@mui/icons-material/Sms"
import { Navigate } from "react-router-dom"

import * as shared from "probuild-shared"

import paths from "model/utils/paths"
import ActionList from "components/views/generic/ActionList"
import ActionListItem from "components/views/generic/ActionListItem"

const UserProfileActionList = ({
  teamKey,
  userKey,
  viewData,
}: {
  teamKey: string
  userKey: string
  viewData: shared.com.probuildsoftware.probuild.library.users.data.view.UserProfilePreviewViewData | null
}) => {
  const [navigateToResend, setNavigateToResend] = useState<boolean>(false)
  if (navigateToResend) {
    return <Navigate to={paths.userReinvite(teamKey, userKey)} />
  }
  return (
    <ActionList>
      {viewData?.actionButtons.resendInvite && (
        <ActionListItem
          Icon={SmsIcon}
          text={viewData.actionButtons.resendInvite}
          onClick={() => {
            setNavigateToResend(true)
          }}
        />
      )}
    </ActionList>
  )
}

export default UserProfileActionList
