import shared from "probuild-shared"
import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber"

class LivePhoneNumbersApi
  implements
    shared.com.probuildsoftware.probuild.library.common.model.phone.api
      .PhoneNumbersApi
{
  format(phoneNumber: string): string {
    try {
      const phoneNumberUtil = PhoneNumberUtil.getInstance()
      const parsedPhoneNumber = phoneNumberUtil.parse(phoneNumber)
      return phoneNumberUtil.format(
        parsedPhoneNumber,
        PhoneNumberFormat.INTERNATIONAL
      )
    } catch (e) {
      return phoneNumber
    }
  }
  formatE164(phoneNumber: string, region: string): string | null {
    try {
      const phoneNumberUtil = PhoneNumberUtil.getInstance()
      const parsedPhoneNumber = phoneNumberUtil.parse(phoneNumber, region)
      return phoneNumberUtil.format(parsedPhoneNumber, PhoneNumberFormat.E164)
    } catch (e) {
      return null
    }
  }
  canSendSms(): boolean {
    return false
  }
}

export default LivePhoneNumbersApi
