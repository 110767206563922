import { Button } from "@mui/material"

const SaveButton = ({
  text,
  isEnabled,
  onClick,
}: {
  text: string | null | undefined
  isEnabled: boolean | null | undefined
  onClick: () => void
}) => {
  if (!text) return null
  return (
    <Button
      disabled={!isEnabled}
      color="primary"
      variant="contained"
      onClick={onClick}
    >
      {text}
    </Button>
  )
}

export default SaveButton
