import React, { useEffect, useRef, useState } from "react"
import { InputAdornment, TextField, Typography } from "@mui/material"
import { SxProps, Theme } from "@mui/system"
import { AsYouTypeFormatter } from "google-libphonenumber"

import * as shared from "probuild-shared"

const Types =
  shared.com.probuildsoftware.probuild.library.documents.data.types.Types

const getKeyboardType = (keyboardType: string): string | undefined => {
  switch (keyboardType) {
    case Types.KEYBOARD_TYPE_PHONE:
      return "tel"
    case Types.KEYBOARD_TYPE_EMAIL:
      return "email"
    case Types.KEYBOARD_TYPE_NUMBER_DECIMAL:
    case Types.KEYBOARD_TYPE_NUMBER_SIGNED:
      return "number"
  }
  return undefined
}

const ViewDataTextField = ({
  viewData,
  onChange,
  className,
  placeholderOnly,
  variant,
  sx,
}: {
  viewData: shared.com.probuildsoftware.probuild.library.common.data.view.TextFieldViewData
  onChange: (text: string) => void
  className?: string
  placeholderOnly?: boolean
  variant?: "standard" | "filled" | "outlined" | undefined
  sx?: SxProps<Theme>
}) => {
  const [backingTextToPreserveCursor, setBackingTextToPreserveCursor] =
    useState<string>("")
  const textFieldRef = useRef<HTMLInputElement>()
  const formatter = (text: string) => {
    if (
      viewData.keyboard.keyboardType === Types.KEYBOARD_TYPE_PHONE &&
      viewData.formatter?.phoneCountryCode
    ) {
      const formatter = new AsYouTypeFormatter(
        viewData.formatter.phoneCountryCode
      )
      let fullFormat = ""
      for (const character of text) {
        if ((character >= "0" && character <= "9") || character === "+") {
          fullFormat = formatter.inputDigit(character)
        }
      }
      return fullFormat
    } else {
      return text
    }
  }
  const onTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formattedValue = formatter(event.target.value)
    setBackingTextToPreserveCursor(formattedValue)
    onChange(formattedValue)
  }
  const unitSymbol = (
    <Typography color={"textSecondary"}>{viewData.unit?.symbol}</Typography>
  )
  const textFieldType = getKeyboardType(viewData.keyboard.keyboardType)
  useEffect(() => {
    const onScroll = (e: Event) => {
      e.preventDefault()
    }
    const currentRef = textFieldRef.current
    if (textFieldType === "number") {
      currentRef?.addEventListener("wheel", onScroll, {
        passive: false,
      })
    }
    return () => {
      if (textFieldType === "number") {
        currentRef?.removeEventListener("wheel", onScroll)
      }
    }
  }, [textFieldType])

  return (
    <TextField
      className={className}
      sx={sx}
      autoFocus={viewData.autoFocus}
      inputRef={textFieldRef}
      type={textFieldType}
      placeholder={placeholderOnly ? viewData.label : undefined}
      label={placeholderOnly ? undefined : viewData.label}
      value={
        textFieldRef.current === document.activeElement
          ? backingTextToPreserveCursor
          : viewData.text
      }
      error={viewData.error != null}
      helperText={viewData.error ?? viewData.helperText}
      onChange={onTextChange}
      variant={variant || "standard"}
      multiline={viewData.multiline}
      disabled={viewData.disabled}
      InputProps={{
        startAdornment: viewData.unit?.prefix === true && (
          <InputAdornment position="start">{unitSymbol}</InputAdornment>
        ),
        endAdornment: viewData?.unit?.prefix === false && (
          <InputAdornment position="end">{unitSymbol}</InputAdornment>
        ),
      }}
    />
  )
}

export default ViewDataTextField
