import { useMemo } from "react"

import * as shared from "probuild-shared"

import TeamPageLayout from "components/views/layouts/TeamPageLayout"
import useUserAdd from "model/users/add/useUserAdd"
import useSnackbar from "model/snackbar/useSnackbar"
import UserAddView from "components/views/users/add/UserAddView"
import paths from "model/utils/paths"
import ConfirmationDialog from "components/dialogs/ConfirmationDialog"
import LoadingModal from "components/views/generic/LoadingModal"
import usePromptSafe from "model/navigation/usePromptSafe"
import useNavigateSafe from "model/navigation/useNavigateSafe"

const UserAddPage = ({ teamKey }: { teamKey: string }) => {
  const showSnackbar = useSnackbar()
  const [navigate, navigateSafeState] = useNavigateSafe()
  const observer: shared.com.probuildsoftware.probuild.library.users.UserAddEventObserver =
    useMemo(() => {
      return {
        onShowSuccessSnackbar(message: string) {
          showSnackbar(message, "success")
        },
        onShowErrorSnackbar(message: string) {
          showSnackbar(message, "error")
        },
        onNavigateBack() {
          navigate(-1)
        },
        onNavigateToSendInvite(userKey: string) {
          navigate(paths.userInvite(teamKey, userKey), { replace: true })
        },
      }
    }, [navigate, showSnackbar, teamKey])
  const [viewData, input] = useUserAdd({
    teamKey,
    observer,
  })
  usePromptSafe({
    when: viewData?.shouldConfirmDiscard === true,
    message: viewData?.discardConfirmationDialog.message,
    navigateSafeState: navigateSafeState,
  })
  return (
    <TeamPageLayout title={viewData?.title} showLoadingSpinner={!viewData}>
      {viewData && (
        <>
          <UserAddView viewData={viewData} input={input} />
          <ConfirmationDialog
            open={viewData.dialog ? true : false}
            dialogViewData={viewData.dialog}
            onConfirm={() => {}}
            onCancel={() => {
              input?.dialogCancel()
            }}
          />
          <LoadingModal open={viewData.isLoadingIndicatorVisible === true} />
        </>
      )}
    </TeamPageLayout>
  )
}

export default UserAddPage
