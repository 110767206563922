import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from "@mui/material"

import * as shared from "probuild-shared"

import UserListItemView from "./UserListItemView"

const UserListSectionView = ({
  teamKey,
  section,
  onUserClicked,
}: {
  teamKey: string
  section: shared.com.probuildsoftware.probuild.library.users.data.view.UserListSectionViewData
  onUserClicked?: (userKey: string) => void
}) => {
  const users = section.users?.asJsReadonlyArrayView()
  return (
    <div>
      <Typography sx={{ ml: 2, mb: 2 }}>{section.title}</Typography>
      <TableContainer sx={{ mb: 3 }} component={Paper}>
        <Table>
          <TableBody>
            {users.map((userItem) => (
              <UserListItemView
                teamKey={teamKey}
                item={userItem}
                onUserClicked={onUserClicked}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default UserListSectionView
