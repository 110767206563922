import { useMemo } from "react"

import * as shared from "probuild-shared"

import useDependencyProvider from "model/utils/useDependencyProvider"
import usePresenter from "model/presenter/usePresenter"

function useAuthAccountSetup(): [
  shared.com.probuildsoftware.probuild.library.auth.data.view.AuthAccountSetupViewData | null,
  shared.com.probuildsoftware.probuild.library.auth.AuthAccountSetupInput | null
] {
  const dependencyProvider = useDependencyProvider()
  const presenter = useMemo(() => {
    if (!dependencyProvider) {
      return
    }
    return new shared.com.probuildsoftware.probuild.library.auth.AuthAccountSetupPresenter(
      dependencyProvider
    )
  }, [dependencyProvider])
  return usePresenter(presenter)
}

export default useAuthAccountSetup
