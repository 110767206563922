export class ReportDataStorage {
  private jsonObserver: ((newData: string | null) => void) | null = null
  private jsonData: string | null = null

  getJson(): string | null {
    return this.jsonData
  }

  updateJson(newData: string | null) {
    this.jsonData = newData
    this.notifyChange()
  }

  observe(observer: (newData: string | null) => void): () => void {
    this.jsonObserver = observer
    this.notifyChange()
    return () => {
      this.jsonObserver = null
    }
  }

  private notifyChange() {
    if (this.jsonObserver) {
      this.jsonObserver(this.jsonData)
    }
  }
}

const globalWindow = window as any
const reportDataStorage = new ReportDataStorage()

globalWindow.reportDataStorage = reportDataStorage

export default reportDataStorage
