import FeedListItem from "model/feed/item/FeedListItem"
import WorkflowItemView from "components/views/feed/items/WorkflowItemView"
import GenericItemView from "components/views/feed/items/GenericItemView"

const FeedItemView = ({
  item,
  showTeamName,
}: {
  item: FeedListItem
  showTeamName: boolean
}) => {
  switch (item.type) {
    case "workflowCreated":
      return <WorkflowItemView item={item} showTeamName={showTeamName} />
    default:
      return <GenericItemView item={item} showTeamName={showTeamName} />
  }
}

export default FeedItemView
