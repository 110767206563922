import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material"

import * as shared from "probuild-shared"

const VisibilitySelectionDialog = ({
  viewData,
  onRoleSelected,
}: {
  viewData: shared.com.probuildsoftware.probuild.library.common.data.view.ListSelectionViewData
  onRoleSelected: (roleKey: string) => void
}) => {
  const items = viewData.items.asJsReadonlyArrayView()
  if (!items) return null
  return (
    <>
      <Typography fontWeight={"bold"} mt={2} mx={3}>
        {viewData.description}
      </Typography>
      <Box mx={3} mb={2}>
        <FormGroup>
          {items.map((item) => (
            <FormControlLabel
              key={item.itemKey}
              control={
                <Checkbox
                  onChange={() => {
                    onRoleSelected(item.itemKey)
                  }}
                  defaultChecked={item.selected}
                />
              }
              label={item.itemName}
              disabled={item.alwaysSelected}
            />
          ))}
        </FormGroup>
      </Box>
    </>
  )
}

export default VisibilitySelectionDialog
