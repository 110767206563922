import { logEvent, getAnalytics, setUserProperties } from "firebase/analytics"

import shared from "probuild-shared"

class LiveAnalyticsApi
  implements
    shared.com.probuildsoftware.probuild.library.common.model.analytics
      .AnalyticsApi
{
  logEvent = (eventName: string) => {
    logEvent(getAnalytics(), eventName)
  }

  setUserProperty(name: string, value: string | null) {
    setUserProperties(getAnalytics(), { [name]: value })
  }
}

export default LiveAnalyticsApi
