import { useCallback, useMemo } from "react"
import { useParams } from "react-router-dom"
import { Button, CircularProgress, Container, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import * as shared from "probuild-shared"
import usePhoneNumberChangeVerify from "model/users/phonenumber/usePhoneNumberChangeVerify"
import useSnackbar from "model/snackbar/useSnackbar"
import LoadingModal from "components/views/generic/LoadingModal"
import ConfirmationDialog from "components/dialogs/ConfirmationDialog"

const RootDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  height: "100%",
})

const HeaderContainerDiv = styled("div")(({ theme }) => ({
  display: "flex",
  position: "fixed",
  justifyContent: "left",
  width: "100%",
  backgroundColor: theme.palette.primary.main,
  [theme.breakpoints.down("sm")]: {
    position: "static",
    justifyContent: "center",
  },
}))

const HeaderInnerDiv = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  margin: theme.spacing(2),
  paddingLeft: theme.spacing(5),
  [theme.breakpoints.down("sm")]: {
    paddingLeft: 0,
  },
}))

const LogoImage = styled("img")({
  height: "35px",
  objectFit: "contain",
})

const LogoTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  margin: theme.spacing(0.5),
}))

const BodyContainer = styled(Container)(({ theme }) => ({
  textAlign: "center",
  margin: "auto",
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}))

const StyledInfoTypography = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(4),
  fontSize: "large",
}))

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(4),
  alignSelf: "center",
  width: "50%",
}))

const HeaderBar = () => {
  const HeaderContent = () => (
    <HeaderInnerDiv>
      <LogoImage src="/logoTransparent.png" alt="Probuild" />
      <LogoTypography variant="h5">Probuild</LogoTypography>
    </HeaderInnerDiv>
  )
  return (
    <HeaderContainerDiv>
      <HeaderContent />
    </HeaderContainerDiv>
  )
}

const PhoneNumberChangeVerifyPage = ({ teamKey }: { teamKey: string }) => {
  const { requestUuid } = useParams()
  const showSnackbar = useSnackbar()
  const observer: shared.com.probuildsoftware.probuild.library.users.PhoneNumberChangeVerifyEventObserver =
    useMemo(() => {
      return {
        onShowSuccessSnackbar(message: string) {
          showSnackbar(message, "success")
        },
        onShowErrorSnackbar(message: string) {
          showSnackbar(message, "error")
        },
        onNavigateBack() {},
      }
    }, [showSnackbar])
  const [viewData, input] = usePhoneNumberChangeVerify({
    teamKey,
    requestUuid: requestUuid,
    observer,
  })
  const onConfirm = useCallback(async () => {
    input?.verifyConfirmed()
  }, [input])
  const onDialogCancel = useCallback(async () => {
    input?.infoDialogDismiss()
  }, [input])
  return (
    <RootDiv>
      <HeaderBar />
      <BodyContainer maxWidth="md">
        {viewData == null || !viewData.isLoaded ? (
          <CircularProgress />
        ) : (
          <>
            <Typography variant="h5">{viewData.title}</Typography>
            <StyledInfoTypography>
              {viewData.changeVerifyMessage}
            </StyledInfoTypography>
            {viewData.verifyButtonText && (
              <StyledButton
                size="large"
                variant="contained"
                color="primary"
                onClick={onConfirm}
                sx={{ mt: 4 }}
              >
                {viewData.verifyButtonText}
              </StyledButton>
            )}
            <StyledInfoTypography>
              {viewData.supportHelpMessage}
            </StyledInfoTypography>
          </>
        )}
      </BodyContainer>
      <ConfirmationDialog
        open={viewData?.infoDialog !== null}
        dialogViewData={viewData?.infoDialog}
        onConfirm={onDialogCancel}
        onCancel={onDialogCancel}
      />
      <LoadingModal open={viewData?.isLoadingIndicatorVisible === true} />
    </RootDiv>
  )
}

export default PhoneNumberChangeVerifyPage
