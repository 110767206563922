import * as forge from "node-forge"

import BufferDecryptionOperation from "./BufferDecryptionOperation"

class CryptoService {
  private readonly ENCODED_KEY_SEPARATOR = "_"

  public decryptWithAESSecret(
    aesSecret: string,
    cipherTextAndIV: string
  ): string {
    const cipherTextAndIVParts = cipherTextAndIV.split(
      this.ENCODED_KEY_SEPARATOR
    )
    const iv = forge.util.createBuffer(
      forge.util.decode64(cipherTextAndIVParts[0])
    )
    const message = forge.util.createBuffer(
      forge.util.decode64(cipherTextAndIVParts[1])
    )
    const aesSecretBytes = forge.util.createBuffer(
      forge.util.decode64(aesSecret)
    )
    const decipher = forge.cipher.createDecipher("AES-CBC", aesSecretBytes)
    decipher.start({ iv })
    decipher.update(message)
    decipher.finish()
    return decipher.output.toString()
  }

  public async decryptArrayBufferWithAESSecret(
    aesSecret: string,
    arrayBuffer: ArrayBuffer,
    base64EncodedIv: string
  ): Promise<Uint8Array> {
    const bufferDecryptionOperatior = new BufferDecryptionOperation(
      base64EncodedIv,
      arrayBuffer,
      aesSecret
    )
    return bufferDecryptionOperatior.start()
  }
}

export default CryptoService
