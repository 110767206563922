import shared from "probuild-shared"

class LiveDynamicLinksApi
  implements
    shared.com.probuildsoftware.probuild.library.common.model.dynamiclinks.api
      .DynamicLinksApi
{
  async createShortLink(
    link: string,
    domainUriPrefix: string,
    bundleId: string,
    appStoreId: string,
    packageName: string,
    completion: (shortLink: string | null) => void
  ) {
    try {
      const response = await fetch(
        `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${process.env.REACT_APP_FIREBASE_CONFIG_API_KEY}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            dynamicLinkInfo: {
              domainUriPrefix: domainUriPrefix,
              link: link,
              androidInfo: {
                androidPackageName: packageName,
              },
              iosInfo: {
                iosBundleId: bundleId,
                iosAppStoreId: appStoreId,
              },
            },
          }),
        }
      )
      const responseJson = await response.json()
      completion(responseJson.shortLink)
    } catch {
      completion(null)
    }
  }
}

export default LiveDynamicLinksApi
