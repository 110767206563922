import * as shared from "probuild-shared"

function extractFilePath(
  file:
    | shared.com.probuildsoftware.probuild.library.common.data.view.FileViewData
    | undefined
    | null,
  download?: boolean
) {
  if (file?.info.devicePath) {
    return file?.info.devicePath
  } else {
    return `/api/storage/${file?.info.path}?download=${download === true}`
  }
}

export default extractFilePath
