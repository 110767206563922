import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material"

import * as shared from "probuild-shared"

const DropdownView = ({
  viewData,
  onChange,
}: {
  viewData: shared.com.probuildsoftware.probuild.library.common.data.view.DropdownViewData
  onChange: (key: string) => void
}) => {
  const dropdownOptions = viewData.options?.asJsReadonlyArrayView()
  return (
    <FormControl error={viewData.error != null} sx={{ width: "100%" }}>
      <InputLabel variant="standard">{viewData.label}</InputLabel>
      <Select
        value={viewData.selectedOption?.key || ""}
        variant="standard"
        label={viewData.label}
        onChange={(event) => {
          const key = event.target.value
          if (!key) return
          onChange(key)
        }}
      >
        {dropdownOptions?.map((option) => (
          <MenuItem key={option.key} value={option.key}>
            <ListItemText primary={option.name} secondary={option.details} />
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{viewData.error}</FormHelperText>
    </FormControl>
  )
}

export default DropdownView
