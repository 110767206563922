import * as shared from "probuild-shared"

import { useState, useEffect } from "react"

function useSharedReportData({
  teamKey,
  sharedId,
}: {
  teamKey: string
  sharedId: string | undefined
}): shared.com.probuildsoftware.probuild.library.documents.data.view.DocumentReportViewData | null {
  const [reportData, setReportData] =
    useState<shared.com.probuildsoftware.probuild.library.documents.data.view.DocumentReportViewData | null>(
      null
    )
  if (!sharedId)
    throw new Error("Can only be used with a route defining :sharedId")
  useEffect(() => {
    if (!sharedId) return
    let active = true
    const reportDataTransformer =
      new shared.com.probuildsoftware.probuild.library.documents.ReportDataTransformer()
    const pathUrl = `/api/reports/${teamKey}/shared/${sharedId}`
    console.log("Fetching " + pathUrl)
    fetch(pathUrl)
      .then((response) => response.text())
      .then((json) => {
        console.log(`Received for ${pathUrl} active: ${active} json: ${json}`)
        if (active) {
          const reportData = reportDataTransformer.fromJSON(json)
          const updatedReportData = reportDataTransformer.transformPaths(
            reportData,
            (path) =>
              `/api/storage/${path}&resize=600x600&jpegQuality=80&rotate=true&fit=inside`
          )
          setReportData(updatedReportData)
        }
      })
    return () => {
      active = false
    }
  }, [teamKey, sharedId])
  return reportData
}

export default useSharedReportData
