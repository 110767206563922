import { useMemo } from "react"

import * as shared from "probuild-shared"

import useDependencyProvider from "model/utils/useDependencyProvider"
import useAppUser from "model/utils/useAppUser"
import usePresenter from "model/presenter/usePresenter"

const useItemList = ({
  teamKey,
  isSelecting,
}: {
  teamKey: string
  isSelecting: boolean
}): [
  shared.com.probuildsoftware.probuild.library.items.data.view.ItemListViewData | null,
  shared.com.probuildsoftware.probuild.library.items.ItemListInput | null
] => {
  const appUser = useAppUser({ teamKey })
  const dependencyProvider = useDependencyProvider()
  const presenter = useMemo(() => {
    if (dependencyProvider && appUser) {
      console.log(
        `Creating item list presenter teamKey = ${appUser.teamKey} userKey = ${appUser.userKey}`
      )
      return new shared.com.probuildsoftware.probuild.library.items.ItemListPresenter(
        dependencyProvider,
        appUser,
        {
          isSelecting,
        }
      )
    }
  }, [appUser, isSelecting, dependencyProvider])
  return usePresenter(presenter)
}

export default useItemList
