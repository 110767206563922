import { Loader } from "@googlemaps/js-api-loader"

let loadPromise: Promise<typeof google> | null = null

const ensureGoogleMapsIsLoaded = () => {
  if (!loadPromise) {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_FIREBASE_CONFIG_API_KEY as string,
      version: "weekly",
      libraries: ["places"],
    })
    loadPromise = loader.load()
  }
  return loadPromise
}

let placesService: google.maps.places.PlacesService | null = null
let autocompleteService: google.maps.places.AutocompleteService | null = null

const fetchPlaceDetails = (
  placeId: string,
  callback: (result: google.maps.places.PlaceResult) => void
) => {
  const request = {
    placeId: placeId,
    fields: ["formatted_address", "geometry.location"],
  }
  ensureGoogleMapsIsLoaded().then(() => {
    if (placesService == null) {
      placesService = new google.maps.places.PlacesService(
        document.getElementById("google-map") as HTMLDivElement
      )
    }
    placesService.getDetails(request, callback)
  })
}

const fetchPlacePredictions = (
  input: string,
  callback: (result: google.maps.places.AutocompletePrediction[]) => void
) => {
  const request = { input: input }
  ensureGoogleMapsIsLoaded().then(() => {
    if (autocompleteService == null) {
      autocompleteService = new google.maps.places.AutocompleteService()
    }
    autocompleteService.getPlacePredictions(request, (result) => {
      callback(result || [])
    })
  })
}

export { fetchPlacePredictions, fetchPlaceDetails }
