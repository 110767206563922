import { styled } from "@mui/material/styles"
import { SvgIconComponent } from "@mui/icons-material"

import * as shared from "probuild-shared"

import EmptyView from "components/views/EmptyView"

const StyledDiv = styled("div")({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
})

const ListDialogContent = ({
  emptyViewData,
  emptyIcon,
  children,
}: {
  emptyViewData:
    | shared.com.probuildsoftware.probuild.library.common.data.view.EmptyViewData
    | undefined
    | null
  emptyIcon: SvgIconComponent | null
  children: React.ReactNode
}) => {
  if (emptyViewData) {
    return (
      <StyledDiv>
        <EmptyView icon={emptyIcon} viewData={emptyViewData} />
      </StyledDiv>
    )
  } else {
    return <div>{children}</div>
  }
}

export default ListDialogContent
