import { styled } from "@mui/material/styles"
import { Button, Typography } from "@mui/material"

import useAccountsList from "model/app/useAccountsList"
import paths from "model/utils/paths"

const RootDiv = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: theme.palette.error.main,
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(2),
  gap: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    justifyContent: "space-between",
    padding: theme.spacing(1),
  },
}))

const StyledButton = styled(Button)(({ theme }) => ({
  position: "absolute",
  left: 0,
  marginLeft: theme.spacing(1),
  minWidth: "fit-content",
  [theme.breakpoints.down("sm")]: {
    position: "relative",
  },
}))

const ImpersonationBar = () => {
  const [accounts] = useAccountsList()
  const warningText = accounts?.warningText
  if (!warningText) {
    return null
  }
  return (
    <RootDiv>
      <StyledButton variant="contrast" href={paths.supportAgentFeed()}>
        Agent Feed
      </StyledButton>
      <Typography sx={{ ml: 1, color: "primary.contrastText" }}>
        {warningText}
      </Typography>
    </RootDiv>
  )
}

export default ImpersonationBar
