import { Button } from "@mui/material"

import * as shared from "probuild-shared"

import LabeledCard from "components/views/generic/LabeledCard"
import ViewDataTextField from "components/views/generic/ViewDataTextField"
import DropdownView from "components/views/generic/DropdownView"

const UserAddView = ({
  viewData,
  input,
}: {
  viewData: shared.com.probuildsoftware.probuild.library.users.data.view.UserAddViewData
  input: shared.com.probuildsoftware.probuild.library.users.UserAddInput | null
}) => {
  return (
    <>
      <LabeledCard text={""}>
        <ViewDataTextField
          viewData={viewData.firstName}
          onChange={(newFirstName) => {
            input?.changeFirstName(newFirstName)
          }}
        />
        <ViewDataTextField
          viewData={viewData.lastName}
          onChange={(newLastName) => {
            input?.changeLastName(newLastName)
          }}
        />
        <ViewDataTextField
          viewData={viewData.phoneNumber}
          onChange={(newPhoneNumber) => {
            input?.changePhoneNumber(newPhoneNumber)
          }}
        />
        <DropdownView
          viewData={viewData.roleDropdown}
          onChange={(roleKey) => {
            input?.changeRole(roleKey)
          }}
        />
      </LabeledCard>
      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          input?.addUser()
        }}
      >
        {viewData.addUserButtonText}
      </Button>
    </>
  )
}

export default UserAddView
