import { Chip } from "@mui/material"
import { Timelapse } from "@mui/icons-material"

const ClockedInUsersChip = ({ title }: { title: string }) => {
  return (
    <Chip
      label={title}
      size="small"
      color="success"
      icon={
        <Timelapse
          style={{
            color: "inherit",
          }}
        />
      }
      sx={{
        cursor: "inherit",
      }}
    />
  )
}

export default ClockedInUsersChip
