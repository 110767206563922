import { useMemo } from "react"
import useSnackbar from "model/snackbar/useSnackbar"
import usePromptSafe from "model/navigation/usePromptSafe"
import { NavigateSafeState } from "model/navigation/useNavigateSafe"
import usePhoneNumberChangeAdd from "model/users/phonenumber/usePhoneNumberChangeAdd"
import NavigationBarDialog from "components/dialogs/NavigationBarDialog"
import SaveButton from "components/views/generic/SaveButton"
import PhoneNumberChangeAddView from "components/views/users/phonenumber/PhoneNumberChangeAddView"

const PhoneNumberChangeAddDialog = ({
  teamKey,
  userKey,
  isOpen,
  onCloseDialog,
  navigateSafeState,
}: {
  teamKey: string
  userKey: string
  isOpen: boolean
  onCloseDialog: () => void
  navigateSafeState: NavigateSafeState
}) => {
  const showSnackbar = useSnackbar()
  const observer = useMemo(() => {
    return {
      onShowSuccessSnackbar(message: string) {
        showSnackbar(message, "success")
      },
      onShowErrorSnackbar(message: string) {
        showSnackbar(message, "error")
      },
      onNavigateBack() {
        onCloseDialog()
      },
    }
  }, [showSnackbar, onCloseDialog])
  const onRequestChangeClicked = () => input?.requestPhoneNumberChange()
  const onDialogClosed = () => onCloseDialog()
  const [viewData, input] = usePhoneNumberChangeAdd({
    teamKey,
    userKey,
    observer,
  })
  usePromptSafe({
    when: viewData?.shouldConfirmDiscard === true,
    message: viewData?.discardConfirmationDialog.message || "",
    navigateSafeState: navigateSafeState,
  })
  return (
    <NavigationBarDialog
      isOpen={isOpen}
      onClose={onDialogClosed}
      title={viewData?.title}
      barItems={
        <SaveButton
          text={viewData?.createButtonText}
          isEnabled={viewData != null}
          onClick={onRequestChangeClicked}
        />
      }
    >
      <PhoneNumberChangeAddView
        viewData={viewData}
        input={input}
        navigateSafeState={navigateSafeState}
      />
    </NavigationBarDialog>
  )
}

export default PhoneNumberChangeAddDialog
