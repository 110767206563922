import * as shared from "probuild-shared"

import ImageGalleryView from "components/views/generic/ImageGalleryView"
import { useCallback, useMemo } from "react"

const PostListItemGalleryView = ({
  viewData,
  initialAttachmentKey,
  onPhotoViewed,
  onClose,
}: {
  viewData: shared.com.probuildsoftware.probuild.library.projects.data.view.PostListItemViewData | null
  initialAttachmentKey: string | null
  onPhotoViewed: (postKey: string, attachmentKey: string) => void
  onClose: () => void
}) => {
  const photos = viewData?.attachments?.photos?.asJsReadonlyArrayView()
  const files = photos?.flatMap((photoFile) =>
    photoFile.file ? [photoFile.file] : []
  )
  const initialFilePath = useMemo(
    () =>
      photos?.find((photo) => photo.attachmentKey === initialAttachmentKey)
        ?.file?.info.path || null,
    [photos, initialAttachmentKey]
  )
  const onPhotoViewedByPath = useCallback(
    (path: string) => {
      const postKey = viewData?.postKey
      const attachmentKey = photos?.find(
        (photo) => photo.file?.info.path === path
      )?.attachmentKey
      if (postKey && attachmentKey) {
        onPhotoViewed(postKey, attachmentKey)
      }
    },
    [viewData?.postKey, photos, onPhotoViewed]
  )
  return (
    <ImageGalleryView
      files={files || null}
      initialFilePath={initialFilePath || null}
      onPhotoViewed={onPhotoViewedByPath}
      onClose={onClose}
    />
  )
}

export default PostListItemGalleryView
