import { TextField } from "@mui/material"
import React from "react"

const DateSelector = ({
  isoDate,
  label,
  onDateChange,
  disable,
}: {
  isoDate: string | null | undefined
  label?: string
  onDateChange: (newDate: string) => void
  disable?: boolean
}) => {
  const formattedDate = formatDateForMaterialUI(isoDate)
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = new Date(Date.parse(event.target.value + "T12:00:00"))
    onDateChange(newDate.toISOString())
  }
  return (
    <TextField
      variant="standard"
      label={label}
      InputLabelProps={{ shrink: true }}
      defaultValue={formattedDate}
      type="date"
      onChange={onChange}
      disabled={disable}
    />
  )
}

const formatDateForMaterialUI = (
  isoFormat: string | null | undefined
): string | null => {
  if (!isoFormat) {
    return null
  }
  const date = new Date(Date.parse(isoFormat))
  const year = date.getFullYear()
  const month = ("0" + (date.getMonth() + 1)).slice(-2)
  const day = ("0" + date.getDate()).slice(-2)
  return year + "-" + month + "-" + day
}

export default DateSelector
