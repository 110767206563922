import shared from "probuild-shared"

class LiveKeyValueStorageApi
  implements
    shared.com.probuildsoftware.probuild.library.common.model.persistence.api
      .KeyValueStorageApi
{
  private localStorageKeyPrefix = "PROBUILD_KEY_VALUE_STORAGE_"
  private observers = new Map<
    string,
    Set<shared.com.probuildsoftware.probuild.library.common.model.persistence.api.KeyValueStorageApiObserver>
  >()
  private isEventListenerAttached = false

  observe(
    key: string,
    observer: shared.com.probuildsoftware.probuild.library.common.model.persistence.api.KeyValueStorageApiObserver
  ): () => void {
    this.ensureKeyObservers(key)
    this.observers.get(key)?.add(observer)
    const value = localStorage.getItem(this.localStorageKey(key))
    observer.onValueChange(value)
    this.updateListener()
    return () => {
      this.observers.get(key)?.delete(observer)
    }
  }

  set(key: string, value: string, completion: () => void) {
    localStorage.setItem(this.localStorageKey(key), value)
    completion()
  }

  remove(key: string, completion: () => void) {
    localStorage.removeItem(this.localStorageKey(key))
    completion()
  }

  private localStorageKey(key: string): string {
    return `${this.localStorageKeyPrefix}${key}`
  }

  private keyFromLocalStorageKey(localStorageKey: string): string | null {
    if (localStorageKey.startsWith(this.localStorageKeyPrefix)) {
      return localStorageKey.split(this.localStorageKeyPrefix)[1]
    }
    return null
  }

  private ensureKeyObservers(key: string) {
    if (!this.observers.has(key)) {
      this.observers.set(
        key,
        new Set<shared.com.probuildsoftware.probuild.library.common.model.persistence.api.KeyValueStorageApiObserver>()
      )
    }
  }

  private hasObservers(): boolean {
    this.observers.forEach((observerSet) => {
      if (observerSet.size !== 0) {
        return true
      }
    })
    return false
  }

  private updateListener() {
    if (this.hasObservers() && !this.isEventListenerAttached) {
      window.addEventListener("storage", this.storageChangedObserver)
      this.isEventListenerAttached = true
    } else {
      window.removeEventListener("storage", this.storageChangedObserver)
      this.isEventListenerAttached = false
    }
  }

  private storageChangedObserver = (event: StorageEvent) => {
    if (event.key == null) return
    const key = this.keyFromLocalStorageKey(event.key)
    if (key == null) return
    this.observers
      .get(key)
      ?.forEach((observer) => observer.onValueChange(event.newValue))
  }
}

export default LiveKeyValueStorageApi
