import { styled } from "@mui/material/styles"
import { useState } from "react"

import * as shared from "probuild-shared"

import FileProgressView from "components/views/generic/FileProgressView"
import extractImagePath from "model/utils/extractImagePath"

const ProgressDiv = styled("div")({
  position: "absolute",
  left: "50%",
  top: "50%",
  marginLeft: "-30px",
  marginTop: "-30px",
  width: "60px",
  height: "60px",
})

const InnerContainerDiv = styled("div")({
  width: "100%",
  height: "100%",
  position: "relative",
  backgroundColor: "lightgray",
  borderWidth: "1px",
  maxWidth: "100%",
})

const Image = styled("img")({
  width: "100%",
  height: "100%",
  objectPosition: "center",
})

const ImageView = ({
  className,
  alt,
  file,
  fadeIn,
  cover,
  width,
  height,
}: {
  className?: string
  alt: string
  file:
    | shared.com.probuildsoftware.probuild.library.common.data.view.FileViewData
    | undefined
    | null
  fadeIn?: boolean
  cover?: boolean
  width?: number
  height?: number
}) => {
  const [opacity, setOpacity] = useState(fadeIn === true ? 0 : 1)
  const canSpecifyImageFit = width && height ? true : undefined
  const imagePath = extractImagePath({
    file,
    width,
    height,
    fit: canSpecifyImageFit && (cover ? "outside" : "inside"),
  })
  return (
    <div className={className}>
      <InnerContainerDiv>
        {imagePath && (
          <Image
            key={imagePath}
            sx={{
              objectFit: cover ? "cover" : "contain",
              transition: "opacity 300ms ease-in-out",
              opacity: opacity,
            }}
            alt={alt}
            src={imagePath}
            onLoad={() => setOpacity(1)}
          />
        )}
        <ProgressDiv>
          <FileProgressView progress={file?.progress} />
        </ProgressDiv>
      </InnerContainerDiv>
    </div>
  )
}

export default ImageView
