import React, { useState } from "react"

import SnackbarContext, { SnackbarData } from "contexts/SnackbarContext"

const SnackbarProvider = ({ children }: { children: React.ReactNode }) => {
  const [snackbar, setSnackbar] = useState<SnackbarData | null>(null)
  return (
    <SnackbarContext.Provider value={{ snackbar, setSnackbar }}>
      {children}
    </SnackbarContext.Provider>
  )
}

export default SnackbarProvider
