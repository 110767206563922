import { Button } from "@mui/material"
import { styled } from "@mui/material/styles"
import { Typography } from "@mui/material"
import { ArrowForward } from "@mui/icons-material"

const ProbuildLogoContainerDiv = styled("div")(({ theme }) => ({
  maxHeight: "30vh",
  maxWidth: "30vh",
  width: "60vw",
  height: "60vw",
  backgroundColor: theme.palette.primary.main,
  borderRadius: "10%",
  margin: theme.spacing(4),
}))

const SuggestionContainerDiv = styled("div")({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
})

const IgnoreSuggestionContainerDiv = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  marginTop: theme.spacing(8),
}))

const ProbuildLogoImage = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "contain",
  padding: "5%",
})

const DownloadAnchor = styled("a")(({ theme }) => ({
  width: "250px",
  maxWidth: "60vw",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: theme.spacing(8),
}))

const RootDiv = styled("div")(({ theme }) => ({
  margin: theme.spacing(4),
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
}))

type Platform = "iOS" | "Android"

const InstallSuggestionView = ({
  type,
  ignoreSuggestion,
}: {
  type: Platform
  ignoreSuggestion: () => void
}) => {
  return (
    <RootDiv>
      <SuggestionContainerDiv>
        <ProbuildLogoContainerDiv>
          <ProbuildLogoImage src="/logoTransparent.png" alt="Probuild" />
        </ProbuildLogoContainerDiv>
        <Typography sx={{ mr: 1, textAlign: "center", fontSize: "large" }}>
          For the best experience, download the Probuild app on the{" "}
          {storeName(type)}, or use your computer to access this website.
        </Typography>
        <DownloadAnchor href={storeUrl(type)}>
          <img
            style={{ width: "100%" }}
            src={badgeImagePath(type)}
            alt={`Download on the ${storeName(type)}`}
          />
        </DownloadAnchor>
      </SuggestionContainerDiv>
      <IgnoreSuggestionContainerDiv>
        <Button
          sx={{ textTransform: "none" }}
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => ignoreSuggestion()}
        >
          <Typography sx={{ mr: 1 }}>Sign in anyway</Typography>
          <ArrowForward fontSize={"large"} />
        </Button>
      </IgnoreSuggestionContainerDiv>
    </RootDiv>
  )
}

const storeName = (type: Platform): string => {
  switch (type) {
    case "iOS":
      return "App Store"
    case "Android":
      return "Play Store"
  }
}

const storeUrl = (type: Platform): string | undefined => {
  switch (type) {
    case "iOS":
      return process.env.REACT_APP_PROBUILD_DOWNLOAD_URL_IOS
    case "Android":
      return process.env.REACT_APP_PROBUILD_DOWNLOAD_URL_ANDROID
  }
}

const badgeImagePath = (type: Platform): string => {
  switch (type) {
    case "iOS":
      return "/appStoreDownloadBadge.svg"
    case "Android":
      return "/playStoreDownloadBadge.svg"
  }
}

export default InstallSuggestionView
