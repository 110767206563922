import { styled } from "@mui/material/styles"
import {
  Button,
  Popover,
  Divider,
  Typography,
  List,
  ListItemButton,
} from "@mui/material"
import { Link } from "react-router-dom"

import * as shared from "probuild-shared"

import AccountView from "components/views/app/AccountView"
import AccountAvatars from "components/views/app/AccountAvatars"
import paths from "model/utils/paths"
import LinkToExternalSite from "components/views/generic/LinkToExternalSite"

const CurrentAccountAvatarsDiv = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(3),
}))

const SignOutButtonContainerDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
})

const CurrentAccountButtonContentDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: theme.spacing(1),
  textTransform: "none",
}))

const PopoverContentDiv = styled("div")({
  minWidth: "350px",
})

const LegalLinksContainerDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(1),
  paddingBottom: theme.spacing(2),
}))

const StyledLinkToExternalSite = styled(LinkToExternalSite)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: "small",
  fontWeight: 500,
  padding: theme.spacing(1),
  borderRadius: theme.spacing(1),
  [`&:hover`]: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
  },
}))

const AccountPopover = ({
  anchorElement,
  accountsViewData,
  selectTeam,
  signOut,
  closePopover,
}: {
  anchorElement: HTMLElement | null
  accountsViewData: shared.com.probuildsoftware.probuild.library.app.data.view.AccountsListViewData | null
  selectTeam: (teamKey: string) => void
  signOut: () => void
  closePopover: () => void
}) => {
  const accounts = accountsViewData?.otherAccounts?.asJsReadonlyArrayView()
  return (
    <Popover
      open={anchorElement != null}
      anchorEl={anchorElement}
      onClose={closePopover}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <PopoverContentDiv>
        <Button sx={{ width: "100%" }} onClick={closePopover}>
          <CurrentAccountButtonContentDiv>
            <CurrentAccountAvatarsDiv>
              <AccountAvatars
                accountViewData={
                  (accountsViewData && accountsViewData?.currentAccount) || null
                }
                light={false}
                size={150}
              />
            </CurrentAccountAvatarsDiv>
            <Typography color="InfoText" variant="h6">
              {accountsViewData?.currentAccount?.teamName}
            </Typography>
            <Typography color="InfoText">
              {accountsViewData?.currentAccount?.userName}
            </Typography>
            <Typography color="InfoText" variant="caption">
              {accountsViewData?.e164FormattedPhoneNumber}
            </Typography>
          </CurrentAccountButtonContentDiv>
        </Button>
        <Divider />
        <List sx={{ p: 0 }}>
          {accounts?.map((account) => {
            return (
              <ChangeAccountButton
                key={account.teamKey}
                account={account}
                onClick={() => selectTeam(account.teamKey)}
              />
            )
          })}
        </List>
        <Divider />
        <SignOutButtonContainerDiv>
          <Button variant="outlined" sx={{ my: 2 }} onClick={signOut}>
            {accountsViewData?.signOutButtonText}
          </Button>
          {accountsViewData && (
            <LegalLinksContainerDiv>
              <StyledLinkToExternalSite
                path={accountsViewData.termsOfUseUrl}
                title={accountsViewData.termsOfUseLabel}
              />
              {"•"}
              <StyledLinkToExternalSite
                path={accountsViewData.privacyPolicyUrl}
                title={accountsViewData.privacyPolicyLabel}
              />
            </LegalLinksContainerDiv>
          )}
        </SignOutButtonContainerDiv>
      </PopoverContentDiv>
    </Popover>
  )
}

const ChangeAccountButton = ({
  account,
  onClick,
}: {
  account:
    | shared.com.probuildsoftware.probuild.library.app.data.view.AccountViewData
    | null
    | undefined
  onClick: () => void
}) => {
  if (!account) return null
  return (
    <ListItemButton
      component={Link}
      to={paths.team(account.teamKey)}
      onClick={onClick}
    >
      <AccountView account={account} light={false} />
    </ListItemButton>
  )
}

export default AccountPopover
