import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  MenuItem,
  Select,
  styled,
  Typography,
} from "@mui/material"
import { Skeleton } from "@mui/material"
import { Contacts, Timelapse, Map, Group } from "@mui/icons-material"
import { SvgIconComponent } from "@mui/icons-material"

import * as shared from "probuild-shared"

import GoogleMap from "components/views/generic/GoogleMap"
import colorToCss from "model/utils/colorToCss"
import ClockedInUsersChip from "components/views/generic/ClockedInUsersChip"

const NameStatusBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(1),
}))

const ProjectPreviewHeaderView = ({
  viewData,
  onClientClicked,
  onTimesheetsClicked,
  onEditInfoClicked,
  onMemberCountClicked,
  onStatusChanged,
}: {
  viewData: shared.com.probuildsoftware.probuild.library.projects.data.view.ProjectPreviewViewData
  onClientClicked: (clientKey: string) => void
  onTimesheetsClicked: () => void
  onEditInfoClicked: () => void
  onMemberCountClicked: () => void
  onStatusChanged: (statusKey: string) => void
}) => {
  const header = viewData.header
  return (
    <Card sx={{ mb: 2, mt: 0, pt: 0 }}>
      {header.projectLocation?.address && (
        <GoogleMap
          address={header.projectLocation.address}
          lat={header.projectLocation.lat}
          lng={header.projectLocation.lng}
          height={200}
        />
      )}
      <CardContent
        sx={{
          px: 0,
          "&:last-child": {
            pb: 0,
          },
        }}
      >
        <NameStatusBox>
          <Typography variant="h5" sx={{ px: 2, whiteSpace: "pre-line" }}>
            {header.projectName}
          </Typography>
          {header.editInfoButton && (
            <Button
              onClick={onEditInfoClicked}
              color="primary"
              variant="outlined"
              size="large"
              sx={{ marginLeft: "auto" }}
            >
              {header.editInfoButton}
            </Button>
          )}
        </NameStatusBox>
        <Typography
          variant="body1"
          color="gray"
          sx={{ px: 2, whiteSpace: "pre-line" }}
        >
          {header.projectDescription}
        </Typography>
        <Box
          sx={{
            m: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "end",
          }}
        >
          <Status
            status={header.projectStatus}
            color={header.projectStatusColor}
            onChange={onStatusChanged}
          />
          {viewData.header.projectClockedInMemberCount && (
            <ClockedInUsersChip
              title={viewData.header.projectClockedInMemberCount}
            />
          )}
        </Box>
        <Divider />
        <Section
          IconComponent={Group}
          isVisible={header.memberCount != null}
          text={header.memberCount}
          onClicked={onMemberCountClicked}
        />
        <Section
          IconComponent={Contacts}
          isVisible={header.client != null}
          text={header.client?.clientName}
          onClicked={() =>
            header.client?.clientKey &&
            onClientClicked(header.client?.clientKey)
          }
        />
        <Section
          IconComponent={Map}
          isVisible={header.projectLocation?.address != null}
          text={header.projectLocation?.address}
          onClicked={onEditInfoClicked}
        />
        <Section
          IconComponent={Timelapse}
          isVisible={header.timesheets.isVisible === true}
          text={header.timesheets?.totalTime}
          onClicked={() => onTimesheetsClicked()}
        >
          {viewData.header.timesheets.clockedInMemberCount && (
            <Box sx={{ marginLeft: "auto" }}>
              <ClockedInUsersChip
                title={viewData.header.timesheets.clockedInMemberCount}
              />
            </Box>
          )}
        </Section>
      </CardContent>
    </Card>
  )
}

const Status = ({
  status,
  color,
  onChange,
}: {
  status:
    | shared.com.probuildsoftware.probuild.library.common.data.view.DropdownViewData
    | null
    | undefined
  color:
    | shared.com.probuildsoftware.probuild.library.common.data.view.ColorViewData
    | null
    | undefined
  onChange: (statusKey: string) => void
}) => {
  if (status == null) return null
  const colorCss = colorToCss(color)
  const options = status.options.asJsReadonlyArrayView()
  if (!status.disabled) {
    return (
      <Select
        value={status?.selectedOption?.key}
        sx={{
          color: colorCss,
          ".MuiSelect-icon": {
            color: colorCss,
          },
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: colorCss,
          },
          "&.Mui-focused": {
            borderColor: colorCss,
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: colorCss,
            },
          },
        }}
        onChange={(event) => {
          if (event.target.value) {
            onChange(event.target.value)
          }
        }}
      >
        {options.map((option) => {
          return <MenuItem value={option.key}>{option.name}</MenuItem>
        })}
      </Select>
    )
  } else {
    return (
      <Box
        sx={{
          p: 2,
          color: colorCss,
          borderColor: colorCss,
          borderWidth: 1,
          borderStyle: "solid",
          borderRadius: 1,
          width: "max-content",
        }}
      >
        {status?.selectedOption?.name}
      </Box>
    )
  }
}

const Section = ({
  IconComponent,
  isVisible,
  text,
  onClicked,
  children,
}: {
  IconComponent: SvgIconComponent
  isVisible: boolean
  text?: string | null
  onClicked: () => void
  children?: React.ReactNode
}) => {
  return (
    <>
      {isVisible && (
        <>
          <Button
            onClick={onClicked}
            sx={{ px: 2, py: 1, width: "100%", textTransform: "none" }}
          >
            <Box
              sx={{
                width: "100%",
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              <IconComponent />
              <Typography sx={{ pl: 2, my: 1 }}>
                {text ? text : <Skeleton width={100} />}
              </Typography>
              {children}
            </Box>
          </Button>
        </>
      )}
    </>
  )
}

export default ProjectPreviewHeaderView
