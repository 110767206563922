import { styled } from "@mui/material/styles"
import { Box, Modal, Paper, Typography } from "@mui/material"

import LoadingView from "components/views/LoadingView"

const StyledModal = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
})

const StyledBox = styled(Box)({
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
})

const LoadingModal = ({
  open,
  value,
}: {
  open: boolean
  value?: number | null
}) => {
  const percentage = Math.round((value || 0) * 100)
  return (
    <StyledModal open={open}>
      <Paper>
        <LoadingView percentage={percentage} />
        {value !== null && value !== undefined && (
          <StyledBox>
            <Typography
              variant="caption"
              component="div"
              color="text.secondary"
            >{`${percentage}%`}</Typography>
          </StyledBox>
        )}
      </Paper>
    </StyledModal>
  )
}

export default LoadingModal
