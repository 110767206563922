import { useMemo } from "react"

import * as shared from "probuild-shared"

import useDependencyProvider from "model/utils/useDependencyProvider"
import useAppUser from "model/utils/useAppUser"
import usePresenter from "model/presenter/usePresenter"

const useClientList = ({
  teamKey,
  isSelecting,
  observer,
}: {
  teamKey: string
  isSelecting: boolean
  observer?: shared.com.probuildsoftware.probuild.library.clients.ClientListEventObserver
}): [
  shared.com.probuildsoftware.probuild.library.clients.data.view.ClientListViewData | null,
  shared.com.probuildsoftware.probuild.library.clients.ClientListInput | null
] => {
  const dependencyProvider = useDependencyProvider()
  const appUser = useAppUser({ teamKey })
  const presenter = useMemo(() => {
    if (dependencyProvider && appUser) {
      console.log(
        `Creating client list presenter ${appUser.teamKey} ${appUser.userKey}`
      )
      return new shared.com.probuildsoftware.probuild.library.clients.ClientListPresenter(
        dependencyProvider,
        appUser,
        {
          isSelecting,
          sortByLastName: false,
        }
      )
    }
  }, [appUser, isSelecting, dependencyProvider])
  return usePresenter(presenter, observer)
}

export default useClientList
