import { useCallback, useState } from "react"
import { flushSync } from "react-dom"
import {
  NavigateFunction,
  NavigateOptions,
  To,
  useNavigate,
} from "react-router-dom"

interface NavigateSafeState {
  isNavigating: boolean
}

const useNavigateSafe = (): [NavigateFunction, NavigateSafeState] => {
  const navigate = useNavigate()
  const [navigateState, setNavigateState] = useState<NavigateSafeState>({
    isNavigating: false,
  })
  const navigateSafe = useCallback<NavigateFunction>(
    (toOrDelta: To | number, options?: NavigateOptions) => {
      flushSync(() => {
        setNavigateState({ isNavigating: true })
      })
      if (typeof toOrDelta === "number") {
        navigate(toOrDelta)
      } else {
        navigate(toOrDelta, options)
      }
    },
    [navigate]
  )

  return [navigateSafe, navigateState]
}

export default useNavigateSafe

export type { NavigateSafeState }
