import { useEffect, useMemo, useState } from "react"

import * as shared from "probuild-shared"

import useDependencyProvider from "model/utils/useDependencyProvider"
import useAppUser from "model/utils/useAppUser"

function useProjectPreview({
  teamKey,
  projectKey,
  observer,
}: {
  teamKey: string
  projectKey: string | undefined
  observer: shared.com.probuildsoftware.probuild.library.projects.ProjectPreviewEventObserver
}): [
  shared.com.probuildsoftware.probuild.library.projects.data.view.ProjectPreviewViewData | null,
  shared.com.probuildsoftware.probuild.library.projects.ProjectPreviewInput | null
] {
  const appUser = useAppUser({ teamKey })
  const dependencyProvider = useDependencyProvider()
  const presenter = useMemo(() => {
    if (dependencyProvider && appUser && projectKey) {
      console.log(
        `Creating project preview presenter teamKey = ${appUser.teamKey} projectKey = ${projectKey}`
      )
      return new shared.com.probuildsoftware.probuild.library.projects.ProjectPreviewPresenter(
        dependencyProvider,
        null,
        appUser,
        {
          projectKey,
          isShareFilesSupported: false,
          isTakePhotosSupported: false,
          isSelectPhotosSupported: true,
          isCreateEstimateAndInvoiceSupported: false,
          isSendEmailSupported: false,
        }
      )
    }
  }, [dependencyProvider, appUser, projectKey])
  const [viewData, setViewData] =
    useState<shared.com.probuildsoftware.probuild.library.projects.data.view.ProjectPreviewViewData | null>(
      null
    )
  const [input, setInput] =
    useState<shared.com.probuildsoftware.probuild.library.projects.ProjectPreviewInput | null>(
      null
    )
  useEffect(() => {
    if (presenter) {
      setInput(presenter.input)
      const cleanUpViewData = presenter.observeViewData((viewData) => {
        setViewData(viewData)
      })
      const cleanUpEvents = presenter.observeEvents(observer)
      return () => {
        cleanUpViewData()
        cleanUpEvents()
      }
    }
  }, [presenter, observer])
  return [viewData, input]
}

export default useProjectPreview
