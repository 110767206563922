import React from "react"

import * as shared from "probuild-shared"
import createInputFiles from "model/utils/createInputFiles"

const ImageInput = ({
  onFileChanged,
  hidden,
  inputRef,
  multiple,
}: {
  onFileChanged: (
    file: shared.com.probuildsoftware.probuild.library.common.data.files.InputFile
  ) => void
  hidden?: boolean
  inputRef?: React.RefObject<HTMLInputElement>
  multiple?: boolean
}) => {
  return (
    <input
      style={{ display: hidden === true ? "none" : undefined }}
      type="file"
      ref={inputRef}
      accept={"image/*"}
      multiple={multiple}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return
        const inputFiles = createInputFiles(event.target.files)
        inputFiles.forEach((inputFile) => onFileChanged(inputFile))
        const currentInput = inputRef?.current
        if (currentInput) {
          currentInput.value = ""
        }
      }}
    />
  )
}

export default ImageInput
