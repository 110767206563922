import { styled } from "@mui/material/styles"
import { Link } from "react-router-dom"
import { Button, Typography } from "@mui/material"

import paths from "model/utils/paths"

const CenteringDiv = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100vh",
})

const CenteredDiv = styled("div")({
  textAlign: "center",
})

const NotFoundPage = ({ teamKey }: { teamKey?: string }) => {
  return (
    <CenteringDiv>
      <CenteredDiv>
        <Typography variant="h4">Page Not Found</Typography>
        <Button
          sx={{ mt: 3 }}
          color="primary"
          component={Link}
          to={teamKey ? paths.team(teamKey) : "/"}
        >
          <Typography>Return to home</Typography>
        </Button>
      </CenteredDiv>
    </CenteringDiv>
  )
}

export default NotFoundPage
