import { saveKey, loadKey } from "model/crypto/CryptoPersistence"

import shared from "probuild-shared"

class LiveRsaCryptoApi
  implements
    shared.com.probuildsoftware.probuild.library.common.model.crypto
      .RsaCryptoApi
{
  async generateAndSaveKeyPair(
    publicKeyIdentifier: string,
    privateKeyIdentifier: string,
    completion: () => void
  ) {
    const algorithm: RsaHashedKeyGenParams = {
      name: "RSASSA-PKCS1-v1_5",
      hash: "SHA-512",
      modulusLength: 2048,
      publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
    }
    const extractable = false
    const keyUsages: KeyUsage[] = ["sign"]
    const keyPair = (await crypto.subtle.generateKey(
      algorithm,
      extractable,
      keyUsages
    )) as CryptoKeyPair
    await saveKey(publicKeyIdentifier, keyPair.publicKey)
    await saveKey(privateKeyIdentifier, keyPair.privateKey)
    completion()
  }

  async signWithSha512(
    privateKeyIdentifier: string,
    plainText: string,
    completion: (sha512Signature: string) => void
  ) {
    const privateKey = await loadKey(privateKeyIdentifier)
    const encoder = new TextEncoder()
    const signatureArraybuffer = await crypto.subtle.sign(
      "RSASSA-PKCS1-v1_5",
      privateKey,
      encoder.encode(plainText)
    )
    const base64EncodedSignature = this.base64Encode(signatureArraybuffer)
    completion(base64EncodedSignature)
  }

  async exportSubjectPublicKeyInfo(
    publicKeyIdentifier: string,
    completion: (subjectPublicKeyInfo: string) => void
  ) {
    const publicKey = await loadKey(publicKeyIdentifier)
    const publicSecretArrayBuffer = await crypto.subtle.exportKey(
      "spki",
      publicKey
    )
    const base64EncodedSpkiPublicSecret = this.base64Encode(
      publicSecretArrayBuffer
    )
    completion(base64EncodedSpkiPublicSecret)
  }

  encrypt(encodedPublicSecret: string, plainText: string): string {
    throw new Error("LiveRsaCryptoApi.encrypt not implemented")
  }

  private base64Encode(buffer: ArrayBuffer): string {
    let binary = ""
    let bytes = new Uint8Array(buffer)
    for (let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i])
    }
    return btoa(binary)
  }
}

export default LiveRsaCryptoApi
