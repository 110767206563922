import { useContext } from "react"

import UserContext from "contexts/UserContext"

function useUserKey(teamKey: string): string | null {
  const userState = useContext(UserContext)
  return userState.signedInUser?.getUserKeyForTeam(teamKey) || null
}

export default useUserKey
