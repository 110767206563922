import { styled } from "@mui/material/styles"
import { Box, Chip, Divider, ListItemButton } from "@mui/material"

import * as shared from "probuild-shared"

import paths from "model/utils/paths"
import TextWithFallbackView from "components/views/generic/TextWithFallbackView"
import { Link } from "react-router-dom"

const StyledBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(1),
}))

const InnerColumn = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
}))

const BoldedTextWithFallbackView = styled(TextWithFallbackView)({
  fontWeight: "bold",
})

const DefaultBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "right",
}))

const TextWithFallbackViewLimitedToFourLines = styled(TextWithFallbackView)({
  display: "-webkit-box",
  "-webkit-line-clamp": "4",
  "-webkit-box-orient": "vertical",
  overflow: "hidden",
})

const ContractListItemView = ({
  teamKey,
  item,
}: {
  teamKey: string
  item: shared.com.probuildsoftware.probuild.library.contracts.data.view.ContractListItemViewData
}) => {
  const contractPath = paths.contractEdit(teamKey, item.contractKey)

  return (
    <>
      <ListItemButton component={Link} key={item.contractKey} to={contractPath}>
        <StyledBox>
          <InnerColumn>
            <BoldedTextWithFallbackView data={item.name} />
            <TextWithFallbackViewLimitedToFourLines data={item.text} />
            {item.defaultType && (
              <DefaultBox>
                <Chip label={item.defaultType} />
              </DefaultBox>
            )}
          </InnerColumn>
        </StyledBox>
      </ListItemButton>
      <Divider />
    </>
  )
}

export default ContractListItemView
