import React, { MouseEvent } from "react"
import {
  Box,
  Button,
  Card,
  CardHeader,
  IconButton,
  Popover,
  styled,
  Tooltip,
  Typography,
} from "@mui/material"
import {
  ArrowDropDown,
  AttachFile,
  Description,
  PhotoAlbum,
} from "@mui/icons-material"

import * as shared from "probuild-shared"

import UserAvatar from "components/views/users/UserAvatar"
import ViewDataTextField from "components/views/generic/ViewDataTextField"
import PostAttachmentView from "components/views/projects/feed/attachments/PostAttachmentView"
import VisibilitySelectionDialog from "components/dialogs/VisibilitySelectionDialog"

const ScrollContainer = styled("div")({
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  flexShrink: 1,
  height: "100%",
})

const VisibilityButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  paddingLeft: theme.spacing(1),
  paddingRight: 0,
  paddingTop: theme.spacing(0.2),
  paddingBottom: theme.spacing(0.2),
  border: "solid 1px",
  borderColor: "primary",
  borderRadius: theme.spacing(0.5),
}))

const AttachmentsBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "center",
}))

const RootBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "top",
  height: "100%",
})

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  margin: theme.spacing(1),
  color: theme.palette.primary.main,
}))

const PostEditView = ({
  teamKey,
  viewData,
  input,
  onAddPhoto,
  onAddDocument,
  onAddFile,
}: {
  teamKey: string
  viewData: shared.com.probuildsoftware.probuild.library.projects.data.view.PostEditViewData | null
  input: shared.com.probuildsoftware.probuild.library.projects.PostEditInput | null
  onAddPhoto: () => void
  onAddDocument: () => void
  onAddFile: () => void
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  if (!viewData || !input) return <div></div>
  const onVisibilityClick = (event: MouseEvent<any>) => {
    input.initiateVisibilitySelection()
    setAnchorEl(event.currentTarget)
  }
  return (
    <RootBox>
      <CardHeader
        sx={{ px: 2, flexShrink: 0 }}
        avatar={
          <UserAvatar size={45} viewData={viewData.header.createdBy.avatar} />
        }
        title={<Typography>{viewData.header.createdBy.name}</Typography>}
        subheader={
          <VisibilityButton onClick={onVisibilityClick}>
            <Typography variant="caption">
              {viewData.header.visibility}
            </Typography>
            <ArrowDropDown fontSize="small" sx={{ p: 0, m: 0 }} />
          </VisibilityButton>
        }
      />
      <ScrollContainer sx={{ px: 2, flexShrink: 1, pb: 1 }}>
        <ViewDataTextField
          sx={{ width: "100%", pb: 1 }}
          viewData={viewData.message}
          placeholderOnly={true}
          onChange={(newMessage) => input.changeMessage(newMessage)}
        />
        <AttachmentsBox>
          <PostAttachmentView
            teamKey={teamKey}
            viewData={viewData.attachments}
            onPhotoClicked={() => {}}
            onPhotoViewed={(attachmentKey) =>
              input.ensureAttachmentDownloaded(attachmentKey)
            }
            onDelete={(attachmentKey) => input.removeAttachment(attachmentKey)}
            isAllPhotoAttachmentsVisible={true}
          />
        </AttachmentsBox>
      </ScrollContainer>
      <Card
        variant="outlined"
        sx={{ display: "inline-flex", alignItems: "center" }}
      >
        <Box sx={{ my: 2, ml: 1 }}>
          {viewData.actions.selectPhotos && (
            <Tooltip
              placement="top"
              arrow
              title={viewData.actions.selectPhotos.actionLabel}
            >
              <StyledIconButton onClick={onAddPhoto}>
                <PhotoAlbum />
              </StyledIconButton>
            </Tooltip>
          )}
          {viewData.actions.addEstimateOrInvoice && (
            <Tooltip
              placement="top"
              arrow
              title={viewData.actions.addEstimateOrInvoice.actionLabel}
            >
              <StyledIconButton onClick={onAddDocument}>
                <Description />
              </StyledIconButton>
            </Tooltip>
          )}
          {viewData.actions.selectFiles && (
            <Tooltip
              placement="top"
              arrow
              title={viewData.actions.selectFiles.actionLabel}
            >
              <StyledIconButton onClick={onAddFile}>
                <AttachFile />
              </StyledIconButton>
            </Tooltip>
          )}
        </Box>
      </Card>
      <Popover
        open={anchorEl != null && viewData.visibilitySelectionDialog != null}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {viewData.visibilitySelectionDialog && (
          <VisibilitySelectionDialog
            viewData={viewData.visibilitySelectionDialog}
            onRoleSelected={(roleKey) => input.toggleVisibilityRole(roleKey)}
          />
        )}
      </Popover>
    </RootBox>
  )
}

export default PostEditView
