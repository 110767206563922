import { Box, ListItemButton, Paper, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"

import NavigationBarDialog from "components/dialogs/NavigationBarDialog"
import ListDialogContent from "components/dialogs/ListDialogContent"
import useTimesheetUserList from "model/timesheets/useTimesheetsUserList"
import UserAvatar from "components/views/users/UserAvatar"

const TextContainerDiv = styled("div")(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
}))

const TableCellContent = styled("div")({
  display: "flex",
  alignItems: "center",
  width: "100%",
})

const TotalTimePaper = styled(Paper)(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  display: "flex",
  justifyContent: "space-between",
}))

const TimesheetUserListDialog = ({
  teamKey,
  projectKey,
  isOpen,
  onUserClicked,
}: {
  teamKey: string
  projectKey: string | undefined
  isOpen: boolean
  onUserClicked: (userKey: string | null) => void
}) => {
  const [viewData] = useTimesheetUserList({ teamKey, projectKey })
  const userListItems = viewData?.items?.asJsReadonlyArrayView()
  return (
    <NavigationBarDialog
      isOpen={isOpen}
      onClose={() => {
        onUserClicked(null)
      }}
      title={viewData?.title}
      barItems={<></>}
    >
      <ListDialogContent emptyViewData={null} emptyIcon={null}>
        <TotalTimePaper>
          <Typography>{viewData?.totalTimeLabel}</Typography>
          <Typography>{viewData?.totalTime}</Typography>
        </TotalTimePaper>
        {userListItems?.map((listItem) => {
          return (
            <ListItemButton
              key={listItem.userKey}
              sx={{
                width: "100%",
                px: 3,
                opacity: listItem.isDeleted ? 0.75 : 1,
              }}
              divider={true}
              onClick={() => {
                onUserClicked(listItem.userKey)
              }}
            >
              <TableCellContent>
                <UserAvatar size={50} viewData={listItem.avatar || null} />
                <TextContainerDiv>
                  <Box fontStyle={listItem.isMe ? "italic" : "normal"}>
                    {listItem.name}
                  </Box>
                  <Typography color="textSecondary">
                    {listItem.totalTime}
                  </Typography>
                </TextContainerDiv>
              </TableCellContent>
            </ListItemButton>
          )
        })}
      </ListDialogContent>
    </NavigationBarDialog>
  )
}

export default TimesheetUserListDialog
