import { styled } from "@mui/material/styles"
import FormControl from "@mui/material/FormControl"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import FormHelperText from "@mui/material/FormHelperText"
import { ReactNode } from "react"

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(1),
  textAlign: "start",
  minWidth: 200,
}))

const RootDiv = styled("div")(({ theme }) => ({
  textAlign: "end",
  paddingBottom: theme.spacing(2),
}))

const FeedFilter = ({
  filter,
  filterChanged,
}: {
  filter: string
  filterChanged: (filter: string) => void
}) => {
  const onFilterChange = (
    event: SelectChangeEvent<string>,
    child: ReactNode
  ) => {
    if (typeof event.target.value === "string") {
      filterChanged(event.target.value)
    }
  }
  return (
    <RootDiv>
      <StyledFormControl>
        <Select
          value={filter}
          onChange={onFilterChange}
          displayEmpty
          sx={{ mt: 2 }}
        >
          <MenuItem value="">
            <em>No Filter</em>
          </MenuItem>
          <MenuItem value="messageCreated">Chat Messages</MenuItem>
          <MenuItem value="clientCreated">Clients</MenuItem>
          <MenuItem value="documentCreated">Documents</MenuItem>
          <MenuItem value="itemCreated">Items</MenuItem>
          <MenuItem value="projectCreated">Projects</MenuItem>
          <MenuItem value="postCreated">Project Posts</MenuItem>
          <MenuItem value="commentCreated">Project Post Comments</MenuItem>
          <MenuItem value="subscriptionCreated">Subscriptions</MenuItem>
          <MenuItem value="teamCreated">Teams</MenuItem>
          <MenuItem value="timesheetCreated">Timesheets</MenuItem>
          <MenuItem value="userPublicCreated">Users</MenuItem>
          <MenuItem value="workflowCreated">Workflows</MenuItem>
        </Select>
        <FormHelperText>Filter items by type</FormHelperText>
      </StyledFormControl>
    </RootDiv>
  )
}

export default FeedFilter
