import { useMemo } from "react"

import * as shared from "probuild-shared"

import useDependencyProvider from "model/utils/useDependencyProvider"
import usePresenter from "model/presenter/usePresenter"

const usePhoneNumberChangeVerify = ({
  teamKey,
  requestUuid,
  observer,
}: {
  teamKey: string
  requestUuid: string | undefined
  observer: shared.com.probuildsoftware.probuild.library.users.PhoneNumberChangeVerifyEventObserver
}): [
  shared.com.probuildsoftware.probuild.library.users.data.view.PhoneNumberChangeVerifyViewData | null,
  shared.com.probuildsoftware.probuild.library.users.PhoneNumberChangeVerifyInput | null
] => {
  const dependencyProvider = useDependencyProvider()
  const presenter = useMemo(() => {
    if (dependencyProvider && requestUuid) {
      console.log(
        `Creating phone number change verify presenter teamKey = ${teamKey} requestUuid = ${requestUuid}`
      )
      return new shared.com.probuildsoftware.probuild.library.users.PhoneNumberChangeVerifyPresenter(
        dependencyProvider,
        null,
        teamKey,
        {
          requestUuid,
        }
      )
    }
  }, [teamKey, requestUuid, dependencyProvider])
  return usePresenter(presenter, observer)
}

export default usePhoneNumberChangeVerify
