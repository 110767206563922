import { styled } from "@mui/material/styles"
import { Toolbar, Typography } from "@mui/material"

import * as shared from "probuild-shared"

import AccountAvatars from "components/views/app/AccountAvatars"

const StyledToolbar = styled(Toolbar)({
  width: "100%",
})

const NamesDiv = styled("div")(({ theme }) => ({
  minWidth: 0,
  textTransform: "none",
  paddingLeft: theme.spacing(3),
}))

const TruncatedTypography = styled(Typography)({
  minWidth: 0,
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
})

const AccountView = ({
  account,
  light,
}: {
  account: shared.com.probuildsoftware.probuild.library.app.data.view.AccountViewData | null
  light: boolean
}) => {
  const textColor = light ? "background.paper" : "black"
  const textStyle = { color: textColor }
  return (
    <StyledToolbar>
      <AccountAvatars accountViewData={account} light={light} size={40} />
      <NamesDiv>
        <TruncatedTypography style={textStyle} variant="subtitle2" align="left">
          {account?.teamName}
        </TruncatedTypography>
        <TruncatedTypography style={textStyle} variant="body2" align="left">
          {account?.userName}
        </TruncatedTypography>
      </NamesDiv>
    </StyledToolbar>
  )
}

export default AccountView
