const paths = {
  authPhone(): string {
    return `/auth/phone`
  },

  authSecureCode(): string {
    return `/auth/code`
  },

  authSetup(): string {
    return `/auth/setup`
  },

  clientEdit(teamKey: string, clientKey: string): string {
    return `/teams/${teamKey}/clients/${clientKey}/edit`
  },

  clientCreate(teamKey: string): string {
    return `/teams/${teamKey}/clients/create`
  },

  clients(teamKey: string): string {
    return `/teams/${teamKey}/clients`
  },

  contracts(teamKey: string): string {
    return `/teams/${teamKey}/contracts`
  },

  contractCreate(teamKey: string): string {
    return `/teams/${teamKey}/contracts/create`
  },

  contractEdit(teamKey: string, contractKey: string): string {
    return `/teams/${teamKey}/contracts/${contractKey}/edit`
  },

  documents(teamKey: string): string {
    return `/teams/${teamKey}/documents`
  },

  document(teamKey: string, documentKey: string): string {
    return `/teams/${teamKey}/documents/${documentKey}`
  },

  documentEdit(teamKey: string, documentKey: string): string {
    return `/teams/${teamKey}/documents/${documentKey}/edit`
  },

  documentCreateEstimate(teamKey: string): string {
    return `/teams/${teamKey}/documents/create/estimate`
  },

  documentCreateInvoice(teamKey: string): string {
    return `/teams/${teamKey}/documents/create/invoice`
  },

  feed(teamKey: string): string {
    return `/teams/${teamKey}/feed`
  },

  helpAndFeedback(teamKey: string): string {
    return `/teams/${teamKey}/help`
  },

  items(teamKey: string): string {
    return `/teams/${teamKey}/items`
  },

  itemCreate(teamKey: string): string {
    return `/teams/${teamKey}/items/create`
  },

  itemEdit(teamKey: string, itemKey: string): string {
    return `/teams/${teamKey}/items/${itemKey}/edit`
  },

  projects(teamKey: string): string {
    return `/teams/${teamKey}/projects`
  },

  projectCreate(teamKey: string): string {
    return `/teams/${teamKey}/projects/create`
  },

  project(teamKey: string, projectKey: string): string {
    return `/teams/${teamKey}/projects/${projectKey}`
  },

  projectInfo(teamKey: string, projectKey: string): string {
    return `/teams/${teamKey}/projects/${projectKey}/info`
  },

  postPreview(teamKey: string, projectKey: string, postKey: string): string {
    return `/teams/${teamKey}/posts/${projectKey}/${postKey}`
  },

  projectMembers(teamKey: string, projectKey: string): string {
    return `/teams/${teamKey}/projects/${projectKey}/members`
  },

  users(teamKey: string): string {
    return `/teams/${teamKey}/users`
  },

  userEdit(teamKey: string, userKey: string): string {
    return `/teams/${teamKey}/users/${userKey}`
  },

  userInvite(teamKey: string, userKey: string): string {
    return `/teams/${teamKey}/users/${userKey}/invite`
  },

  userReinvite(teamKey: string, userKey: string): string {
    return `/teams/${teamKey}/users/${userKey}/reinvite`
  },

  userAdd(teamKey: string): string {
    return `/teams/${teamKey}/users/add`
  },

  reportPdfLocal(teamKey: string, sharedId: string): string {
    return `/teams/${teamKey}/pdf/${sharedId}?local=true&disableOpenTracking=true`
  },

  reportPdfExisting(teamKey: string, sharedId: string): string {
    return `/teams/${teamKey}/pdf/${sharedId}?disableOpenTracking=true`
  },

  reportPdfFile(teamKey: string, sharedId: string): string {
    return `/api/pdf/${teamKey}/shared/${sharedId}`
  },

  team(teamKey: string): string {
    return `/teams/${teamKey}`
  },

  profile(teamKey: string): string {
    return `/teams/${teamKey}/profile`
  },

  subscriptions(teamKey: string): string {
    return `/teams/${teamKey}/subscriptions`
  },

  supportAgentFeed(): string {
    return `/agent/feed`
  },

  supportAgentTeamSelector(): string {
    return `/agent/businesses`
  },
}

export default paths
