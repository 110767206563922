import { useEffect, useState } from "react"

import * as shared from "probuild-shared"

const usePresenter = <Input, ViewData, EventsObserver>(
  presenter:
    | shared.com.probuildsoftware.probuild.library.common.model.presenter.Presenter<
        Input,
        ViewData,
        EventsObserver
      >
    | undefined,
  eventsObserver?: EventsObserver
): [ViewData | null, Input | null] => {
  const [viewData, setViewData] = useState<ViewData | null>(null)
  const [input, setInput] = useState<Input | null>(null)
  useEffect(() => {
    if (presenter) {
      setInput(presenter.input)
      const cleanUpViewData = presenter.observeViewData((viewData) => {
        setViewData(viewData)
      })
      let cleanUpEvents: (() => void) | null = null
      if (eventsObserver) {
        cleanUpEvents = presenter.observeEvents(eventsObserver)
      }
      return () => {
        cleanUpViewData()
        if (cleanUpEvents) {
          cleanUpEvents()
        }
      }
    }
  }, [presenter, eventsObserver])
  return [viewData, input]
}

export default usePresenter
