import shared from "probuild-shared"

class SignedInUser {
  readonly uid: string
  readonly idToken: string
  readonly claims: { [key: string]: any }

  constructor(uid: string, idToken: string, claims: { [key: string]: any }) {
    this.uid = uid
    this.idToken = idToken
    this.claims = claims
  }

  isSupportAgent(): boolean {
    return this.claims.isSupportAgent === true
  }

  getUserKeyForTeam(teamKey: string): string | null {
    if (this.isSupportAgent()) {
      const supportTeamKey =
        shared.com.probuildsoftware.probuild.library.common.utils.TeamKeys
          .SUPPORT
      return this.claims["users"]?.[supportTeamKey]
    }
    return (
      this.claims["users"]?.[teamKey] || this.claims["readOnlyUsers"]?.[teamKey]
    )
  }
}

export default SignedInUser
