import { Box } from "@mui/material"

import * as shared from "probuild-shared"

const TextWithFallbackView = ({
  className,
  data,
}: {
  className?: string
  data: shared.com.probuildsoftware.probuild.library.common.data.view.TextWithFallbackData
}) => {
  return (
    <Box className={className} fontStyle={data.fallback ? "italic" : undefined}>
      {data.text || data.fallback}
    </Box>
  )
}

export default TextWithFallbackView
