import {
  getFirestore,
  collection as getCollection,
  addDoc,
  orderBy,
  limit,
  query,
  where,
  onSnapshot,
} from "firebase/firestore"

import * as shared from "probuild-shared"

class LiveFirestoreApi
  implements
    shared.com.probuildsoftware.probuild.library.common.model.database
      .FirestoreApi
{
  onCreate(
    collection: string,
    documentJson: string,
    observer: shared.com.probuildsoftware.probuild.library.common.model.database.FirestoreDocumentCreateObserver
  ): void {
    const documentData = JSON.parse(documentJson)

    addDoc(getCollection(getFirestore(), collection), documentData)
      .then((documentReference) => {
        observer.onCreateSuccess(documentReference.id)
      })
      .catch(() => {
        observer.onCreateError()
      })
  }

  onQuery(
    collection: string,
    firestoreQuery: shared.com.probuildsoftware.probuild.library.common.model.database.FirestoreApiQuery,
    observer: shared.com.probuildsoftware.probuild.library.common.model.database.FirestoreDocumentSnapshotListObserver
  ): () => void {
    let currentQuery = query(
      query(
        getCollection(getFirestore(), collection),
        orderBy(
          firestoreQuery.orderBy,
          firestoreQuery.ascending ? "asc" : "desc"
        )
      ),
      limit(1)
    )
    firestoreQuery.equalTo.asJsReadonlyArrayView().forEach((equalTo) => {
      currentQuery = query(
        currentQuery,
        where(equalTo.property, "==", equalTo.value)
      )
    })
    return onSnapshot(currentQuery, {
      next(snapshot) {
        const dataArray = snapshot.docs.map((document) => {
          return {
            id: document.id,
            documentJson: JSON.stringify(document.data()),
          }
        })
        const dataList =
          shared.com.probuildsoftware.probuild.library.common.utils.ArrayUtils.Companion.convertToKotlinList(
            dataArray
          )
        observer.onDocumentSnapshotListUpdated(collection, dataList)
      },
      error() {
        console.log(`Error loading snapshots from collection ${collection}`)
        const dataList =
          shared.com.probuildsoftware.probuild.library.common.utils.ArrayUtils.Companion.convertToKotlinList(
            []
          )
        observer.onDocumentSnapshotListUpdated(collection, dataList)
      },
    })
  }
}

export default LiveFirestoreApi
