import {
  getDatabase,
  ref,
  push,
  DataSnapshot,
  onValue,
  off,
  update,
  Query,
  orderByChild,
  startAt,
  endAt,
  equalTo,
  limitToFirst,
  limitToLast,
  query,
  orderByKey,
} from "firebase/database"

import shared from "probuild-shared"
class LiveDataApi
  implements
    shared.com.probuildsoftware.probuild.library.common.model.database.DataApi
{
  createNewKey(): string {
    return push(ref(getDatabase())).key!!
  }

  isOfflinePersistenceEnabled(): boolean {
    return false
  }

  onDataGet(
    path: string,
    observer: shared.com.probuildsoftware.probuild.library.common.model.database.FirebaseDataObserver
  ): () => void {
    console.log(`Firebase database value started listing to ${path}`)
    const currentRef = ref(getDatabase(), path)
    const snapshotObserver = (snapshot: DataSnapshot | null) => {
      const data = snapshot && snapshot.exists() ? snapshot.val() : null
      const json = data ? JSON.stringify(data) : null
      console.log(`Firebase database value loaded value ${path} json ${json}`)
      observer.onDataUpdated(path, {
        valueJson: json,
        valueString: null,
        valueBoolean: null,
        valueNumber: null,
      })
    }
    const cancelObserver = () => {
      observer.onPermissionDenied(path)
    }
    onValue(currentRef, snapshotObserver, cancelObserver)
    return () => {
      off(currentRef, "value", snapshotObserver)
      console.log(`Firebase database value stopped listing to ${path}`)
    }
  }

  onDataQuery(
    path: string,
    query: shared.com.probuildsoftware.probuild.library.common.model.database.FirebaseQuery,
    observer: shared.com.probuildsoftware.probuild.library.common.model.database.FirebaseDataListObserver
  ): () => void {
    console.log(`Firebase database query started listing to ${path}`)
    const snapshotObserver = (snapshot: DataSnapshot | null) => {
      const dataList =
        Array<shared.com.probuildsoftware.probuild.library.common.model.database.FirebaseSnapshot>()
      if (snapshot && snapshot.exists()) {
        snapshot.forEach(function (childSnapshot) {
          const childKey = childSnapshot.key!!
          const childJson = JSON.stringify(childSnapshot.val())
          const data = {
            valueJson: childJson,
            valueString: null,
            valueBoolean: null,
            valueNumber: null,
          }
          dataList.push({ key: childKey, data: data })
        })
      }
      console.log(
        `Firebase database query loaded list from ${path} of size ${dataList.length}`
      )
      observer.onDataListUpdated(path, dataList)
    }
    const ref = this.getFirebaseQueryReference(path, query)
    onValue(ref, snapshotObserver)
    return () => {
      off(ref, "value", snapshotObserver)
      console.log(`Firebase database query stopped listing to ${path}`)
    }
  }

  onDataSaveMultiple(
    dataList: shared.kotlin.collections.KtList<shared.com.probuildsoftware.probuild.library.common.model.database.FirebaseSnapshot>,
    observer: shared.com.probuildsoftware.probuild.library.common.model.database.FirebaseDataSaveMultipleObserver
  ) {
    const valuesMap: { [key: string]: any } = {}
    dataList.asJsReadonlyArrayView().forEach((snapshot) => {
      if (snapshot.data) {
        valuesMap[snapshot.key] = this.extractValue(snapshot.data)
      }
    })
    update(ref(getDatabase()), valuesMap)
      .then(() => {
        observer.onDataSaveMultipleSuccess()
      })
      .catch((error: Error) => {
        if (error.message.toLowerCase().indexOf("permission_denied") === -1) {
          observer.onDataSaveMultipleError()
        } else {
          observer.onDataSaveMultiplePermissionDenied()
        }
      })
  }

  private getFirebaseQueryReference(
    path: string,
    firebaseQuery: shared.com.probuildsoftware.probuild.library.common.model.database.FirebaseQuery
  ): Query {
    var databaseQuery: Query = ref(getDatabase(), path)
    if (firebaseQuery.byChild) {
      if (firebaseQuery.byChild.childKey) {
        databaseQuery = query(
          databaseQuery,
          orderByChild(firebaseQuery.byChild.childKey)
        )
      }
      if (firebaseQuery.byChild.startValue) {
        databaseQuery = query(
          databaseQuery,
          startAt(firebaseQuery.byChild.startValue)
        )
      }
      if (firebaseQuery.byChild.endValue) {
        databaseQuery = query(
          databaseQuery,
          endAt(firebaseQuery.byChild.endValue)
        )
      }
      if (firebaseQuery.byChild.equalTo) {
        databaseQuery = query(
          databaseQuery,
          equalTo(firebaseQuery.byChild.equalTo)
        )
      }
    }
    if (firebaseQuery.byKey) {
      databaseQuery = query(databaseQuery, orderByKey())
      if (firebaseQuery.byKey.startKey) {
        databaseQuery = query(
          databaseQuery,
          startAt(firebaseQuery.byKey.startKey)
        )
      }
      if (firebaseQuery.byKey.endKey) {
        databaseQuery = query(databaseQuery, endAt(firebaseQuery.byKey.endKey))
      }
    }
    if (firebaseQuery.limitToFirst) {
      databaseQuery = query(
        databaseQuery,
        limitToFirst(firebaseQuery.limitToFirst)
      )
    } else if (firebaseQuery.limitToLast) {
      databaseQuery = query(
        databaseQuery,
        limitToLast(firebaseQuery.limitToLast)
      )
    }
    return databaseQuery
  }

  private extractValue(
    data: shared.com.probuildsoftware.probuild.library.common.model.database.FirebaseData
  ): any {
    if (data.valueBoolean != null) {
      return data.valueBoolean.valueOf()
    } else if (data.valueString != null) {
      return data.valueString
    } else if (data.valueNumber != null) {
      return data.valueNumber.valueOf()
    } else if (data.valueJson != null) {
      return JSON.parse(data.valueJson)
    }
    return null
  }
}

export default LiveDataApi
