import NotFoundPage from "components/pages/NotFoundPage"
import SupportAgentFeedPage from "components/pages/SupportAgentFeedPage"
import SupportAgentTeamSelectorPage from "components/pages/SupportAgentTeamSelectorPage"
import useAccountsList from "model/app/useAccountsList"
import paths from "model/utils/paths"
import { Routes, Route, Navigate, Outlet } from "react-router-dom"

const SupportAgentRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<SupportAgentRootRoute />}>
        <Route path="feed" element={<SupportAgentFeedPage />} />
        <Route path="businesses" element={<SupportAgentTeamSelectorPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  )
}

const SupportAgentRootRoute = () => {
  const [accounts] = useAccountsList()
  const currentAccountTeamKey = accounts?.currentAccount?.teamKey
  if (!accounts) {
    return <></>
  } else if (accounts.isSignedOut || !accounts.currentAccount) {
    return <Navigate to={paths.authPhone()} replace={true} />
  } else if (!accounts.isSupportAgent && currentAccountTeamKey) {
    return <Navigate to={paths.team(currentAccountTeamKey)} replace={true} />
  } else if (!accounts.isSupportAgent) {
    return <Navigate to={"/"} replace={true} />
  } else {
    return <Outlet />
  }
}

export default SupportAgentRoutes
