import { styled } from "@mui/material/styles"
import { Button, Grid } from "@mui/material"
import { SvgIconComponent } from "@mui/icons-material"

const ActionButton = styled(Button)({
  width: "100%",
  justifyContent: "flex-start",
})

const ButtonLabelDiv = styled("div")(({ theme }) => ({
  marginLeft: theme.spacing(2),
}))

const ActionListItem = ({
  Icon,
  text,
  onClick,
}: {
  Icon: SvgIconComponent
  text: string | null | undefined
  onClick: () => void
}) => {
  return (
    <Grid item sm={12} md={6}>
      <ActionButton color="primary" onClick={onClick}>
        <Icon />
        <ButtonLabelDiv>{text}</ButtonLabelDiv>
      </ActionButton>
    </Grid>
  )
}

export default ActionListItem
