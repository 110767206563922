import * as shared from "probuild-shared"

import LabeledCard from "components/views/generic/LabeledCard"
import LoadingModal from "components/views/generic/LoadingModal"
import ViewDataTextField from "components/views/generic/ViewDataTextField"
import AddressAndMapView from "components/views/generic/AddressAndMapView"
import usePromptSafe from "model/navigation/usePromptSafe"
import { NavigateSafeState } from "model/navigation/useNavigateSafe"

const ClientEditView = ({
  viewData,
  input,
  navigateSafeState,
}: {
  viewData: shared.com.probuildsoftware.probuild.library.clients.data.view.ClientViewData | null
  input: shared.com.probuildsoftware.probuild.library.clients.ClientInput | null
  navigateSafeState: NavigateSafeState
}) => {
  usePromptSafe({
    when: viewData?.status.isModified === true,
    message: viewData?.actions.discardConfirmationDialog.message || "",
    navigateSafeState: navigateSafeState,
  })
  if (!input || !viewData) return null
  return (
    <>
      <LabeledCard text={viewData.sections.information.title}>
        <ViewDataTextField
          viewData={viewData.sections.information.name}
          onChange={(text) => input?.changeName(text)}
        />
        <ViewDataTextField
          viewData={viewData.sections.information.email}
          onChange={(text) => input?.changeEmail(text)}
        />
        <ViewDataTextField
          viewData={viewData.sections.information.mobilePhone}
          onChange={(text) => input?.changeMobilePhone(text)}
        />
        <ViewDataTextField
          viewData={viewData.sections.information.homePhone}
          onChange={(text) => input?.changeHomePhone(text)}
        />
      </LabeledCard>
      <LabeledCard text={viewData.sections.address.title}>
        <AddressAndMapView
          location={viewData.sections.address.location}
          onChange={(address, lat, lng) => {
            input?.changeLocation(address, lat, lng)
          }}
        />
      </LabeledCard>
      <LabeledCard text={viewData.sections.other.title}>
        <ViewDataTextField
          viewData={viewData.sections.other.notes}
          onChange={(text) => input?.changeNotes(text)}
        />
      </LabeledCard>
      <LoadingModal
        open={
          viewData.status.isSaveInProgress || viewData.status.isDeleteInProgress
        }
      />
    </>
  )
}

export default ClientEditView
