import { useMemo } from "react"
import { Box } from "@mui/material"

import NavigationBarDialog from "components/dialogs/NavigationBarDialog"
import useSnackbar from "model/snackbar/useSnackbar"
import SaveButton from "components/views/generic/SaveButton"
import usePaymentEdit from "model/documents/payments/usePaymentEdit"
import PaymentEditView from "components/views/documents/payments/PaymentEditView"
import OverflowMenu from "components/views/generic/OverflowMenu"
import DeleteMenuItem from "components/views/generic/DeleteMenuItem"
import ConfirmationDialog from "./ConfirmationDialog"
import { NavigateSafeState } from "model/navigation/useNavigateSafe"

const PaymentEditDialog = ({
  teamKey,
  documentKey,
  paymentKey,
  isOpen,
  onCloseDialog,
  navigateSafeState,
}: {
  teamKey: string
  documentKey: string | undefined
  paymentKey: string | null
  isOpen: boolean
  onCloseDialog: () => void
  navigateSafeState: NavigateSafeState
}) => {
  const showSnackbar = useSnackbar()
  const observer = useMemo(() => {
    return {
      onShowSuccessSnackbar(message: string) {
        showSnackbar(message, "success")
      },
      onShowErrorSnackbar(message: string) {
        showSnackbar(message, "error")
      },
      onNavigateBack() {
        onCloseDialog()
      },
    }
  }, [showSnackbar, onCloseDialog])
  const onSaveClicked = () => input?.save()
  const onDialogClosed = () => onCloseDialog()
  const [viewData, input] = usePaymentEdit({
    teamKey,
    documentKey,
    paymentKey,
    observer,
  })
  return (
    <NavigationBarDialog
      isOpen={isOpen}
      onClose={onDialogClosed}
      title={viewData?.title}
      barItems={
        <>
          <SaveButton
            text={viewData?.saveButtonText}
            isEnabled={viewData?.isSaveButtonEnabled}
            onClick={onSaveClicked}
          />
          <OverflowMenu>
            {viewData?.deleteButtonText && (
              <DeleteMenuItem
                text={viewData?.deleteButtonText}
                onClick={() => {
                  input?.deleteInitiated()
                }}
              />
            )}
          </OverflowMenu>
        </>
      }
    >
      <Box px={3}>
        <PaymentEditView
          viewData={viewData}
          input={input}
          navigateSafeState={navigateSafeState}
        />
      </Box>
      <ConfirmationDialog
        open={viewData?.deleteConfirmationDialog != null}
        onConfirm={() => input?.deleteConfirmed()}
        onCancel={() => input?.deleteCancelled()}
        dialogViewData={viewData?.deleteConfirmationDialog}
      />
    </NavigationBarDialog>
  )
}

export default PaymentEditDialog
