import { styled } from "@mui/material/styles"
import { SxProps } from "@mui/system"

const RootDiv = styled("iframe")(({ theme }) => ({
  border: 0,
  width: "100%",
}))

const createQuery = (
  address: string,
  lat: number | null | undefined,
  lng: number | null | undefined
): string => {
  if (!lat || !lng) return `&q=%20&center=0.0,0.0`
  return `&q=${encodeURIComponent(address)}&center=${lat},${lng}`
}

const GoogleMap = ({
  address,
  lat,
  lng,
  height,
  sx,
}: {
  address: string
  lat: number | null | undefined
  lng: number | null | undefined
  height?: number
  sx?: SxProps
}) => {
  const query = createQuery(address, lat, lng)
  return (
    <RootDiv
      id="google-map"
      key={query}
      sx={sx}
      title={"Google Map"}
      hidden={!lat || !lng}
      height={height || "300"}
      loading="lazy"
      src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_FIREBASE_CONFIG_API_KEY}${query}`}
    ></RootDiv>
  )
}

export default GoogleMap
