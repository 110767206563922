import { useMemo } from "react"

import * as shared from "probuild-shared"

import useUserKey from "model/utils/useUserKey"

function useAppUser({
  teamKey,
}: {
  teamKey: string
}): shared.com.probuildsoftware.probuild.library.common.model.users.AppUser | null {
  const userKey = useUserKey(teamKey)
  const appUser = useMemo(() => {
    if (!userKey) return null
    return new shared.com.probuildsoftware.probuild.library.common.model.users.AppUser(
      teamKey,
      userKey
    )
  }, [teamKey, userKey])
  return appUser
}

export default useAppUser
