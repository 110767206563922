import * as shared from "probuild-shared"

import LabeledCard from "components/views/generic/LabeledCard"
import { Box, Button, InputLabel, Typography, styled } from "@mui/material"

import ViewDataTextField from "../generic/ViewDataTextField"
import ImageView from "../generic/ImageView"
import ImageInput from "../generic/ImageInput"
import { useRef } from "react"
import ConfirmationDialog from "components/dialogs/ConfirmationDialog"
import DateSelector from "../generic/DateSelector"
import LoadingModal from "../generic/LoadingModal"
import DropdownListItemSelectionMenu from "../generic/DropdownListItemSelectionMenu"

const TeamLogoContainerDiv = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  margin: "0 auto",
  width: "300px",
  height: "200px",
  backgroundColor: theme.palette.secondary.light,
  boxShadow: "0 0 5px lightgrey",
  border: "white solid 2px",
  borderRadius: 15,
  overflow: "hidden",
}))
const StyledImageView = styled(ImageView)({
  width: "100%",
  height: "100%",
})
const PlaceholderImageView = styled("div")(({ theme }) => ({
  display: "flex",
  fontSize: "3rem",
  width: "80%",
  height: "100%",
  justifyContent: "center",
  alignItems: "center",
  color: theme.palette.secondary.main,
  backgroundColor: theme.palette.secondary.light,
}))
const TeamLogoButtonContainerDiv = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  gap: theme.spacing(2),
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
}))
const CurrentPayPeriodDiv = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  textAlign: "center",
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  color: theme.palette.text.secondary,
}))

const TeamProfileEditView = ({
  viewData,
  input,
}: {
  viewData: shared.com.probuildsoftware.probuild.library.team.data.view.TeamProfileViewData
  input: shared.com.probuildsoftware.probuild.library.team.TeamProfileInput
}) => {
  const teamBusinessLogo = viewData.sections.logo.file
  const placeholderImage = viewData.sections.logo?.placeholder
  const avatarImageInputRef = useRef<HTMLInputElement>(null)
  const onNameChanged = (name: string) => {
    input.changeName(name)
  }
  const onEmailChanged = (email: string) => {
    input.changeEmail(email)
  }
  const onPhoneNumberChanged = (phoneNumber: string) => {
    input.changePhoneNumber(phoneNumber)
  }
  const onChangeExtraDetails = (extraDetails: string) => {
    input.changeExtraDetails(extraDetails)
  }
  const onChangeAddress = (address: string) => {
    input.changeAddress(address)
  }
  const onChangeCity = (city: string) => {
    input.changeCity(city)
  }
  const onChangeProvince = (province: string) => {
    input.changeProvince(province)
  }
  const onChangePostalCode = (postalCode: string) => {
    input.changePostalCode(postalCode)
  }
  const onChangeTax = (tax: string) => {
    input.changeTax(tax)
  }
  const onChangePayrollSchedule = (value: string) => {
    input.changePayrollSchedule(value)
  }
  const onChangePayrollCloseDate = (closeDate: string) => {
    input.changePayrollCloseDate(closeDate)
  }
  const onChangePayrollMonthlyEndDay = (value: string) => {
    input?.changePayrollMonthlyEndDay(value)
  }
  const onChangePayrollTwiceAMonthEndDay1 = (value: string) => {
    input?.changePayrollTwiceAMonthEndDay1(value)
  }
  const onChangePayrollTwiceAMonthEndDay2 = (value: string) => {
    input.changePayrollTwiceAMonthEndDay2(value)
  }
  const onChangeLocale = (value: string) => {
    input.changeLocale(value)
  }
  const onChangeCurrency = (value: string) => {
    input.changeCurrency(value)
  }
  const onChangeTimezone = (value: string) => {
    input.changeTimeZone(value)
  }
  const payrollScheduleList =
    viewData.payrollScheduleListSelectionDialog?.items?.asJsReadonlyArrayView()
  const selectedPayrollSchedule = payrollScheduleList?.find(
    (payrollScheduleItem) => payrollScheduleItem.selected
  )?.itemKey
  const payrollMonthlyEndDayList =
    viewData.payrollMonthlyEndDayListSelectionDialog?.items?.asJsReadonlyArrayView()
  const selectedPayrollMonthlyEndDay = payrollMonthlyEndDayList?.find(
    (payrollMonthlyEndDayItem) => payrollMonthlyEndDayItem.selected
  )?.itemKey
  const payrollTwiceMonthlyEndDay1List =
    viewData.payrollTwiceAMonthEndDay1ListSelectionDialog?.items?.asJsReadonlyArrayView()
  const selectedPayrollTwiceMonthlyEndDay1 =
    payrollTwiceMonthlyEndDay1List?.find(
      (payrollTwiceMonthlyEndDay1Item) =>
        payrollTwiceMonthlyEndDay1Item.selected
    )?.itemKey
  const payrollTwiceMonthlyEndDay2List =
    viewData.payrollTwiceAMonthEndDay2ListSelectionDialog?.items?.asJsReadonlyArrayView()
  const selectedPayrollTwiceMonthlyEndDay2 =
    payrollTwiceMonthlyEndDay2List?.find(
      (payrollTwiceMonthlyEndDay2Item) =>
        payrollTwiceMonthlyEndDay2Item.selected
    )?.itemKey
  const localesList =
    viewData.localeListSelectionDialog?.items?.asJsReadonlyArrayView()
  const selectedLocales = localesList?.find(
    (localeItem) => localeItem.selected
  )?.itemKey
  const currenciesList =
    viewData.currencyListSelectionDialog?.items?.asJsReadonlyArrayView()
  const selectedCurrency = currenciesList?.find(
    (currencyItem) => currencyItem.selected
  )?.itemKey
  const timeZonesList =
    viewData.timeZoneListSelectionDialog?.items?.asJsReadonlyArrayView()
  const selectedTimeZone = timeZonesList?.find(
    (timeZoneItem) => timeZoneItem.selected
  )?.itemKey
  const isDropdownDisabled = viewData.isEditable ? false : true
  return (
    <>
      <TeamLogoContainerDiv>
        {teamBusinessLogo ? (
          <StyledImageView
            alt={viewData.sections.logo.logoAltDescription}
            file={teamBusinessLogo}
            width={300}
            height={200}
          />
        ) : (
          <PlaceholderImageView>{placeholderImage}</PlaceholderImageView>
        )}
        <ImageInput
          inputRef={avatarImageInputRef}
          hidden={true}
          onFileChanged={(inputFile) => {
            input.changeLogoFile(inputFile)
            const currentInput = avatarImageInputRef?.current
            if (currentInput) {
              currentInput.value = ""
            }
          }}
        />
      </TeamLogoContainerDiv>
      <TeamLogoButtonContainerDiv>
        <>
          {viewData.sections.logo.changeLogoButtonText && (
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                avatarImageInputRef.current?.click()
              }}
            >
              {viewData.sections.logo.changeLogoButtonText}
            </Button>
          )}
          {viewData.sections.logo.removeLogoButtonText && (
            <Button
              color="primary"
              variant="outlined"
              onClick={() => input.removeLogoFile()}
            >
              {viewData.sections.logo.removeLogoButtonText}
            </Button>
          )}
        </>
      </TeamLogoButtonContainerDiv>

      <LabeledCard text={viewData.sections.info.sectionTitle}>
        <ViewDataTextField
          viewData={viewData.sections.info.name}
          onChange={onNameChanged}
        />
        <ViewDataTextField
          viewData={viewData.sections.info.email}
          onChange={onEmailChanged}
        />
        <ViewDataTextField
          viewData={viewData.sections.info.phoneNumber}
          onChange={onPhoneNumberChanged}
        />
        <ViewDataTextField
          viewData={viewData.sections.info.extraDetails}
          onChange={onChangeExtraDetails}
        />
      </LabeledCard>

      <LabeledCard text={viewData.sections.address.sectionTitle}>
        <ViewDataTextField
          viewData={viewData.sections.address.address}
          onChange={onChangeAddress}
        />
        <ViewDataTextField
          viewData={viewData.sections.address.city}
          onChange={onChangeCity}
        />
        <ViewDataTextField
          viewData={viewData.sections.address.province}
          onChange={onChangeProvince}
        />
        <ViewDataTextField
          viewData={viewData.sections.address.postalCode}
          onChange={onChangePostalCode}
        />
      </LabeledCard>

      <LabeledCard text={viewData.sections.tax.sectionTitle}>
        <ViewDataTextField
          viewData={viewData.sections.tax.rate}
          onChange={onChangeTax}
        />
      </LabeledCard>

      <LabeledCard text={viewData.sections.payroll.sectionTitle}>
        <DropdownListItemSelectionMenu
          label={viewData.sections.payroll.schedule?.label}
          value={selectedPayrollSchedule}
          renderValue={viewData.sections.payroll.schedule?.value}
          onChange={onChangePayrollSchedule}
          onOpen={() => input?.selectPayrollScheduleInitiated()}
          options={payrollScheduleList}
          disable={isDropdownDisabled}
        />
        {viewData.sections.payroll.lastPeriodClosedAtLocalFormat && (
          <Box sx={{ width: "100%" }}>
            <InputLabel
              variant="standard"
              shrink={true}
              sx={{ color: "inherit", transformOrigin: "bottom left" }}
            >
              {viewData.sections.payroll.lastPeriodClosedAtLocalFormat?.label}
            </InputLabel>
            <DateSelector
              isoDate={viewData.sections.payroll.lastPeriodClosedAtIso}
              onDateChange={onChangePayrollCloseDate}
              disable={isDropdownDisabled}
            />
          </Box>
        )}
        {viewData.sections.payroll.twiceAMonthEndDay1 && (
          <DropdownListItemSelectionMenu
            label={viewData.sections.payroll.twiceAMonthEndDay1?.label}
            value={selectedPayrollTwiceMonthlyEndDay1}
            renderValue={viewData.sections.payroll.twiceAMonthEndDay1?.value}
            onChange={onChangePayrollTwiceAMonthEndDay1}
            onOpen={() => input?.selectPayrollTwiceAMonthEndDay1Initiated()}
            options={payrollTwiceMonthlyEndDay1List}
            disable={isDropdownDisabled}
          />
        )}
        {viewData.sections.payroll.twiceAMonthEndDay2 && (
          <DropdownListItemSelectionMenu
            label={viewData.sections.payroll.twiceAMonthEndDay2?.label}
            value={selectedPayrollTwiceMonthlyEndDay2}
            renderValue={viewData.sections.payroll.twiceAMonthEndDay2?.value}
            onChange={onChangePayrollTwiceAMonthEndDay2}
            onOpen={() => input?.selectPayrollTwiceAMonthEndDay2Initiated()}
            options={payrollTwiceMonthlyEndDay2List}
            disable={isDropdownDisabled}
          />
        )}
        {viewData.sections.payroll.monthlyEndDay && (
          <DropdownListItemSelectionMenu
            label={viewData.sections.payroll.monthlyEndDay?.label}
            value={selectedPayrollMonthlyEndDay}
            renderValue={viewData.sections.payroll.monthlyEndDay?.value}
            onChange={onChangePayrollMonthlyEndDay}
            onOpen={() => input?.selectPayrollMonthlyEndDayInitiated()}
            options={payrollMonthlyEndDayList}
            disable={isDropdownDisabled}
          />
        )}
        <CurrentPayPeriodDiv>
          <Box
            sx={{
              width: "fit-content",
            }}
          >
            <Typography>
              {viewData.sections.payroll.currentPeriod?.label}
            </Typography>
            <Typography>
              {viewData.sections.payroll.currentPeriod?.value}
            </Typography>
          </Box>
        </CurrentPayPeriodDiv>
      </LabeledCard>

      <LabeledCard text={viewData.sections.internationalization.sectionTitle}>
        <DropdownListItemSelectionMenu
          label={viewData.sections.internationalization.locale?.label}
          value={selectedLocales}
          renderValue={viewData.sections.internationalization.locale?.value}
          onChange={onChangeLocale}
          onOpen={() => input?.selectLocaleInitiated()}
          options={localesList}
          disable={!viewData.isEditable}
        />
        <DropdownListItemSelectionMenu
          label={viewData.sections.internationalization.currency?.label}
          value={selectedCurrency}
          renderValue={viewData.sections.internationalization.currency?.value}
          onChange={onChangeCurrency}
          onOpen={() => input?.selectCurrencyInitiated()}
          options={currenciesList}
          disable={!viewData.isEditable}
        />
        <DropdownListItemSelectionMenu
          label={viewData.sections.internationalization.timeZone?.label}
          value={selectedTimeZone}
          renderValue={viewData.sections.internationalization.timeZone?.value}
          onChange={onChangeTimezone}
          onOpen={() => input?.selectTimeZoneInitiated()}
          options={timeZonesList}
          disable={!viewData.isEditable}
        />
      </LabeledCard>

      <LoadingModal
        open={viewData.isSaveInProgress || viewData.isDeleteInProgress}
      />
      <ConfirmationDialog
        open={viewData?.deleteConfirmationDialog != null}
        onConfirm={() => input?.deleteConfirmed()}
        onCancel={() => input?.deleteCancelled()}
        dialogViewData={viewData?.deleteConfirmationDialog}
      />
      <ConfirmationDialog
        open={viewData?.deleteDoubleConfirmationDialog != null}
        onConfirm={() => input?.deleteDoubleConfirmed()}
        onCancel={() => input?.deleteCancelled()}
        dialogViewData={viewData?.deleteDoubleConfirmationDialog}
      />
    </>
  )
}

export default TeamProfileEditView
