import { useMemo } from "react"
import { useParams } from "react-router-dom"

import TeamPageLayout from "components/views/layouts/TeamPageLayout"
import SaveButton from "components/views/generic/SaveButton"
import useProjectMemberList from "model/projects/useProjectMemberList"
import UserListSectionView from "components/views/users/list/UserListSectionView"
import useNavigateSafe from "model/navigation/useNavigateSafe"

const ProjectMemberListPage = ({ teamKey }: { teamKey: string }) => {
  const { projectKey } = useParams()
  const [navigate] = useNavigateSafe()
  const observer = useMemo(() => {
    return {
      onNavigateBack() {
        navigate(-1)
      },
      onSave() {},
    }
  }, [navigate])
  const [viewData, input] = useProjectMemberList({
    teamKey,
    projectKey,
    observer,
  })
  const sections = viewData?.sections?.asJsReadonlyArrayView() || []
  return (
    <TeamPageLayout
      title={viewData?.title}
      barItems={
        <>
          <SaveButton
            text={viewData?.saveButtonTitle}
            isEnabled={true}
            onClick={() => input?.save()}
          />
        </>
      }
    >
      {sections.map((section) => {
        return (
          <UserListSectionView
            key={section.title}
            teamKey={teamKey}
            section={section}
            onUserClicked={(userKey: string) =>
              input?.toggleIsUserSelected(userKey)
            }
          />
        )
      })}
    </TeamPageLayout>
  )
}

export default ProjectMemberListPage
