import { styled } from "@mui/material/styles"
import { Box, TableCell, TableRow, Typography } from "@mui/material"
import { Check } from "@mui/icons-material"

import * as shared from "probuild-shared"

import paths from "model/utils/paths"
import LinkTableRow from "components/views/generic/LinkTableRow"
import UserAvatar from "components/views/users/UserAvatar"

const TextContainerDiv = styled("div")(({ theme }) => ({
  paddingLeft: theme.spacing(2),
}))

const TableCellContent = styled("div")({
  display: "flex",
  alignItems: "center",
})

const UserListItemContent = ({
  teamKey,
  item,
}: {
  teamKey: string
  item: shared.com.probuildsoftware.probuild.library.users.data.view.UserListItemViewData
}) => {
  return (
    <TableCell>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TableCellContent>
          <UserAvatar size={50} viewData={item.avatar} />
          <TextContainerDiv>
            <Box fontStyle={item.isNameItalicized ? "italic" : "normal"}>
              {item.name}
            </Box>
            <Typography color="textSecondary">{item.phoneNumber}</Typography>
          </TextContainerDiv>
        </TableCellContent>
        {item.isSelected && <Check color="primary" />}
      </Box>
    </TableCell>
  )
}

const StyledTableRow = styled(TableRow)({
  textDecoration: "none",
  cursor: "pointer",
})

const UserListItemView = ({
  teamKey,
  item,
  onUserClicked,
}: {
  teamKey: string
  item: shared.com.probuildsoftware.probuild.library.users.data.view.UserListItemViewData
  onUserClicked?: (userKey: string) => void
}) => {
  if (onUserClicked) {
    return (
      <StyledTableRow
        hover={true}
        onClick={() => {
          onUserClicked(item.userKey)
        }}
      >
        <UserListItemContent teamKey={teamKey} item={item} />
      </StyledTableRow>
    )
  } else {
    const userPath = paths.userEdit(teamKey, item.userKey)
    return (
      <LinkTableRow to={userPath}>
        <UserListItemContent teamKey={teamKey} item={item} />
      </LinkTableRow>
    )
  }
}

export default UserListItemView
