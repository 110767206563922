import shared from "probuild-shared"
class LivePlatformMetadataApi
  implements
    shared.com.probuildsoftware.probuild.library.common.model.metadata.api
      .PlatformMetadataApi
{
  appVersion = process.env.REACT_APP_PROBUILD_VERSION || null
  deviceModel = null
  osName = "Web"
  osVersion = null
  userAgent = window.navigator.userAgent
}

export default LivePlatformMetadataApi
