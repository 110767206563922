import { saveKey, loadKey } from "model/crypto/CryptoPersistence"

import shared from "probuild-shared"

class LiveAesSecretStorageApi
  implements
    shared.com.probuildsoftware.probuild.library.common.model.crypto
      .AesSecretStorageApi
{
  async save(aesSecret: string, teamKey: string, completion: () => void) {
    const keyData = Uint8Array.from(atob(aesSecret), (c) => c.charCodeAt(0))
    const cryptoKey = await crypto.subtle.importKey(
      "raw",
      keyData,
      "aes-cbc",
      true,
      ["encrypt", "decrypt", "wrapKey", "unwrapKey"]
    )
    const keyIdentifier = this.teamKeyIdentifier(teamKey)
    await saveKey(keyIdentifier, cryptoKey)
    completion()
  }

  async load(teamKey: string, completion: (aesSecret: string) => void) {
    const keyIdentifier = this.teamKeyIdentifier(teamKey)
    const cryptoKey = await loadKey(keyIdentifier)
    const rawKey = await crypto.subtle.exportKey("raw", cryptoKey)
    const encodedKey = btoa(
      new Uint8Array(rawKey).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        ""
      )
    )
    completion(encodedKey)
  }

  private teamKeyIdentifier(teamKey: string): string {
    return teamKey + "-team-aes"
  }
}

export default LiveAesSecretStorageApi
