import { useMemo } from "react"
import { Box } from "@mui/material"

import NavigationBarDialog from "components/dialogs/NavigationBarDialog"
import useContract from "model/contracts/edit/useContract"
import useSnackbar from "model/snackbar/useSnackbar"
import SaveButton from "components/views/generic/SaveButton"
import ContractEditView from "components/views/contracts/edit/ContractEditView"
import useNavigateSafe from "model/navigation/useNavigateSafe"

const ContractEditDialog = ({
  teamKey,
  contractKey,
  isOpen,
  onContractSaved,
}: {
  teamKey: string
  contractKey: string | undefined
  isOpen: boolean
  onContractSaved: (contractKey: string | null) => void
}) => {
  const showSnackbar = useSnackbar()
  const [, navigateSafeState] = useNavigateSafe()
  const observer = useMemo(() => {
    return {
      onSaveSuccess(message: string, contractKey: string) {
        showSnackbar(message, "success")
        onContractSaved(contractKey)
      },
      onSaveError(message: string) {
        showSnackbar(message, "error")
      },
      onDeleteSuccess(message: string) {
        showSnackbar(message, "success")
      },
      onDeleteError(message: string) {
        showSnackbar(message, "error")
      },
    }
  }, [showSnackbar, onContractSaved])
  const onSaveClicked = () => input?.save()
  const onDialogClosed = () => onContractSaved(null)
  const [viewData, input] = useContract({
    teamKey,
    contractKey,
    observer,
    isForDocument: contractKey != null,
  })
  console.log(viewData)
  return (
    <NavigationBarDialog
      isOpen={isOpen}
      onClose={onDialogClosed}
      title={viewData?.title}
      barItems={
        <SaveButton
          text={viewData?.actions.saveButtonText}
          isEnabled={viewData?.status.isModified}
          onClick={onSaveClicked}
        />
      }
    >
      <Box px={3}>
        <ContractEditView
          viewData={viewData}
          input={input}
          navigateSafeState={navigateSafeState}
        />
      </Box>
    </NavigationBarDialog>
  )
}

export default ContractEditDialog
