import { Button } from "@mui/material"
import { Link } from "react-router-dom"

const DeleteButton = ({
  text,
  to,
  onClick,
}: {
  text: string | null | undefined
  to?: string
  onClick?: () => void
}) => {
  if (!text) return null
  return (
    <Button
      component={to ? Link : "button"}
      to={to}
      onClick={onClick}
      variant="contained"
      color="error"
      sx={{ flexShrink: 0 }}
    >
      {text}
    </Button>
  )
}

export default DeleteButton
