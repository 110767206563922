import { styled } from "@mui/material/styles"
import { Box, ListItemButton, ListItemText } from "@mui/material"

import NavigationBarDialog from "components/dialogs/NavigationBarDialog"
import useItemList from "model/items/list/useItemList"
import SearchBar from "components/views/generic/SearchBar"
import CreateButton from "components/views/generic/CreateButton"
import ListIcon from "@mui/icons-material/List"
import ListDialogContent from "components/dialogs/ListDialogContent"

const StyledBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
})

const ItemListDialog = ({
  teamKey,
  isOpen,
  onItemSelected,
  onCreateClicked,
}: {
  teamKey: string
  isOpen: boolean
  onItemSelected: (itemKey: string | null) => void
  onCreateClicked: () => void
}) => {
  const [viewData, input] = useItemList({ teamKey, isSelecting: true })
  const itemListItems = viewData?.items?.asJsReadonlyArrayView()
  return (
    <NavigationBarDialog
      isOpen={isOpen}
      onClose={() => {
        onItemSelected(null)
        input?.changeSearchQuery("")
      }}
      title={viewData?.title}
      barItems={
        <>
          <SearchBar
            placeholder={viewData?.searchHint}
            onChange={(text: string) => {
              input?.changeSearchQuery(text)
            }}
            autoFocus={true}
          />
          <CreateButton
            text={viewData?.createButtonText}
            onClick={onCreateClicked}
          />
        </>
      }
    >
      <ListDialogContent emptyViewData={viewData?.empty} emptyIcon={ListIcon}>
        {itemListItems?.map((listItem) => {
          return (
            <ListItemButton
              key={listItem.itemKey}
              divider
              onClick={() => {
                onItemSelected(listItem.itemKey)
              }}
            >
              <StyledBox px={1}>
                <ListItemText>{listItem.name.text}</ListItemText>
                <ListItemText sx={{ textAlign: "right" }}>
                  {listItem.rate.text}
                </ListItemText>
              </StyledBox>
            </ListItemButton>
          )
        })}
      </ListDialogContent>
    </NavigationBarDialog>
  )
}

export default ItemListDialog
