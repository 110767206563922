import { Box, IconButton, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import { grey } from "@mui/material/colors"
import { styled } from "@mui/material/styles"

import * as shared from "probuild-shared"

import paths from "model/utils/paths"
import { Delete } from "@mui/icons-material"

const RootDiv = styled("div")({
  width: "100%",
})

const StyledDiv = styled("div")({
  maxWidth: 450,
  margin: "0 auto",
})

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  minWidth: 450,
  backgroundColor: grey[200],
  borderRadius: theme.spacing(1),
  [`&:hover`]: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    borderRadius: theme.spacing(1),
  },
}))

const StyledLink = styled(Link)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  color: "inherit",
  textDecoration: "inherit",
  alignSelf: "center",
  height: "fit-content",
  width: "100%",
  [`&:hover`]: {
    backgroundColor: "inherit",
    borderRadius: "inherit",
  },
}))

const DeleteBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: theme.palette.primary.light,
  borderTopRightRadius: theme.spacing(1),
  borderBottomRightRadius: theme.spacing(1),
}))

const PostAttachmentDocumentView = ({
  teamKey,
  viewData,
  onDelete,
}: {
  teamKey: string
  viewData: shared.com.probuildsoftware.probuild.library.projects.data.view.PostAttachmentDocumentViewData
  onDelete: ((attachmentKey: string) => void) | null
}) => {
  const summary = viewData.summary
  if (summary) {
    const documentPath = paths.document(teamKey, summary.documentKey)
    return (
      <RootDiv>
        <StyledDiv>
          <StyledBox>
            <StyledLink to={documentPath}>
              <Box sx={{ px: 2, py: 1 }}>
                <Typography fontWeight={"bold"}>
                  {viewData.summary?.name}
                </Typography>
                <Typography>{viewData.summary?.emailStatus}</Typography>
              </Box>
              <Box sx={{ px: 2, py: 1, ml: "auto" }}>
                <Typography>{viewData.summary?.total}</Typography>
              </Box>

              {onDelete && (
                <DeleteBox>
                  <IconButton
                    onClick={() => onDelete(viewData.attachmentKey)}
                    sx={{ color: "white", alignSelf: "center" }}
                  >
                    <Delete />
                  </IconButton>
                </DeleteBox>
              )}
            </StyledLink>
          </StyledBox>
        </StyledDiv>
      </RootDiv>
    )
  } else {
    return <></>
  }
}

export default PostAttachmentDocumentView
