import { css, Global } from "@emotion/react"
import { useCallback, useEffect, useState } from "react"

import * as report from "app-web-report"
import * as shared from "probuild-shared"

import PaperArialRegular from "fonts/PaperArialRegular"
import PaperArialBold from "fonts/PaperArialBold"
import LoadingView from "components/views/LoadingView"

const DocumentReportView = ({
  onReportLoaded,
  reportData,
}: {
  onReportLoaded?: () => void
  reportData: shared.com.probuildsoftware.probuild.library.documents.data.view.DocumentReportViewData | null
}) => {
  const [isReportLoaded, setIsReportLoaded] = useState(false)
  const callbackRef = useCallback(
    (element: HTMLDivElement | null) => {
      if (element && reportData) {
        report.render(element, reportData, () => {
          setIsReportLoaded(true)
        })
      }
    },
    [reportData]
  )
  useEffect(() => {
    if (isReportLoaded && onReportLoaded) {
      onReportLoaded()
    }
  }, [isReportLoaded, onReportLoaded])
  return (
    <div>
      {!isReportLoaded && <LoadingView />}
      <div
        style={{
          transition: "opacity 0.5s",
          opacity: isReportLoaded ? 1 : 0,
        }}
      >
        <Global
          styles={css`
            @font-face {
              font-family: PaperArial;
              src: ${`url(data:font/truetype;charset=utf8;base64,${PaperArialRegular.base64})`};
            }
            @font-face {
              font-family: PaperArial;
              font-weight: bold;
              src: ${`url(data:font/truetype;charset=utf8;base64,${PaperArialBold.base64})`};
            }
            @page {
              margin: 0;
            }
          `}
        />
        <div ref={callbackRef}></div>
      </div>
    </div>
  )
}

export default DocumentReportView
