import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"

import * as shared from "probuild-shared"

import LabeledCard from "components/views/generic/LabeledCard"
import LoadingView from "components/views/LoadingView"

const UserInviteView = ({
  viewData,
  input,
}: {
  viewData: shared.com.probuildsoftware.probuild.library.users.data.view.UserInviteViewData
  input: shared.com.probuildsoftware.probuild.library.users.UserInviteInput | null
}) => {
  const copyInviteMessage = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(viewData.action?.messageContent || "")
      input?.markCopyAttempted()
    }
  }
  return (
    <>
      <LabeledCard text={""}>
        <Typography>{viewData.body}</Typography>
        {(viewData.action && (
          <TextField
            value={viewData.action?.messageContent}
            multiline
            onFocus={copyInviteMessage}
            InputProps={{
              readOnly: true,
              endAdornment: navigator.clipboard && (
                <InputAdornment position="end">
                  <IconButton onClick={copyInviteMessage}>
                    <ContentCopyIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            variant="outlined"
          ></TextField>
        )) || <LoadingView />}
      </LabeledCard>
    </>
  )
}

export default UserInviteView
