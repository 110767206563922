import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material"

import * as shared from "probuild-shared"

import LabeledCard from "components/views/generic/LabeledCard"
import LoadingModal from "components/views/generic/LoadingModal"
import ViewDataTextField from "components/views/generic/ViewDataTextField"
import DateSelector from "components/views/generic/DateSelector"
import usePromptSafe from "model/navigation/usePromptSafe"
import { NavigateSafeState } from "model/navigation/useNavigateSafe"

const PaymentEditView = ({
  viewData,
  input,
  navigateSafeState,
}: {
  viewData: shared.com.probuildsoftware.probuild.library.documents.data.view.PaymentEditViewData | null
  input: shared.com.probuildsoftware.probuild.library.documents.PaymentEditInput | null
  navigateSafeState: NavigateSafeState
}) => {
  usePromptSafe({
    when: viewData?.shouldConfirmDiscard === true,
    message: viewData?.discardConfirmationDialog.message || "",
    navigateSafeState: navigateSafeState,
  })
  if (!input || !viewData) return null
  const methods = viewData.methodSelectionList?.items?.asJsReadonlyArrayView()
  const selectedMethodKey = methods?.find((method) => method.selected)?.itemKey
  return (
    <>
      <Typography sx={{ mt: 2 }} variant="h4" textAlign="center">
        {viewData.outstandingAmount}
      </Typography>
      <Typography variant="body1" textAlign="center">
        {viewData.outstandingLabel}
      </Typography>
      <LabeledCard text={""}>
        <ViewDataTextField
          viewData={viewData.amount}
          onChange={(text) => input?.changeAmount(text)}
        />
        <FormControl>
          <InputLabel variant="standard" shrink={true}>
            {viewData.method.label}
          </InputLabel>
          <Select
            value={selectedMethodKey}
            label={viewData.method.label}
            variant="standard"
            onChange={(event) => {
              input?.changeMethod(event.target.value)
            }}
          >
            {methods?.map((method) => {
              return (
                <MenuItem key={method.itemKey} value={method.itemKey}>
                  {method.itemName}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
        <DateSelector
          isoDate={viewData.paidAt.dateIso}
          label={viewData.paidAt.label}
          onDateChange={(newIsoDate) => {
            input?.changePaidAt(newIsoDate)
          }}
        />
        <ViewDataTextField
          viewData={viewData.notes}
          onChange={(text) => input?.changeNotes(text)}
        />
      </LabeledCard>
      <LoadingModal
        open={viewData.isSaveInProgress || viewData.isDeleteInProgress}
      />
    </>
  )
}

export default PaymentEditView
