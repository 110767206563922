import {
  Chip,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Stack,
} from "@mui/material"
import { ErrorOutline, OpenInNew } from "@mui/icons-material"
import { Link as RouterLink } from "react-router-dom"

import * as shared from "probuild-shared"

import paths from "model/utils/paths"
import LabeledSectionCard from "components/views/generic/LabeledSectionCard"
import EmptyView from "components/views/EmptyView"

const SubscriptionOrders = ({
  teamKey,
  viewData,
}: {
  teamKey: string
  viewData: shared.com.probuildsoftware.probuild.library.subscriptions.data.view.orders.OrdersViewData | null
}) => {
  const orders = viewData?.orders?.asJsReadonlyArrayView() || []
  return (
    viewData && (
      <LabeledSectionCard label={viewData.subtitle}>
        {viewData.empty && <EmptyView icon={null} viewData={viewData.empty} />}
        <List>
          {orders.map((order, index) => {
            return (
              <ListItem
                key={order.orderKey}
                secondaryAction={
                  order.externalLink && (
                    <IconButton
                      component={Link}
                      href={order.externalLink}
                      target="_blank"
                    >
                      <OpenInNew />
                    </IconButton>
                  )
                }
                divider={index < orders.length - 1}
              >
                <ListItemText
                  sx={{ maxWidth: 150 }}
                  primary={order.startDate}
                  secondary={order.startTime}
                />
                <ListItemText
                  primary={
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={1}
                    >
                      {order.name}
                      <span />
                      <Chip
                        variant="outlined"
                        size="small"
                        color={statusChipColor(order)}
                        label={order.statusText}
                      />
                      {order.isLastVerifyFailed && (
                        <ErrorOutline color="error" fontSize="small" />
                      )}
                    </Stack>
                  }
                  secondary={
                    <Stack
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="start"
                    >
                      <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={0.5}
                      >
                        {order.createdByLabel}
                        <span />
                        {createdByLink(
                          teamKey,
                          order.createdByUserKey || null,
                          order.createdByName
                        )}
                      </Stack>
                      <span />
                      {order.expiryDetails}
                    </Stack>
                  }
                />
              </ListItem>
            )
          })}
        </List>
      </LabeledSectionCard>
    )
  )
}

const createdByLink = (
  teamKey: string,
  userKey: string | null,
  userName: string
) => {
  if (userKey) {
    return (
      <Link component={RouterLink} to={paths.userEdit(teamKey, userKey)}>
        {userName}
      </Link>
    )
  } else {
    return <span>{userName}</span>
  }
}

const statusChipColor = (
  order: shared.com.probuildsoftware.probuild.library.subscriptions.data.view.orders.OrderItemViewData
) => {
  if (order.isStatusSuccess) {
    return "success"
  } else if (order.isStatusError) {
    return "error"
  } else {
    return "default"
  }
}

export default SubscriptionOrders
