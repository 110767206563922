import { ReactNode } from "react"
import { Card, Typography } from "@mui/material"

const LabeledSectionCard = ({
  label,
  children,
}: {
  label: string | null | undefined
  children: ReactNode
}) => {
  return (
    <div>
      <Typography sx={{ p: 1 }} variant="h6">
        {label}
      </Typography>
      <Card variant="outlined">{children}</Card>
      <br />
    </div>
  )
}

export default LabeledSectionCard
