import { styled } from "@mui/material/styles"

import * as shared from "probuild-shared"

import ImageView from "components/views/generic/ImageView"
import ImageInput from "components/views/generic/ImageInput"

const RootDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
})

const StyledImageView = styled(ImageView)({
  width: "800px",
  height: "400px",
  objectFit: "contain",
})

const Summary = ({
  documentAnswerKey,
  userKey,
  element,
  input,
}: {
  documentAnswerKey: string
  userKey: string
  element: shared.com.probuildsoftware.probuild.library.documents.data.view.elements.ElementViewData
  input: shared.com.probuildsoftware.probuild.library.documents.DocumentInput
}) => {
  const file = element.files?.asJsReadonlyArrayView()?.find((x) => true) || null
  const onFileChanged = (
    file: shared.com.probuildsoftware.probuild.library.common.data.files.InputFile
  ) => {
    input.addFile(documentAnswerKey, element.elementKey, userKey, file)
  }
  if (file) {
    return (
      <RootDiv>
        <StyledImageView
          file={file}
          alt={element.labelText || ""}
          width={520}
        />
      </RootDiv>
    )
  } else {
    return (
      <RootDiv>
        <ImageInput onFileChanged={onFileChanged} />
      </RootDiv>
    )
  }
}

export default Summary
