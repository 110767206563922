import { useMemo } from "react"

import * as shared from "probuild-shared"

import useDependencyProvider from "model/utils/useDependencyProvider"
import usePresenter from "model/presenter/usePresenter"
import { useParams } from "react-router-dom"

function useAccountsList(): [
  shared.com.probuildsoftware.probuild.library.app.data.view.AccountsListViewData | null,
  shared.com.probuildsoftware.probuild.library.app.AccountsListInput | null
] {
  const { teamKey } = useParams()
  const dependencyProvider = useDependencyProvider()
  const presenter = useMemo(() => {
    if (dependencyProvider) {
      console.log(`Creating accounts list presenter teamKey = ${teamKey}`)
      return new shared.com.probuildsoftware.probuild.library.app.AccountsListPresenter(
        dependencyProvider,
        {
          teamKey: teamKey as string,
        }
      )
    }
  }, [teamKey, dependencyProvider])
  return usePresenter(presenter)
}

export default useAccountsList
