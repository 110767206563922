import { useMemo } from "react"

import * as shared from "probuild-shared"

import useDependencyProvider from "model/utils/useDependencyProvider"
import useAppUser from "model/utils/useAppUser"
import usePresenter from "model/presenter/usePresenter"

function useEmailComposerViewData({
  teamKey,
  documentKey,
  observer,
}: {
  teamKey: string
  documentKey: string | undefined
  observer: shared.com.probuildsoftware.probuild.library.documents.EmailComposerEventObserver
}): [
  shared.com.probuildsoftware.probuild.library.documents.data.view.EmailComposerViewData | null,
  shared.com.probuildsoftware.probuild.library.documents.EmailComposerInput | null
] {
  const appUser = useAppUser({ teamKey })
  const dependencyProvider = useDependencyProvider()
  const presenter = useMemo(() => {
    if (dependencyProvider && appUser && documentKey) {
      return new shared.com.probuildsoftware.probuild.library.documents.EmailComposerPresenter(
        dependencyProvider,
        appUser,
        {
          documentKey,
        }
      )
    }
  }, [appUser, documentKey, dependencyProvider])
  return usePresenter(presenter, observer)
}

export default useEmailComposerViewData
