import shared from "probuild-shared"
import { getToken } from "firebase/app-check"
import appCheck from "model/app/firebaseAppCheck"

class LiveAppCheckApi
  implements
    shared.com.probuildsoftware.probuild.library.common.model.appcheck.api
      .AppCheckApi
{
  getAppCheckToken(completion: (token: string | null) => void) {
    getToken(appCheck, false)
      .then((response) => {
        completion(response.token)
      })
      .catch((e) => {
        completion(null)
      })
  }

  shouldPerformAppCheck(): boolean {
    if (!process.env.REACT_APP_PROBUILD_WEBSITE_APP_URL) return false
    const isLiveDeployment = window.location.href.startsWith(
      process.env.REACT_APP_PROBUILD_WEBSITE_APP_URL
    )
    return isLiveDeployment
  }
}

export default LiveAppCheckApi
