import { unstable_usePrompt as usePrompt } from "react-router-dom"
import { NavigateSafeState } from "model/navigation/useNavigateSafe"

const usePromptSafe = ({
  when,
  message,
  navigateSafeState,
}: {
  when: boolean
  message: string | null | undefined
  navigateSafeState: NavigateSafeState
}) => {
  usePrompt({
    when: when && !navigateSafeState.isNavigating,
    message: message || "",
  })
}

export default usePromptSafe
