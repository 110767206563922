import { Link } from "@mui/material"

const LinkToExternalSite = ({
  title,
  path,
  className,
}: {
  title: string
  path: string
  className?: string
}) => {
  return (
    <Link underline="none" target="_blank" href={path} className={className}>
      {title}
    </Link>
  )
}

export default LinkToExternalSite
