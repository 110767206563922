import {
  getMetadata,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage"

import * as shared from "probuild-shared"

import BufferEncryptionResult from "model/crypto/BufferEncryptionResult"
import BufferEncryptionOperation from "model/crypto/BufferEncryptionOperation"

class LocalFile {
  constructor(
    readonly devicePath: string,
    readonly filename: string,
    readonly contentType: string | null | undefined
  ) {}
}

class LiveFileApi
  implements
    shared.com.probuildsoftware.probuild.library.common.model.files.FileApi
{
  private observerMap = new Map<
    string,
    Set<shared.com.probuildsoftware.probuild.library.common.model.files.FileSummaryObserver>
  >()
  private localFiles = new Map<string, LocalFile>()
  private generations = new Map<string, string>()
  private uploadProgress = new Map<string, number>()

  onFileSummary(
    path: string,
    observer: shared.com.probuildsoftware.probuild.library.common.model.files.FileSummaryObserver
  ): () => void {
    if (!this.observerMap.has(path)) {
      this.observerMap.set(path, new Set())
    }
    this.observerMap.get(path)?.add(observer)
    this.notifyFileSummaryUpdated(path)
    return () => {
      this.observerMap.get(path)?.delete(observer)
    }
  }

  onFileDeleteLocal(
    storagePath: string,
    completion: (success: boolean) => void
  ) {
    this.localFiles.delete(storagePath)
    this.generations.delete(storagePath)
    this.notifyFileSummaryUpdated(storagePath)
    completion(true)
  }

  onFileDeleteDevice(
    devicePath: string,
    completion: (success: boolean) => void
  ) {
    completion(true)
  }

  onFileDownload(storagePath: string) {
    this.localFiles.delete(storagePath)
    this.fetchGeneration(storagePath)
  }

  onFileSaveLocal(
    storagePath: string,
    devicePath: string,
    filename: string,
    contentType: string | null | undefined,
    teamAesSecret: string | null | undefined,
    completion: (success: boolean) => void
  ) {
    this.localFiles.set(
      storagePath,
      new LocalFile(devicePath, filename, contentType)
    )
    this.generations.delete(storagePath)
    this.notifyFileSummaryUpdated(storagePath)
    completion(true)
  }

  async onFileUpload(
    storagePath: string,
    teamAesSecret: string | null,
    completion: (success: boolean) => void
  ) {
    const localFile = this.localFiles.get(storagePath)
    const devicePath = localFile?.devicePath
    const filename = localFile?.filename
    const contentType = localFile?.contentType
    if (filename == null || devicePath == null) {
      completion(false)
      return
    }
    const localBlob = await this.fetchLocalBlob(devicePath)
    const metadata: { [key: string]: string } = {
      displayName: filename,
    }
    if (contentType) {
      metadata.contentType = contentType
    }
    if (teamAesSecret) {
      const encryptionResult = await this.encrypt(localBlob, teamAesSecret)
      metadata.ivData = encryptionResult.iv
      const uploadSuccess = await this.upload(
        storagePath,
        encryptionResult.data,
        metadata
      )
      completion(uploadSuccess)
    } else {
      const rawData = await localBlob.arrayBuffer()
      const uploadSuccess = await this.upload(storagePath, rawData, metadata)
      completion(uploadSuccess)
    }
  }

  fetchImageMetadata(
    devicePath: string,
    completion: (
      metadata: shared.com.probuildsoftware.probuild.library.common.model.files.FileImageMetadata | null
    ) => void
  ) {
    const image = new Image()
    image.onload = () => {
      completion({
        width: image.width,
        height: image.height,
      })
    }
    image.src = devicePath
  }

  private async upload(
    storagePath: string,
    data: ArrayBuffer,
    customMetadata: { [key: string]: string }
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const uploadTask = uploadBytesResumable(
        ref(getStorage(), storagePath),
        data,
        {
          customMetadata: customMetadata,
        }
      )
      this.uploadProgress.set(storagePath, 0)
      const cleanUp = uploadTask.on("state_changed", {
        next: (snapshot) => {
          const progress = snapshot.bytesTransferred / snapshot.totalBytes
          this.uploadProgress.set(storagePath, progress)
          this.notifyFileSummaryUpdated(storagePath)
        },
        error: () => {
          this.uploadProgress.delete(storagePath)
          this.notifyFileSummaryUpdated(storagePath)
          cleanUp()
          resolve(false)
        },
        complete: () => {
          this.uploadProgress.delete(storagePath)
          this.generations.set(
            storagePath,
            uploadTask.snapshot.metadata.generation
          )
          this.notifyFileSummaryUpdated(storagePath)
          cleanUp()
          resolve(true)
        },
      })
    })
  }

  private async fetchLocalBlob(devicePath: string): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.open("GET", devicePath)
      xhr.responseType = "blob"
      xhr.addEventListener("load", () => {
        resolve(xhr.response as Blob)
      })
      xhr.addEventListener("error", () => {
        reject()
      })
      xhr.addEventListener("abort", () => {
        reject()
      })
      xhr.send()
    })
  }

  private notifyFileSummaryUpdated(storagePath: string) {
    const fileSummary = this.createFileSummary(storagePath)
    this.observerMap.get(storagePath)?.forEach((observer) => {
      observer.onFileSummaryUpdated(fileSummary)
    })
  }

  private createFileSummary(
    storagePath: string
  ): shared.com.probuildsoftware.probuild.library.common.model.files.FileSummary {
    const uploadProgress = this.uploadProgress.get(storagePath)
    return {
      path: storagePath,
      generation: this.generations.get(storagePath),
      devicePath: null,
      onDevice:
        this.generations.has(storagePath) || this.localFiles.has(storagePath),
      downloading: false,
      uploading: uploadProgress ? true : false,
      progress: uploadProgress || null,
      lastModifiedAt: null,
      sizeInBytes: null,
    }
  }

  private async encrypt(
    blob: Blob,
    teamAesSecret: string
  ): Promise<BufferEncryptionResult> {
    const arrayBuffer = await blob.arrayBuffer()
    const operation = new BufferEncryptionOperation(arrayBuffer, teamAesSecret)
    return await operation.start()
  }

  private async fetchGeneration(storagePath: string) {
    try {
      const metadata = await getMetadata(ref(getStorage(), storagePath))
      this.generations.set(storagePath, metadata.generation)
      this.notifyFileSummaryUpdated(storagePath)
    } catch (error) {
      console.log(`Failed to fetch metadata for path: ${storagePath}`)
    }
  }
}

export default LiveFileApi
