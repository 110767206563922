import { styled } from "@mui/material/styles"
import { CircularProgress, CSSObject } from "@mui/material"
import { grey } from "@mui/material/colors"
import CloudUpload from "@mui/icons-material/CloudUpload"
import CloudDownload from "@mui/icons-material/CloudDownload"

import * as shared from "probuild-shared"

const StyledCircularProgress = styled(CircularProgress)({
  position: "absolute",
  top: "5px",
  left: "5px",
})

const cloudStyle: CSSObject = {
  position: "absolute",
  top: "18px",
  left: "18px",
  color: grey[50],
}

const StyledCloudUpload = styled(CloudUpload)(cloudStyle)

const StyledCloudDownload = styled(CloudDownload)(cloudStyle)

const RootDiv = styled("div")({
  backgroundColor: "#FFFFFFAA",
  borderRadius: "10px",
  width: "60px",
  height: "60px",
  position: "relative",
})

const FileProgressView = ({
  progress,
}: {
  progress:
    | shared.com.probuildsoftware.probuild.library.common.data.view.FileProgressViewData
    | null
    | undefined
}) => {
  if (!progress) {
    return null
  }
  return (
    <RootDiv>
      <StyledCircularProgress
        size={50}
        sx={{ color: grey[50] }}
        variant="determinate"
        value={100}
      />
      <StyledCircularProgress
        size={50}
        color="primary"
        variant="determinate"
        value={progress?.progress || 0}
      />
      {progress?.uploading && <StyledCloudUpload />}
      {progress?.downloading && <StyledCloudDownload />}
    </RootDiv>
  )
}

export default FileProgressView
