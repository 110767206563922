import { useCallback, useEffect, useState } from "react"

interface DownloadableFileInfo {
  content: string
  contentType: string
  filename: string
}

function useDownloadFile() {
  const [info, setInfo] = useState<DownloadableFileInfo | null>(null)
  useEffect(() => {
    if (info) {
      const anchorElement = document.createElement("a")
      const blob = new Blob([info.content], { type: info.contentType })
      const objectUrl = URL.createObjectURL(blob)
      anchorElement.href = objectUrl
      anchorElement.download = info.filename
      document.body.appendChild(anchorElement)
      anchorElement.click()
      anchorElement.parentNode?.removeChild(anchorElement)
      return () => {
        URL.revokeObjectURL(objectUrl)
      }
    }
  }, [info])
  return useCallback(
    (filename: string, content: string, contentType: string) => {
      setInfo({
        filename,
        content,
        contentType,
      })
    },
    []
  )
}

export default useDownloadFile
