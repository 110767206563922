import React from "react"

import * as shared from "probuild-shared"
import createInputFiles from "model/utils/createInputFiles"

const FileInput = ({
  onFilesChanged,
  hidden,
  inputRef,
  multiple,
}: {
  onFilesChanged: (
    files: shared.com.probuildsoftware.probuild.library.common.data.files.InputFile[]
  ) => void
  hidden?: boolean
  inputRef?: React.RefObject<HTMLInputElement>
  multiple?: boolean
}) => {
  return (
    <input
      style={{ display: hidden === true ? "none" : undefined }}
      type="file"
      ref={inputRef}
      accept="*"
      multiple={multiple}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return
        const files = createInputFiles(event.target.files)
        onFilesChanged(files)
        const currentInput = inputRef?.current
        if (currentInput) {
          currentInput.value = ""
        }
      }}
    />
  )
}

export default FileInput
