import { useMemo } from "react"

import * as shared from "probuild-shared"

import useDependencyProvider from "model/utils/useDependencyProvider"
import usePresenter from "model/presenter/usePresenter"
import useAppUser from "model/utils/useAppUser"

const usePhoneNumberChangeAdd = ({
  teamKey,
  userKey,
  observer,
}: {
  teamKey: string
  userKey: string
  observer: shared.com.probuildsoftware.probuild.library.users.PhoneNumberChangeAddEventObserver
}): [
  shared.com.probuildsoftware.probuild.library.users.data.view.PhoneNumberChangeAddViewData | null,
  shared.com.probuildsoftware.probuild.library.users.PhoneNumberChangeAddInput | null
] => {
  const dependencyProvider = useDependencyProvider()
  const appUser = useAppUser({ teamKey })
  const presenter = useMemo(() => {
    if (dependencyProvider && appUser) {
      console.log(
        `Creating PhoneNumberChangeAddPresenter teamKey = ${teamKey} userKey = ${userKey}`
      )
      return new shared.com.probuildsoftware.probuild.library.users.PhoneNumberChangeAddPresenter(
        dependencyProvider,
        null,
        appUser,
        {
          userKey,
        }
      )
    }
  }, [teamKey, userKey, appUser, dependencyProvider])
  return usePresenter(presenter, observer)
}

export default usePhoneNumberChangeAdd
