import { styled } from "@mui/material/styles"
import {
  Button,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material"
import { CheckCircleOutline, Payments } from "@mui/icons-material"
import { Box } from "@mui/system"

import * as shared from "probuild-shared"

import NavigationBarDialog from "components/dialogs/NavigationBarDialog"
import usePaymentListViewData from "model/documents/payments/usePaymentListViewData"
import ListDialogContent from "components/dialogs/ListDialogContent"

const StyledBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
})

const VerticalStackDiv = styled("div")({
  width: "100%",
  display: "flex",
  flexDirection: "column",
})

const StatusBarDiv = styled("div")(({ theme }) => ({
  display: "grid",
  width: "100%",
  height: "44px",
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.secondary.main,
}))

const PaidPercentDiv = styled("div")(({ theme }) => ({
  height: "44px",
  backgroundColor: theme.palette.success.light,
  gridColumn: 1,
  gridRow: 1,
}))

const NotesListItemText = styled(ListItemText)(({ theme }) => ({
  whiteSpace: "pre-wrap",
  overflow: "hidden",
  textAlign: "left",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 4,
  WebkitBoxOrient: "vertical",
  color: theme.palette.secondary.main,
}))

const PaymentListDialog = ({
  teamKey,
  documentKey,
  isOpen,
  onClose,
  onPaymentSelected,
  onCreateClicked,
}: {
  teamKey: string
  documentKey: string | undefined
  isOpen: boolean
  onClose: () => void
  onPaymentSelected: (paymentKey: string) => void
  onCreateClicked: () => void
}) => {
  const viewData = usePaymentListViewData({ teamKey, documentKey })
  if (!viewData) return null
  const listItems = viewData.items?.asJsReadonlyArrayView()
  return (
    <NavigationBarDialog
      isOpen={isOpen}
      onClose={onClose}
      title={viewData.title}
      barItems={
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            onCreateClicked()
          }}
        >
          {viewData.recordPaymentButtonText}
        </Button>
      }
    >
      <PaymentListHeader viewData={viewData.header} />
      <ListDialogContent emptyViewData={viewData.empty} emptyIcon={Payments}>
        {listItems?.map((listItem) => {
          return (
            <PaymentListItem
              viewData={listItem}
              onPaymentSelected={onPaymentSelected}
            />
          )
        })}
      </ListDialogContent>
    </NavigationBarDialog>
  )
}

const PaymentListItem = ({
  viewData,
  onPaymentSelected,
}: {
  viewData: shared.com.probuildsoftware.probuild.library.documents.data.view.PaymentListItemViewData
  onPaymentSelected: (paymentKey: string) => void
}) => {
  return (
    <ListItem
      key={viewData.paymentKey}
      button
      divider
      onClick={() => {
        onPaymentSelected(viewData.paymentKey)
      }}
    >
      <VerticalStackDiv>
        <StyledBox px={1}>
          <ListItemText>{viewData.amount.text}</ListItemText>
          <ListItemText sx={{ textAlign: "right" }}>
            {viewData.paidAtFormatted.text}
          </ListItemText>
        </StyledBox>
        <StyledBox px={1}>
          <ListItemText>{viewData.method.text}</ListItemText>
        </StyledBox>
        {viewData.notes.text && viewData.notes.text.length > 0 && (
          <StyledBox px={1}>
            <NotesListItemText>{viewData.notes.text}</NotesListItemText>
          </StyledBox>
        )}
      </VerticalStackDiv>
    </ListItem>
  )
}

const PaymentListHeader = ({
  viewData,
}: {
  viewData: shared.com.probuildsoftware.probuild.library.documents.data.view.PaymentListHeaderViewData
}) => {
  return (
    <Paper sx={{ backgroundColor: "background.paper", p: 3 }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: 8,
        }}
      >
        {viewData.isFullyPaid && (
          <CheckCircleOutline
            sx={{ fontSize: "56px", color: "success.light" }}
          />
        )}
        <Typography
          variant="h5"
          color={viewData.isFullyPaid ? "success.light" : undefined}
        >
          {viewData.title}
        </Typography>
        <Typography variant="body1" color={"secondary.main"}>
          {viewData.subtitle}
        </Typography>
      </div>
      <StatusBarDiv>
        <PaidPercentDiv
          style={{
            width: `${viewData.paidPercent}%`,
          }}
        />
        <div
          style={{
            gridColumn: 1,
            gridRow: 1,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span style={{ color: "primary.contrastText" }}>
            {viewData.paidProgressText}
          </span>
        </div>
      </StatusBarDiv>
    </Paper>
  )
}

export default PaymentListDialog
