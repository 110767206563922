import { useEffect } from "react"
import { Avatar, Box, Button, IconButton } from "@mui/material"
import { styled } from "@mui/material/styles"
import { Delete } from "@mui/icons-material"
import { useInView } from "react-intersection-observer"

import * as shared from "probuild-shared"

import ImageView from "components/views/generic/ImageView"

const StyledImageView = styled(ImageView)({
  width: "100%",
  height: "100%",
})

const DeletePhotoBox = styled(Box)({
  position: "relative",
  boxSizing: "border-box",
  width: "100%",
  height: "100%",
  padding: 1,
})

const ImageButton = styled(Button)({
  boxSizing: "border-box",
  width: "100%",
  height: "100%",
  padding: 1,
})

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  borderStyle: "solid",
  borderColor: "white",
}))

const PostAttachmentPhotoView = ({
  viewData,
  onDelete,
  onPhotoClicked,
  onPhotoViewed,
}: {
  viewData: shared.com.probuildsoftware.probuild.library.projects.data.view.PostAttachmentPhotoViewData
  onDelete: ((attachmentKey: string) => void) | null
  onPhotoClicked: () => void
  onPhotoViewed: () => void
}) => {
  const { ref, inView } = useInView({})
  useEffect(() => {
    if (inView) {
      onPhotoViewed()
    }
  }, [onPhotoViewed, inView])
  const file = viewData.file
  if (!file) return <></>
  const image = (
    <StyledImageView
      fadeIn={true}
      file={file}
      alt={"Photo"}
      width={804}
      height={534}
      cover={true}
    />
  )
  if (onDelete) {
    return (
      <DeletePhotoBox ref={ref}>
        {image}
        <IconButton
          onClick={() => onDelete(viewData.attachmentKey)}
          sx={{ color: "white", position: "absolute", top: 0, right: 0 }}
        >
          <StyledAvatar>
            <Delete />
          </StyledAvatar>
        </IconButton>
      </DeletePhotoBox>
    )
  } else {
    return (
      <ImageButton
        ref={ref}
        onClick={onPhotoClicked}
        disabled={onDelete != null}
      >
        {image}
      </ImageButton>
    )
  }
}

export default PostAttachmentPhotoView
