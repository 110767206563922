import { useMemo } from "react"

import * as shared from "probuild-shared"

import useDependencyProvider from "model/utils/useDependencyProvider"
import useAppUser from "model/utils/useAppUser"
import usePresenter from "model/presenter/usePresenter"

const useUserList = ({
  teamKey,
  isSelecting,
}: {
  teamKey: string
  isSelecting: boolean
}): [
  shared.com.probuildsoftware.probuild.library.users.data.view.UserListViewData | null,
  shared.com.probuildsoftware.probuild.library.users.UserListInput | null
] => {
  const appUser = useAppUser({ teamKey })
  const dependencyProvider = useDependencyProvider()
  const presenter = useMemo(() => {
    if (dependencyProvider && appUser) {
      console.log(
        `Creating user list presenter teamKey = ${appUser.teamKey} userKey = ${appUser.userKey}`
      )
      return new shared.com.probuildsoftware.probuild.library.users.UserListPresenter(
        dependencyProvider,
        appUser,
        {
          isSelecting,
        }
      )
    }
  }, [appUser, isSelecting, dependencyProvider])
  return usePresenter(presenter)
}

export default useUserList
