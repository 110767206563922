import * as forge from "node-forge"

class BufferDecryptionOperation {
  private readonly ENCODING = "utf8"
  private readonly CHUNK_SIZE = 1024 * 64

  private readonly iv: string
  private readonly aesSecretBytes: forge.util.ByteBuffer
  private readonly decipher: forge.cipher.BlockCipher
  private readonly arrayBuffer: ArrayBuffer
  private readonly length: number
  private resolve: ((value: Uint8Array) => void) | null
  private decryptedBytes: string
  private index: number

  constructor(
    base64EncodedIv: string,
    arrayBuffer: ArrayBuffer,
    aesSecret: string
  ) {
    this.iv = forge.util
      .createBuffer(forge.util.decode64(base64EncodedIv), this.ENCODING)
      .getBytes()
    this.arrayBuffer = arrayBuffer
    this.aesSecretBytes = forge.util.createBuffer(
      forge.util.decode64(aesSecret),
      this.ENCODING
    )
    this.decipher = forge.cipher.createDecipher("AES-CBC", this.aesSecretBytes)
    this.length = arrayBuffer.byteLength
    this.decryptedBytes = ""
    this.index = 0
    this.resolve = null
  }

  public start(): Promise<Uint8Array> {
    const promise = new Promise<Uint8Array>((resolve, reject) => {
      this.resolve = resolve
      this.decipher.start({ iv: this.iv })
      this.process()
    })
    return promise
  }

  private process() {
    if (this.index < this.length) {
      this.decryptedBytes += this.decipher.output.getBytes()
      const chunk = new forge.util.ByteStringBuffer(
        this.arrayBuffer.slice(this.index, this.index + this.CHUNK_SIZE)
      )
      this.decipher.update(chunk)
      this.index += this.CHUNK_SIZE
      setTimeout(() => this.process(), 50)
    } else {
      this.decipher.finish()
      this.decryptedBytes += this.decipher.output.getBytes()
      const decrypted = forge.util.binary.raw.decode(this.decryptedBytes)
      if (this.resolve != null) {
        this.resolve(decrypted)
      }
    }
  }
}

export default BufferDecryptionOperation
