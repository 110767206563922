import { styled } from "@mui/material/styles"
import { Typography } from "@mui/material"
import { SvgIconComponent } from "@mui/icons-material"
import SearchIcon from "@mui/icons-material/Search"

import * as shared from "probuild-shared"

const CenteringDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  color: theme.palette.secondary.main,
  gap: theme.spacing(2),
  margin: theme.spacing(4),
}))

const iconSize = 50

const CircleDiv = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  borderRadius: iconSize,
  width: iconSize * 2,
  height: iconSize * 2,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}))

const EmptyView = ({
  icon,
  viewData,
}: {
  icon: SvgIconComponent | null
  viewData:
    | shared.com.probuildsoftware.probuild.library.common.data.view.EmptyViewData
    | undefined
    | null
}) => {
  if (!viewData) {
    return null
  }
  const Icon = viewData.isSearching ? SearchIcon : icon
  return (
    <CenteringDiv>
      {Icon && (
        <CircleDiv>
          <Icon sx={{ fontSize: iconSize, color: "primary.contrastText" }} />
        </CircleDiv>
      )}
      {viewData.title && <Typography variant="h5">{viewData.title}</Typography>}
      {viewData.subtitle && (
        <Typography variant="body1">{viewData.subtitle}</Typography>
      )}
    </CenteringDiv>
  )
}

export default EmptyView
