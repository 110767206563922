import { useMemo } from "react"
import { useParams } from "react-router-dom"

import * as shared from "probuild-shared"

import TeamPageLayout from "components/views/layouts/TeamPageLayout"
import useUserProfileEdit from "model/users/profile/useUserProfileEdit"
import useUserProfilePreview from "model/users/profile/useUserProfilePreview"
import OverflowMenu from "components/views/generic/OverflowMenu"
import DeleteMenuItem from "components/views/generic/DeleteMenuItem"
import SaveButton from "components/views/generic/SaveButton"
import UserProfileEditView from "components/views/users/profile/UserProfileEditView"
import useSnackbar from "model/snackbar/useSnackbar"
import useNavigateSafe from "model/navigation/useNavigateSafe"

const UserProfilePage = ({ teamKey }: { teamKey: string }) => {
  const { userKey } = useParams()
  const [navigate, navigateSafeState] = useNavigateSafe()
  const showSnackbar = useSnackbar()
  const editObserver: shared.com.probuildsoftware.probuild.library.users.UserProfileEditEventObserver =
    useMemo(() => {
      return {
        onShowSuccessSnackbar(message: string) {
          showSnackbar(message, "success")
        },
        onShowErrorSnackbar(message: string) {
          showSnackbar(message, "error")
        },
        onNavigateBack() {
          navigate(-1)
        },
      }
    }, [showSnackbar, navigate])
  const previewObserver: shared.com.probuildsoftware.probuild.library.users.UserProfilePreviewEventObserver =
    useMemo(() => {
      return {
        onShowSuccessSnackbar(message: string) {
          showSnackbar(message, "success")
        },
        onShowErrorSnackbar(message: string) {
          showSnackbar(message, "error")
        },
        onChatEnsured(
          ensuredChatViewData: shared.com.probuildsoftware.probuild.library.users.data.view.EnsuredChatViewData
        ) {},
        onNavigateBack() {},
      }
    }, [showSnackbar])
  const [editViewData, editInput] = useUserProfileEdit({
    teamKey,
    userKey,
    observer: editObserver,
  })
  const [previewViewData, previewInput] = useUserProfilePreview({
    teamKey,
    userKey,
    observer: previewObserver,
  })
  return (
    <TeamPageLayout
      title={editViewData?.title}
      barItems={
        <>
          <SaveButton
            text={editViewData?.saveButtonText}
            onClick={() => editInput?.save()}
            isEnabled={editViewData?.isSaveButtonEnabled}
          />
          <OverflowMenu>
            {editViewData?.deleteButtonText && (
              <DeleteMenuItem
                text={editViewData?.deleteButtonText}
                onClick={() => editInput?.deleteInitiated()}
              />
            )}
          </OverflowMenu>
        </>
      }
      showLoadingSpinner={!editViewData || !userKey}
    >
      {editViewData && userKey && (
        <UserProfileEditView
          teamKey={teamKey}
          userKey={userKey}
          editViewData={editViewData}
          previewViewData={previewViewData}
          editInput={editInput}
          previewInput={previewInput}
          navigateSafeState={navigateSafeState}
        />
      )}
    </TeamPageLayout>
  )
}

export default UserProfilePage
