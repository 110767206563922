import { useMemo } from "react"

import * as shared from "probuild-shared"

import useDependencyProvider from "model/utils/useDependencyProvider"
import useAppUser from "model/utils/useAppUser"
import usePresenter from "model/presenter/usePresenter"

const useUserProfilePreview = ({
  teamKey,
  userKey,
  observer,
}: {
  teamKey: string
  userKey: string | undefined
  observer: shared.com.probuildsoftware.probuild.library.users.UserProfilePreviewEventObserver
}): [
  shared.com.probuildsoftware.probuild.library.users.data.view.UserProfilePreviewViewData | null,
  shared.com.probuildsoftware.probuild.library.users.UserProfilePreviewInput | null
] => {
  const appUser = useAppUser({ teamKey })
  const dependencyProvider = useDependencyProvider()
  const presenter = useMemo(() => {
    if (dependencyProvider && appUser && userKey) {
      console.log(
        `Creating user profile preview presenter teamKey = ${appUser.teamKey} userKey = ${appUser.userKey} userKeyOfProfile = ${userKey}`
      )
      return new shared.com.probuildsoftware.probuild.library.users.UserProfilePreviewPresenter(
        dependencyProvider,
        appUser,
        {
          userKey,
        }
      )
    }
  }, [appUser, userKey, dependencyProvider])
  return usePresenter(presenter, observer)
}

export default useUserProfilePreview
