import {
  Paper,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useTheme,
  useMediaQuery,
  styled,
} from "@mui/material"
import ContactsIcon from "@mui/icons-material/Contacts"

import * as shared from "probuild-shared"

import TeamPageLayout from "components/views/layouts/TeamPageLayout"
import ClientListItemView from "components/views/clients/list/ClientListItemView"
import SearchBar from "components/views/generic/SearchBar"
import useClientList from "model/clients/list/useClientList"
import paths from "model/utils/paths"
import EmptyView from "components/views/EmptyView"
import { GetApp } from "@mui/icons-material"
import { useMemo } from "react"
import useDownloadFile from "model/utils/useDownloadFile"

const TableHeadContainer = styled("div")({
  display: "flex",
  flexBasis: "100%",
})

const StyledTableHead = styled(TableHead)({
  display: "flex",
  flexBasis: "100%",
})

const StyledTableRow = styled(TableRow)({
  display: "flex",
  flexBasis: "100%",
})

const StyledTableCell = styled(TableCell)({
  display: "flex",
  flex: 1,
  fontSize: 16,
})

const ClientListPage = ({ teamKey }: { teamKey: string }) => {
  const downloadFile = useDownloadFile()
  const observer: shared.com.probuildsoftware.probuild.library.clients.ClientListEventObserver =
    useMemo(() => {
      return {
        onExportCsvReady(filename: string, csvContent: string) {
          downloadFile(filename, csvContent, "text/csv")
        },
        onDeleteSuccess() {},
        onDeleteError() {},
      }
    }, [downloadFile])
  const [viewData, input] = useClientList({
    teamKey,
    isSelecting: false,
    observer,
  })
  const clientListItems = viewData?.items?.asJsReadonlyArrayView() || []

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.up("sm"))
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("md"))
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"))

  return (
    <TeamPageLayout
      barItems={
        <SearchBar
          placeholder={viewData?.searchHint}
          onChange={(text) => input?.changeSearchQuery(text)}
        />
      }
      createButtons={[
        {
          to: paths.clientCreate(teamKey),
          text: viewData?.createButtonText,
        },
      ]}
      title={viewData?.title}
      showLoadingSpinner={!viewData}
    >
      {viewData && (
        <>
          {viewData.exportButtonText && (
            <Button
              startIcon={<GetApp />}
              onClick={() => {
                input?.exportCsv()
              }}
              sx={{ mb: 2 }}
            >
              {viewData.exportButtonText}
            </Button>
          )}
          <TableContainer component={Paper}>
            <Table>
              <TableHeadContainer>
                <StyledTableHead>
                  <StyledTableRow>
                    <StyledTableCell>{viewData.header.name}</StyledTableCell>
                    {isSmallScreen && (
                      <StyledTableCell>{viewData.header.email}</StyledTableCell>
                    )}
                    {isMediumScreen && (
                      <StyledTableCell>
                        {viewData.header.mobilePhone}
                      </StyledTableCell>
                    )}
                    {isLargeScreen && (
                      <StyledTableCell>
                        {viewData.header.homePhone}
                      </StyledTableCell>
                    )}
                  </StyledTableRow>
                </StyledTableHead>
              </TableHeadContainer>
              <TableBody>
                {clientListItems.map((listItem) => {
                  return (
                    <ClientListItemView
                      key={listItem.clientKey}
                      teamKey={teamKey}
                      item={listItem}
                      isSmallScreen={isSmallScreen}
                      isMediumScreen={isMediumScreen}
                      isLargeScreen={isLargeScreen}
                    />
                  )
                })}
              </TableBody>
            </Table>
            <EmptyView icon={ContactsIcon} viewData={viewData.empty} />
          </TableContainer>
        </>
      )}
    </TeamPageLayout>
  )
}

export default ClientListPage
