import { useCallback } from "react"

import * as shared from "probuild-shared"

import { Box, Collapse, Divider, Typography } from "@mui/material"
import MessageBubbleView from "components/views/chats/MessageBubbleView"
import PostCommentInputField from "components/views/generic/PostCommentInputField"

const PostCommentsListView = ({
  teamKey,
  viewData,
  input,
}: {
  teamKey: string
  viewData: shared.com.probuildsoftware.probuild.library.projects.data.view.PostPreviewViewData
  input: shared.com.probuildsoftware.probuild.library.projects.PostPreviewInput
}) => {
  const onCommentViewed = useCallback(
    (commentKey: string) => {
      input?.commentBecameVisible(commentKey)
    },
    [input]
  )
  const comments =
    viewData.listItemViewData.comments.asJsReadonlyArrayView() || []
  const nowTypingText = viewData?.listItemViewData?.nowTypingText
  return (
    <>
      <Box>
        {comments.length > 0 && (
          <>
            {comments.map((commentViewData) => {
              return (
                <MessageBubbleView
                  teamKey={teamKey}
                  viewData={commentViewData}
                  onViewed={onCommentViewed}
                />
              )
            })}
            <Collapse in={nowTypingText != null}>
              <Typography sx={{ px: 2, pb: 1, minHeight: "2rem" }}>
                {nowTypingText}
              </Typography>
            </Collapse>
          </>
        )}
        <Box>
          {comments.length > 0 && <Divider />}
          <PostCommentInputField viewData={viewData} input={input} />
        </Box>
      </Box>
    </>
  )
}

export default PostCommentsListView
