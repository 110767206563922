import TeamPageLayout from "components/views/layouts/TeamPageLayout"
import TeamProfileEditView from "components/views/team/TeamProfileEditView"
import DeleteButton from "components/views/generic/DeleteButton"
import SaveButton from "components/views/generic/SaveButton"
import { styled } from "@mui/material"
import useTeamProfile from "model/team/useTeamProfile"
import { useMemo } from "react"
import * as shared from "probuild-shared"
import useSnackbar from "model/snackbar/useSnackbar"
import useNavigateSafe from "model/navigation/useNavigateSafe"
import usePromptSafe from "model/navigation/usePromptSafe"
import { useLocation } from "react-router-dom"

const DeleteButtonDiv = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  paddingTop: theme.spacing(3),
}))

const TeamProfileEditPage = ({ teamKey }: { teamKey: string }) => {
  const [navigateSafe, navigateSafeState] = useNavigateSafe()
  const location = useLocation()
  const showSnackbar = useSnackbar()
  const afterSaveNavigateTo = location.state?.afterSaveNavigateTo
  const afterSaveNavigateState = location.state?.afterSaveNavigateState
  const observer: shared.com.probuildsoftware.probuild.library.team.TeamProfileEventObserver =
    useMemo(() => {
      return {
        onNavigateBack() {
          if (afterSaveNavigateTo && afterSaveNavigateState) {
            navigateSafe(afterSaveNavigateTo, { state: afterSaveNavigateState })
          }
        },
        onTeamDeleted() {},
        onShowErrorSnackbar(message: string) {
          showSnackbar(message, "error")
        },
      }
    }, [
      showSnackbar,
      navigateSafe,
      afterSaveNavigateTo,
      afterSaveNavigateState,
    ])
  const [viewData, input] = useTeamProfile({
    teamKey,
    observer,
    focusOnEmail: location.state?.focusOnEmail === true,
  })
  const onSaveButtonClick = () => {
    input?.save()
  }
  const onDeleteButtonClick = () => {
    input?.deleteInitiated()
  }
  usePromptSafe({
    when: viewData?.isModified === true,
    message: viewData?.discardConfirmationDialog.message || "",
    navigateSafeState: navigateSafeState,
  })
  if (!input) return null
  return (
    <TeamPageLayout
      title={viewData?.title}
      barItems={
        <SaveButton
          text={viewData?.saveButtonText}
          onClick={onSaveButtonClick}
          isEnabled={viewData?.isModified}
        />
      }
      showLoadingSpinner={!viewData}
    >
      {viewData && (
        <>
          <TeamProfileEditView viewData={viewData} input={input} />
          {viewData?.isEditable && (
            <DeleteButtonDiv>
              <DeleteButton
                text={viewData.sections.deleteTeam.buttonText}
                onClick={onDeleteButtonClick}
              />
            </DeleteButtonDiv>
          )}
        </>
      )}
    </TeamPageLayout>
  )
}

export default TeamProfileEditPage
