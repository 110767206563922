import { useContext } from "react"
import { styled } from "@mui/material/styles"
import { Box, Divider, Drawer, List, Typography } from "@mui/material"
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed"
import ListIcon from "@mui/icons-material/List"
import GavelIcon from "@mui/icons-material/Gavel"
import PeopleIcon from "@mui/icons-material/People"
import ContactsIcon from "@mui/icons-material/Contacts"
import DescriptionIcon from "@mui/icons-material/Description"
import HelpIcon from "@mui/icons-material/Help"
import DomainIcon from "@mui/icons-material/Domain"

import NavigationDrawerItem from "components/views/app/NavigationDrawerItem"
import AccountButton from "components/views/app/AccountButton"
import useTabs from "model/app/useTabs"
import paths from "model/utils/paths"
import NavigationDrawerContext from "contexts/NavigationDrawerContext"
import { AccountBalance } from "@mui/icons-material"

const DrawerContentDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
})

const BrandingDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
}))

const ProbuildLogoImage = styled("img")({
  height: "35px",
  objectFit: "contain",
})

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: "large",
  color: theme.palette.common.white,
  margin: theme.spacing(0.5),
}))

const RootDiv = styled("div")(({ theme }) => ({
  width: drawerWidth,
  height: "100%",
  overflow: "hidden",
  flexShrink: 0,
  backgroundColor: theme.palette.primary.main,
}))

const StyledList = styled(List)(({ theme }) => ({
  width: "100%",
  paddingTop: 0,
  paddingBottom: 0,
  backgroundColor: theme.palette.primary.main,
}))

const StyledDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  opacity: 0.3,
}))

const drawerWidth = 260

const StyledBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: { flexShrink: 0 },
  [theme.breakpoints.up("md")]: { width: drawerWidth },
}))

const TemporaryDrawer = styled(Drawer)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: { display: "block" },
  [theme.breakpoints.up("sm")]: { display: "block" },
  [theme.breakpoints.up("md")]: { display: "none" },
  "& .MuiDrawer-paper": {
    boxSizing: "content-box",
    width: drawerWidth,
    position: "relative",
  },
}))

const PermanentDrawer = styled(Drawer)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: { display: "none" },
  [theme.breakpoints.up("sm")]: { display: "none" },
  [theme.breakpoints.up("md")]: { display: "block" },
  "& .MuiDrawer-paper": {
    boxSizing: "content-box",
    width: drawerWidth,
    position: "relative",
  },
}))

const NavigationDrawer = ({ teamKey }: { teamKey: string }) => {
  const tabsViewData = useTabs({ teamKey })

  const { openNavigationDrawer, toggleNavigationDrawer } = useContext(
    NavigationDrawerContext
  )

  const NavigationDrawerExpanded = (
    <RootDiv>
      <DrawerContentDiv>
        <div>
          <AccountButton />

          <StyledDivider />
          <StyledList>
            {tabsViewData?.feed && (
              <NavigationDrawerItem
                title={tabsViewData.feed}
                icon={DynamicFeedIcon}
                href={paths.feed(teamKey)}
              />
            )}
            {tabsViewData?.estimatesAndInvoices && (
              <NavigationDrawerItem
                title={tabsViewData.estimatesAndInvoices}
                icon={DescriptionIcon}
                href={paths.documents(teamKey)}
              />
            )}
            {tabsViewData?.clients && (
              <NavigationDrawerItem
                title={tabsViewData.clients}
                icon={ContactsIcon}
                href={paths.clients(teamKey)}
              />
            )}
            {tabsViewData?.items && (
              <NavigationDrawerItem
                title={tabsViewData.items}
                icon={ListIcon}
                href={paths.items(teamKey)}
              />
            )}
            {tabsViewData?.contracts && (
              <NavigationDrawerItem
                title={tabsViewData.contracts}
                icon={GavelIcon}
                href={paths.contracts(teamKey)}
              />
            )}
            {tabsViewData?.users && (
              <NavigationDrawerItem
                title={tabsViewData.users}
                icon={PeopleIcon}
                href={paths.users(teamKey)}
              />
            )}
            {tabsViewData?.businessProfile && (
              <NavigationDrawerItem
                title={tabsViewData.businessProfile}
                icon={DomainIcon}
                href={paths.profile(teamKey)}
              />
            )}
            {tabsViewData?.subscriptions && (
              <NavigationDrawerItem
                title={tabsViewData.subscriptions}
                icon={AccountBalance}
                href={paths.subscriptions(teamKey)}
              />
            )}
            {tabsViewData?.helpAndFeedback && (
              <NavigationDrawerItem
                title={tabsViewData.helpAndFeedback}
                icon={HelpIcon}
                href={paths.helpAndFeedback(teamKey)}
              />
            )}
          </StyledList>
          <StyledDivider />
        </div>
        <div>
          <BrandingDiv>
            <ProbuildLogoImage src="/logoTransparent.png" alt="Probuild" />
            <StyledTypography>{tabsViewData?.title}</StyledTypography>
          </BrandingDiv>
        </div>
      </DrawerContentDiv>
    </RootDiv>
  )

  return (
    <StyledBox component="nav">
      <TemporaryDrawer
        variant="temporary"
        ModalProps={{
          keepMounted: true,
        }}
        open={openNavigationDrawer}
        onClose={toggleNavigationDrawer}
        onClick={toggleNavigationDrawer}
      >
        {NavigationDrawerExpanded}
      </TemporaryDrawer>
      <PermanentDrawer variant="permanent" open>
        {NavigationDrawerExpanded}
      </PermanentDrawer>
    </StyledBox>
  )
}

export default NavigationDrawer
