import * as shared from "probuild-shared"

function createInputFiles(
  files: FileList
): shared.com.probuildsoftware.probuild.library.common.data.files.InputFile[] {
  const inputFiles: shared.com.probuildsoftware.probuild.library.common.data.files.InputFile[] =
    []
  for (let index = 0; index < files.length; index++) {
    const file = files[index]
    if (file) {
      inputFiles.push(
        new shared.com.probuildsoftware.probuild.library.common.data.files.InputFile(
          URL.createObjectURL(file),
          file.name,
          file.type,
          file.size
        )
      )
    }
  }
  return inputFiles
}

export default createInputFiles
