import React, { ReactNode } from "react"
import { Grid } from "@mui/material"

import LabeledCard from "components/views/generic/LabeledCard"

const ActionList = ({ children }: { children?: ReactNode }) => {
  if (React.Children.toArray(children).length === 0) {
    return null
  }
  return (
    <LabeledCard text="">
      <div>
        <Grid container spacing={2}>
          {children}
        </Grid>
      </div>
    </LabeledCard>
  )
}

export default ActionList
