import { Box, Divider, ListItemButton, styled } from "@mui/material"

import * as shared from "probuild-shared"

import paths from "model/utils/paths"
import TextWithFallbackView from "components/views/generic/TextWithFallbackView"
import { Link } from "react-router-dom"

const StyledBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(1),
}))

const LeftInnerColumn = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(0.5),
}))

const RightInnerColumn = styled("div")({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  textAlign: "right",
  justifyContent: "space-between",
})

const BoldedTextWithFallbackView = styled(TextWithFallbackView)({
  fontWeight: "bold",
})

const ItemListItemView = ({
  teamKey,
  item,
}: {
  teamKey: string
  item: shared.com.probuildsoftware.probuild.library.items.data.view.ItemListItemViewData
}) => {
  const itemPath = paths.itemEdit(teamKey, item.itemKey)
  return (
    <>
      <ListItemButton component={Link} key={item.itemKey} to={itemPath}>
        <StyledBox>
          <LeftInnerColumn>
            <BoldedTextWithFallbackView data={item.name} />
            <TextWithFallbackView data={item.description} />
          </LeftInnerColumn>
          <RightInnerColumn>
            <TextWithFallbackView data={item.rate} />
          </RightInnerColumn>
        </StyledBox>
      </ListItemButton>
      <Divider />
    </>
  )
}

export default ItemListItemView
