import useAppUser from "model/utils/useAppUser"
import useDependencyProvider from "model/utils/useDependencyProvider"
import { useMemo } from "react"

import * as shared from "probuild-shared"
import usePresenter from "model/presenter/usePresenter"

function useDocumentAllList({
  teamKey,
  isSelecting,
  observer,
}: {
  teamKey: string
  isSelecting: boolean
  observer?: shared.com.probuildsoftware.probuild.library.documents.DocumentAllListEventObserver
}): [
  shared.com.probuildsoftware.probuild.library.documents.data.view.DocumentsListViewData | null,
  shared.com.probuildsoftware.probuild.library.documents.DocumentAllListInput | null
] {
  const appUser = useAppUser({ teamKey })
  const dependencyProvider = useDependencyProvider()
  const presenter = useMemo(() => {
    if (dependencyProvider && appUser) {
      return new shared.com.probuildsoftware.probuild.library.documents.DocumentAllListPresenter(
        dependencyProvider,
        appUser,
        {
          isSelecting,
        }
      )
    }
  }, [appUser, dependencyProvider, isSelecting])
  return usePresenter(presenter, observer)
}

export default useDocumentAllList
