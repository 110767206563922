import shared from "probuild-shared"

class PlatformLocaleApi
  implements
    shared.com.probuildsoftware.probuild.library.common.model.locale.api
      .PlatformLocaleApi
{
  observeLocale(
    observer: shared.com.probuildsoftware.probuild.library.common.model.locale.api.PlatformLocaleApiObserver
  ): () => void {
    const locale = this.determineLocale()
    observer.onPlatformLocaleChanged(locale)
    return () => {}
  }

  private determineLocale(): string | null {
    if (typeof window !== "undefined") {
      if (window.navigator.languages && window.navigator.languages.length > 0) {
        return window.navigator.languages[0]
      }
      if (window.navigator.language) {
        return window.navigator.language
      }
    }
    return null
  }
}

export default PlatformLocaleApi
